import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import Status from '../../../components/ui/Status';
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import WarehouseAddress from '../includes/WarehouseAddress';
import AssetMiniDetail from '../../includes/AssetMiniDetail';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import ItemSearchModal from '../../items/itemSearchModal';
import InputTicketSearch from '../../includes/ui/InputTicketSearch';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import TapInputNumber from '../../../components/ui/TapInputNumber';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';

class AllocationAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initAllocationForm        =   {
            transaction_date                :   new Date(),
            warehouse_id                    :   '',
            allocation_for                  :   'asset',
            workstation_id                  :   '',
            asset_id                        :   '',
            sub_assembly_id                 :   '',
            voucher_number                  :   '',
            voucher_date                    :   '',
            items                           :   [],
            notes                           :   '',
            adjustment_amount               :   0,
            ticket_id                       :   '',
            employee_id                     :   ''
        }
        
        this.initAllocationItemRows   =   [{
            item                            :   null,
            stock_id                        :   null,
            remark                          :   '',
            amount                          :   0,
            qty                             :   0,
            stock_qty                       :   0,
            free_qty                        :   0,
            rate                            :   0,
            current_stocks                  :   [],
            selected_stock                  :   null,
            stock_loading                   :   false,
            allApiCallsCompleted            :   false,
        }];
        
        this.initalState                =   {
            allocationFormDataLoaded       :   false,
            allWarehouses                  :   [],
            allGstSlabs                    :   [],
            allStatus                      :   [],
            isc_configuration              :   null,
            track_batch_expiry             :   'N',
            negative_stock                 :   'N',
            allocationForm                 :   this.initAllocationForm,
            saveFormSubmitting             :   false,
            allocationDetail               :   null,
            selectedSite                   :   null,
            warehouseDetailLoading         :   false,
            warehouseDetail                :   null,
            warehouseSites                 :   [],
            selectedAsset                  :   null,
            allocationItemRows             :   [...this.initAllocationItemRows] ,
            itemRowNumber                  :   0  ,
            cloneallocation                :   false,
            consumtionHistoryList          :   [],
            allocationDetailLoading        :   false,
            only_stocked_item              :   'Y',
            searchedEmployee               :   null,
            selectedTicket                 :   null,
            ticketSelectedSite             :   '',
            ticketSelectedAsset            :   '',
            ticketFromAllocation           :   false,
            ticket_allocation              :   'N',
            buttonDisabled                 :   false,
            searchSubassembly              :   null,
            ticketFromTicket               :   false,
            allAllocationsFor               :  [],
            allDemandListing                :   [],
            allSelectedDemandList            :     [],
            allItemWiseDemandListing          :    [],
            demand_listing_loading          :       false,          
            searchedWorkstation             :       null,
            itemDemandLoaded                :       false,
            showItemSearchModal             :       false,
            showItemAddModal                :       false,
            showItemEditModal               :       false
        }
        
        this.state                      =   {
            ...this.initalState
        };
        this.addItemModalRef            =   React.createRef();
        this.editItemModalRef           =   React.createRef();
        this.allocationHistoryRef       =   React.createRef();
        this.itemSearchRef              =   React.createRef();

        this.formDataUrl                =   INVENTORY_API_BASE_URL_2 + '/allocation/add_form_data';
        this.allocationUrl              =   INVENTORY_API_BASE_URL_2 + '/allocation';
        this.fetchDemandUrl             =   INVENTORY_API_BASE_URL_2  + '/item_demand/list';
        this.itemCurrentStockUrl        =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';
        this.itemDetailUrl              =   INVENTORY_API_BASE_URL_2 + '/item'
        this.warehouseUrl               =   ISC_API_BASE_URL_2  +   '/warehouse';


        // this.allocationFor              =   [
        //     {label: 'Asset', value: 'asset'},
        //     {label: 'Workstation', value: 'workstation'},
        //     {label: 'Employee', value: 'employee'},
        //     {label: 'Sub Assembly', value: 'sub_assembly'},
        // ];
    }
    
    componentDidMount() {
    
        this.viewItemModal                           =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
        this.pickItemModal                           =   new Modal(document.getElementById('pickItemModal'), {keyboard: false, backdrop :false});
        this.pickItemDemandModal                     =   new Modal(document.getElementById('pickItemDemandModal'), {keyboard: false, backdrop :false});
        this.initilaizeFormData(this.props);

    }

    componentWillReceiveProps(nextProps){
        if(this.props.location && this.props.location.state){
            if(nextProps.location.state !== this.props.location.state) {
                this.initilaizeFormData(nextProps)
             }  
        }        
    }
    // buttonDisabled
    initilaizeFormData                  =   (pr)  =>  {
        let sites                       =   pr.iam_group_sites;
        if(!this.state.allocationFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.formDataUrl , this.props.access_token, null, null, (response) => {
                 let formData = response.data
                this.setState({
                    allocationFormDataLoaded   :   formData ? true : false,
                    ticket_allocation          :   formData && formData.setting && formData.setting.ticket_allocation ? formData.setting.ticket_allocation : 'N',
                    allWarehouses               :   pr.all_warehouses && pr.all_warehouses.length > 0 ? pr.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_ids}; }) : [],
                    allAllocationsFor            :   [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'},{value:'sub_assembly',label:'Sub Assembly'}],
                    allStatus                   :   formData && formData.status && formData.status.length > 0 ? formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    track_batch_expiry          :   pr.isc_configuration && pr.isc_configuration.track_batch_expiry ? pr.isc_configuration.track_batch_expiry : 'N',
                    negative_stock              :   pr.isc_configuration && pr.isc_configuration.negative_stock ? pr.isc_configuration.negative_stock : 'N',
                    only_stocked_item           :    pr.isc_configuration && pr.isc_configuration.negative_stock && pr.isc_configuration.negative_stock == 'N' ? 'Y' : 'N'
                }, () => {
                    let ParamObject                 =       new URLSearchParams(this.props.location.search);
                    if(pr.location && pr.location.state && pr.location.state.allocationData) {
                      
                        let allocationDetail       =   pr.location.state.allocationData;
                        let allocationItems        =   allocationDetail.items;
                        
                        this.setState({
                            allocationDetail       :   pr.location && pr.location.state && pr.location.state.clone ? null : allocationDetail,
                            allocationForm         :   {
                                ...this.initAllocationForm,
                                transaction_date        :   pr.location && pr.location.state && pr.location.state.clone ?  moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') : allocationDetail.transaction_date,
                                warehouse_id            :   allocationDetail.warehouse_id,
                                allocation_for        :   allocationDetail.allocation_for ? allocationDetail.allocation_for : 'asset',
                                asset_id                :   allocationDetail.assetProfile ? allocationDetail.assetProfile.asset_id : null, 
                                workstation_id          :   allocationDetail.workstation ? allocationDetail.workstation.id : null,
                                voucher_number          :   allocationDetail.voucher_number,
                                voucher_date            :   allocationDetail.voucher_date ? allocationDetail.voucher_date : '',
                                notes                   :   allocationDetail.notes,
                                adjustment_amount       :   allocationDetail.adjustment_amount,
                                status                  :   allocationDetail.status,
                                demand_transaction_id   :   allocationDetail.demand_transaction_id,
                                ticket_id               :   allocationDetail.ticket ? allocationDetail.ticket.activity_id : '',
                                sub_assembly_id         :   allocationDetail.subAssembly ? allocationDetail.subAssembly.enc_id : ''
                            },
                            buttonDisabled              :  true,
                            ticketFromAllocation        :   allocationDetail.ticket ? true : false,
                            
                            ticketSelectedAsset         :   allocationDetail.ticket && allocationDetail.allocation_for == "asset" && allocationDetail.assetProfile ? allocationDetail.assetProfile : null,
                            selectedTicket              :   allocationDetail.ticket ? {...allocationDetail.ticket, value: allocationDetail.ticket.activity_id, label: `${allocationDetail.ticket.activity_id} : ${allocationDetail.ticket.header}`, display_label: `${allocationDetail.ticket.activity_id} : ${allocationDetail.ticket.header}` } : null,
                            allStatus                   :   allocationDetail && allocationDetail.user_status && allocationDetail.user_status.length > 0 ? allocationDetail.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        }, () => {
                            if(allocationDetail.demand_transaction_id){
                                this.getAllDemandListing(allocationDetail.demand_transaction_id);
                                this.getItemDemandLwisting(allocationDetail.demand_transaction_id,false)
                            }
                            this.getWarehouseDetail(allocationDetail.warehouse_id, true);
                            let assetProfile        =   allocationDetail.assetProfile;
                            if(assetProfile) {
                                this.setState({selectedAsset : {...assetProfile, label : `${assetProfile.name} (${assetProfile.asset_code})` }});
                            }

                            let employeeProfile        =   allocationDetail.employee;
                            if(employeeProfile) {
                                this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.display_full_name}`,employee : employeeProfile }});
                            }

                            let workstation        =   allocationDetail.workstation;
                            if(workstation) {
                                this.setState({searchedWorkstation : {value:workstation.id,label:workstation.workstation_name }});
                            }
                            let subAssembly         =   allocationDetail.subAssembly;
                            if(subAssembly) {
                                this.setState({
                                    searchSubassembly : {
                                        label               :   subAssembly.name,
                                        value               :   subAssembly.enc_id,
                                        display_label       :   subAssembly.name,
                                        subAssembly         :   subAssembly
                                    }
                                })
                            }
                            
                            allocationItems.forEach((consumedItem, key) => {
                                
                                let pre_selected_stock = {batch_number : consumedItem.batch_number, expiry_date : consumedItem.expiry_date , qty : consumedItem.qty, allocation_item_id : consumedItem.id,pending_qty : consumedItem.pending_qty,item_current_qty : consumedItem.qty };
                                this.onItemSelect({...consumedItem ,demand_pending_qty : consumedItem.qty ? consumedItem.qty : 0,demand_item_id : consumedItem.demand_item_id,demand_transaction_id : consumedItem.demand_transaction_id ? consumedItem.demand_transaction_id : '', itemdata : {...consumedItem.itemData,isAddRemark : consumedItem.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate, pre_selected_stock,false);
                            });
                        });
                    }

                    if(pr.location && pr.location.state && pr.location.state.addAllocationFromPurchase) {
                      
                        let allocationDetail       =   pr.location.state.addAllocationFromPurchase;
                       
                        let consumedItems           =   allocationDetail.items;
                          
                        setTimeout(() => {
                            this.setState({
                                allocationDetail    :   null,
                                buttonDisabled      :  true,
                                allocationForm      :   {
                                    ...this.initAllocationForm,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_id            :   allocationDetail.warehouse_id,
                                }
                            }, () => {
                                this.getWarehouseDetail(allocationDetail.warehouse_id, true);
                                
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                        },500)
                       
                    }

                    if(pr.location && pr.location.state && pr.location.state.addAllocationFromInventoryTransfer) {
                      
                        let allocationDetail       =   pr.location.state.addAllocationFromInventoryTransfer;
                       
                        let consumedItems           =   allocationDetail.items;
                          
                        setTimeout(() => {
                            this.setState({
                                allocationDetail       :   null,
                                buttonDisabled               :  true,
                                allocationForm         :   {
                                    ...this.initAllocationForm,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_id            :   allocationDetail.warehouse_id,
                                   
                                },
                                only_stocked_item           :   'N'
                            }, () => {
                                this.getWarehouseDetail(allocationDetail.warehouse_id, true);
                                
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                        },500)
                       
                    }

                    if(ParamObject.get('ticketId')) {

                        const storedData = localStorage.getItem('selectedTicketToAddallocation');
                        if (storedData) {
                            const { asset, site_id, ticket_header, ticketFor, ticketForData } = JSON.parse(storedData);
                            let ticket_id = ParamObject.get('ticketId');

                            this.setState({
                                ticketFromTicket        :   true,
                                allocation_for          :   ticketFor,
                                ticketSelectedSite      :   site_id ? site_id : '',
                                ticketSelectedAsset     :   ticketFor === 'asset' && ticketForData  ? ticketForData : null,
                                selectedAsset           :   ticketFor === 'asset' && ticketForData  ? { ...ticketForData, label: `${ticketForData?.name} (${ticketForData?.asset_code})` } : null,
                                allocationForm          :   {
                                    ...this.initAllocationForm,
                                    allocation_for      :   ticketFor,
                                    asset_id            :   ticketForData && ticketFor === 'asset' && ticketForData ? ticketForData.asset_id : "",
                                    ticket_id: ticket_id,
                                    sub_assembly_id     :   ticketForData && ticketFor === 'sub_assembly' && ticketForData  ? ticketForData.enc_id : "",
                                    workstation_id      :   ticketForData && ticketFor === 'workstation' && ticketForData ? ticketForData.id : "",
                                    employee_id         :   ticketForData && ticketFor === 'employee' && ticketForData  ? ticketForData.enc_id : "", 
                                },
                                searchSubassembly       :   ticketFor === 'sub_assembly' && ticketForData  ? {
                                    label                   :   ticketForData.name,
                                    value                   :   ticketForData.enc_id,
                                    display_label           :   ticketForData.name,
                                    subAssembly             :   ticketForData
                                } : null,
                                searchedEmployee        :   ticketFor === 'employee' && ticketForData ? {
                                    value : ticketForData.enc_id, label : ticketForData.display_full_name, employee : ticketForData
                                } : null, 
                                ticketFromAllocation    :   ticket_id && ticketFor !== 'site' ? true : false,
                                allWarehouses           :   this.state.allWarehouses.filter(wd => wd.site_id.includes(site_id)),
                                selectedTicket          :   ticket_header ? {asset_id : asset ? asset.asset_id : '', value: ticket_id, label: `${ticket_id} : ${ticket_header}`, display_label: `${ticket_id} : ${ticket_header}` } : null
                            }, () => {
                                let workstation        =   ticketForData && ticketFor === 'workstation' ? ticketForData : null;
                                if(workstation) {
                                    this.setState({searchedWorkstation : {value:workstation.id,label:workstation.workstation_name }});
                                }
                            })
                        } 
                      
                    } 
                    
                    if(pr.location && pr.location.state && pr.location.state.addAllocationFromDemand) {
                      
                        let allocationData       =   pr.location.state.addAllocationFromDemand;
                        let allocationDetail       =   pr.location.state.addAllocationFromDemand.demandData;
                       
                        let consumedItems           =   allocationDetail.items && allocationDetail.items.length > 0 ? allocationDetail.items : allocationData.items && allocationData.items.length > 0 ? allocationData.items : [];
                         
                        setTimeout(() => {
                            this.setState({
                                allocationDetail    :   null,
                                buttonDisabled      :  true,
                                allocationForm      :   {
                                    ...this.initAllocationForm,
                                    transaction_date        :    moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') ,
                                    warehouse_id            :   allocationDetail.warehouse.id,
                                    allocation_for          :   allocationDetail.demand_for,
                                    demand_transaction_id   :   allocationDetail.transaction_id ? allocationDetail.transaction_id : '',
                                    ticket_id               :   allocationDetail.ticket_id ? allocationDetail.ticket_id : '',
                                    asset_id                    :       allocationDetail.asset ? allocationDetail.asset.asset_id : null,
                                    workstation_id              :       allocationDetail.workstation ? allocationDetail.workstation.id : null,
                                    employee_id                 :       allocationDetail.employee ? allocationDetail.employee.enc_id : null,
                                    sub_assembly_id             :       allocationDetail.sub_assembly ? allocationDetail.sub_assembly.id : null,
                                },
                                selectedTicket          :   allocationDetail.ticket ? {asset_id : allocationDetail.asset ? allocationDetail.asset.asset_id : null,
                                     value: allocationDetail.ticket_id ? allocationDetail.ticket_id : '', label: `${allocationDetail.ticket.activity_id} : ${allocationDetail.ticket.header}`, display_label: `${allocationDetail.ticket.activity_id} : ${allocationDetail.ticket.header}` } : null
                            }, () => {
                                this.getWarehouseDetail(allocationDetail.warehouse.id, true);
                                let assetProfile        =   allocationDetail.asset;
                                if(assetProfile) {
                                    this.setState({selectedAsset : {...assetProfile, label : `${assetProfile.name} (${assetProfile.asset_code})` }});
                                }
    
                                let employeeProfile        =   allocationDetail.employee;
                                if(employeeProfile) {
                                    this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.name}` }});
                                }
                                let subAssemblyProfile        =   allocationDetail.sub_assembly;
                                if(subAssemblyProfile) {
                                    this.setState({searchSubassembly : {value : subAssemblyProfile.id, label : `${subAssemblyProfile.name}` }});
                                }
                                let workStation        =   allocationDetail.workstation;
                                if(workStation) {
                                    this.setState({searchedWorkstation : {value : workStation.id, label : `${workStation.workstation_name}` }});
                                }
                                if(allocationDetail.transaction_id){
                                    this.getAllDemandListing(allocationDetail.transaction_id);
                                    this.getItemDemandLwisting(allocationDetail.transaction_id)
                                }
                                consumedItems.forEach((consumedItem, key) => {
                                    this.onItemSelect({...consumedItem ,
                                        demand_pending_qty : consumedItem.pending_qty ? consumedItem.pending_qty : 0,demand_item_id : consumedItem.demand_item_id,demand_transaction_id : allocationDetail.transaction_id, itemdata : {...consumedItem.item_profile,isAddRemark : consumedItem?.remark ? true : false,demand_item_id : consumedItem.demand_item_id,demand_transaction_id : allocationDetail.transaction_id}}, key ,consumedItem.qty, consumedItem.rate,null,false);
                                });
                            });
                        },500)
                       
                    }
                    
                });
            });
        }
    }
    
    onWarehouseChange                   =   (selectedOption) =>  {
        if(!this.state.selectedTicket){
         this.setState({
             selectedAsset               :   null,
             searchedWorkstation         :   null,
             searchedEmployee            :   null,
             searchSubassembly           :    null,
             warehouseSites              :   [],
             itemDemandLoaded            :  false,
             
             allocationForm             :   {
                 ...this.state.allocationForm,
                 workstation_id          :   '',
                 asset_id                :   '',
                 employee_id             :   '',
                 tag_users           :       [],
                 sub_assembly_id         :   ''
             },
             // allocationItemRows         :   [...this.iniitallocationItemRows]
         }, () => {
            
         });
        }
        this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
     }
   
    
    getWarehouseDetail                  =   (warehouse_id, editallocation=false)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null, warehouseSites : []});
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetail         :   respData.data,
                    warehouseSites          :   respData.data.sites
                });
                if(!editallocation) {
                    
                }
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null})
        }
    }
   
    onItemSelect                        =   (selectedOption, key, qty = 1, allocation_price = null , pre_selected_stock =  null,addRow = true,fromPickItem=false)  =>  {
       
        if(selectedOption) {
            let allocationItemRows         =   this.state.allocationItemRows;
        
            let itemdata                    =   selectedOption.itemdata;
            if(!allocation_price) {
                allocation_price           =   itemdata && itemdata.allocation_price ? itemdata.allocation_price : 0;
            }
            
            let row                         =   {...allocationItemRows[key],demand_pending_qty : selectedOption && selectedOption.demand_pending_qty ? selectedOption.demand_pending_qty : 0,demand_item_id : selectedOption && selectedOption.demand_item_id ? selectedOption.demand_item_id : '',demand_transaction_id:selectedOption && selectedOption.demand_transaction_id ? selectedOption.demand_transaction_id : '', item : itemdata , qty : qty , rate : allocation_price ? allocation_price : itemdata && itemdata.purchase_price ? itemdata.purchase_price : '',remark : selectedOption.remark ? selectedOption.remark : "",allocation_item_id : pre_selected_stock && pre_selected_stock.allocation_item_id ? pre_selected_stock.allocation_item_id : null,pending_qty : pre_selected_stock && pre_selected_stock.pending_qty ? pre_selected_stock.pending_qty : 0,item_current_qty : pre_selected_stock && pre_selected_stock.qty ? pre_selected_stock.qty :0 };
            allocationItemRows[key]            =   row;
            this.setState({allocationItemRows : [...allocationItemRows]}, () => {
                if(!this.state.allocationItemRows.find(ir => !ir.item)) {
                    if(addRow == true){
                        setTimeout(() => {this.addNewItemRow()}, 1000);
                    }
                }
                
                if(itemdata) {
                    //load Inventory Level of this item at given warehouse for this key :-
                    this.getItemInventoryLevel(itemdata.id, key,pre_selected_stock);
                }
                
                
            });
        }
       
    }

    getItemDemandLwisting               =   (transaction_id,updateRows=true)  =>  {
           if(transaction_id){
                this.setState({ allocationItemRows: updateRows == true ? [{ item: null }] : this.state.allocationItemRows });
                let params                          =   {
                    action                      :       'data', 
                    search_warehouse_ids        :      [this.state.allocationForm.warehouse_id],
                    search_demand_for          :       this.state.allocationForm.allocation_for,
                    search_asset_ids            :     this.state.allocationForm.asset_id ?  [this.state.allocationForm.asset_id] : [],
                    search_employee_ids         :      this.state.allocationForm.employee_id ?  [this.state.allocationForm.employee_id] : [],
                    search_workstation_ids      :      this.state.allocationForm.workstation_id ? [this.state.allocationForm.workstation_id] : [],
                    search_sub_assembly_ids     :      this.state.allocationForm.sub_assembly_id ?  [this.state.allocationForm.sub_assembly_id] : [],
                    search_transaction_id       :       transaction_id,
                    search_status_levels          :       [3,4],
                    min_pending_qty             :       0.01,
                };
            
                HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/item_demand/report/item-wise', this.props.access_token, params, {}, (resp) => {
                let respData        =       resp.data;


                let item_demand_list        =   respData.data && respData.data.length > 0 ?  respData.data.map((dt) => {
                                    return {
                                                    value : dt.transaction_id,
                                                    label : dt.transaction_id,
                                                    demand_data : dt,
                                                }
                                            }) : [];


                let allocationItemRows     =   this.state.allocationItemRows;
                if(item_demand_list && item_demand_list.length > 0){
                item_demand_list.map((demand,key) => {
                //let selectedIndentItem =  indentItemDataList && indentItemDataList.length > 0 ? indentItemDataList.find(it => it.id == itemData.item.id) : null;
                let itemData = demand.demand_data ? demand.demand_data : null;
                if(updateRows == true){
                    let row                    =           {
                                                        ...allocationItemRows[key], 
                                                        item        :       {...itemData.item_profile}  ,
                                                        qty         :       itemData.pending_qty , 
                                                        rate        :       itemData.item_profile && itemData.item_profile.purchase_price ? itemData.item_profile.purchase_price : 0,
                                                        gst         :       0,
                                                        demand_pending_qty :       itemData.pending_qty,
                                                        demand_item_id   :       itemData.demand_item_id,
                                                        demand_transaction_id : itemData.demand_transaction_id,
                                                        remark      :       '',
                                                    };
                    allocationItemRows[key]    =   row;
                    this.getItemInventoryLevel(itemData.item_id,key)
                
                }
                
                
                })
                 this.setState({allocationItemRows : allocationItemRows,})
                }
               
                this.setState({
                    demand_loading                  :       false,
                    allItemWiseDemandListing        :       item_demand_list,
                    //itemDemandLoaded : true
                })

                })
           }
            
                
    }
    
    getItemInventoryLevel               =   (item_id, key, pre_selected_stock = null, item_category = 'goods')  =>  {
        this.setState({ buttonDisabled: true });
        let allocationItemRows         =   this.state.allocationItemRows;

        if(item_category == 'goods') {
            allocationItemRows[key]        =   {...allocationItemRows[key] , stock_loading : true};
            this.setState({allocationItemRows : [...allocationItemRows]});
            
            let params                          =   {interchangeable_items_current_stock: "N", warehouse_id: this.state.allocationForm.warehouse_id};
            HttpAPICall.withAthorization('GET', this.itemCurrentStockUrl + '/' + item_id, this.props.access_token, params, {}, (resp) => {
                let current_stocks              =   resp.data.current_stock.map(cs => {
                                                        return {
                                                            value : cs.qty,
                                                            label : (<Ax>BN :  <b>{cs.batch_number}</b> <br />
                                                                ED : <b> {cs.expiry_date_display}</b><br />
                                                                Stock in hand : <b> {cs.qty} </b> <br/>
                                                                Free Stock : <b> {cs.free_qty} </b></Ax>),
                                                            stock : cs,
                                                            display_label: `${cs.batch_number} & ${cs.expiry_date_display} `
                                                        }
                                                    });
                let allocationItemRows         =   this.state.allocationItemRows;
                allocationItemRows[key]        =   {
                        ...allocationItemRows[key],
                        stock_loading : false,
                        current_stocks : current_stocks,
                        allApiCallsCompleted: true
                };
                
                let selectedStock               =   current_stocks.length > 0 ? current_stocks[0] : null;
                let findSelectedStockKey        =   null;
                if(pre_selected_stock) {
                    let preSelectedStock            =   current_stocks.find(cs => {
                                                            return cs.stock.batch_number == pre_selected_stock.batch_number 
                                                                        && cs.stock.expiry_date == pre_selected_stock.expiry_date;
                                                        });
                    if(!preSelectedStock) {
                        
                        selectedStock           =  {
                            allocation_item_id      :   pre_selected_stock.allocation_item_id,
                            batch_number            :   pre_selected_stock.batch_number,
                            expiry_date             :   pre_selected_stock.expiry_date,
                            stock : {
                                qty         :   pre_selected_stock.qty,
                                free_qty    :   pre_selected_stock.qty
                        }
                        };
                        
                    } else {
                        let cs = preSelectedStock.stock;
                        let qty             =   cs.qty;
                        let free_qty        =   cs.free_qty;
                    
        
                        if(this.state.allocationDetail) {
                            qty             =   pre_selected_stock.qty;
                            free_qty        =   free_qty + pre_selected_stock.qty;
                        
                        }
                        
                        selectedStock           =   {...preSelectedStock , allocation_item_id : pre_selected_stock.allocation_item_id, stock : {
                                    ...cs,
                                    qty         :   qty,
                                    free_qty    :   free_qty
                            }};
                    }
                }
                this.setState({allocationItemRows : [...allocationItemRows]} , () => {
                    this.onBatchChange(selectedStock, key);
                    this.handleButtonEnable()
                });
            }).then(() => {});
        } else {
            allocationItemRows[key]        =   {...allocationItemRows[key] , stock_loading : false, allApiCallsCompleted: true};
            this.setState({allocationItemRows : [...allocationItemRows]});
        }
    }

    handleButtonEnable = () => {
        const allApiCallsCompleted = this.state.allocationItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
        this.setState({ buttonDisabled: !allApiCallsCompleted });
    };
    
    onBatchChange                       =   (selectedOption, key)  =>  {
        
        if(selectedOption && selectedOption.stock) {
            
            let selected_stock              =   selectedOption.stock;
           
            let allocationItemRows         =   this.state.allocationItemRows;
            allocationItemRows[key]        =   {
                ...allocationItemRows[key], 
                selected_stock  :   selected_stock,
                stock_qty       :   selected_stock.qty,
                free_qty        :   selected_stock.free_qty,
                allocation_item_id        :   selectedOption && selectedOption.allocation_item_id ?  selectedOption.allocation_item_id : null,
            };
            this.setState({allocationItemRows : [...allocationItemRows]});
        }
    }

    onDemandChange                       =   (selectedOption, key)  =>  {
        this.setState({ allocationItemRows: [{ item: null }]});
       if(selectedOption){
        let allocationItemRows     =   this.state.allocationItemRows;
        if(this.state.allDemandListing && this.state.allDemandListing.length > 0){
            this.state.allDemandListing.filter(dl => dl.value == this.state.allocationForm.demand_transaction_id).map((demand,key) => {
                //let selectedIndentItem =  indentItemDataList && indentItemDataList.length > 0 ? indentItemDataList.find(it => it.id == itemData.item.id) : null;
                let itemData = demand.demand_data ? demand.demand_data : null;
                    let row                    =           {
                                                            ...allocationItemRows[key], 
                                                            item        :       {...itemData.item_profile}  ,
                                                            qty         :       itemData.pending_qty , 
                                                            rate        :       itemData.item_profile && itemData.item_profile.purchase_price ? itemData.item_profile.purchase_price : 0,
                                                            gst         :       0,
                                                            demand_pending_qty :       itemData.pending_qty,
                                                            demand_item_id   :       itemData.demand_item_id,
                                                            demand_transaction_id : itemData.demand_transaction_id,
                                                            remark      :       '',
                                                        };
                allocationItemRows[key]    =   row;
                this.getItemInventoryLevel(itemData.item_id,key)
            })

            
            this.setState({allocationItemRows : allocationItemRows})
        }
       }
                     
    }
    
    itemViewModalInit                       =   (data,k)  =>  {
        this.setState({ itemSearchedDetails        :    data,itemRowNumber : k})
    }

    viewallocationModalInit                       =   (data,k)  =>  {
       if(this.state.allocationForm?.asset_id || this.state.allocationForm?.workstation_id){
            if(data){
                this.setState({    
                    itemSearchedDetails   :    data,
                    itemRowNumber         :    k
                },() => this.allocationHistoryRef.current.modalInit(data,this.state.allocationForm.workstation_id,this.state.allocationForm.asset_id,this.state.allocationForm.allocation_for))   
            }
       }else{
            toast.error('Please Select Asset/Workstation first', { position: toast.POSITION.TOP_RIGHT });
       }
       
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.allocationItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.allocationItemRows[k] , item  : itemdata};
        this.setState({allocationItemRows : allRows});
    }

    
    itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.allocationItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    editItemModalInit          =       (data)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.allocationItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });
        
    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.allocationItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
            
            }
        });
        
    }

     //********************EDIT ITEM DETAILS ******************************* */


    submitEditItem          =       ()      =>      {
        HttpAPICall.withAthorization('GET',this.itemDetailUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,this.state.allocationItemRows[this.state.itemRowNumber]?.qty)
               });
        })
       
    }
    
    addNewItemRow                       =   ()  =>  {
        this.setState({allocationItemRows : [...this.state.allocationItemRows, ...this.initAllocationItemRows ] });
    }

    removeItemRow                       =   (k) =>  {
        let allocationItemRows             =   this.state.allocationItemRows;
        if(allocationItemRows.length > 1) {
            allocationItemRows.splice(k, 1);
            this.setState({allocationItemRows : allocationItemRows});
        }
    }

     //****************************CLOSE allocation *********************/
    closeEvent              =           ()      =>      {
        if(this.state.allocationDetail){
            this.props.history.push({pathname: `/allocation_list`, state: {allocationData : this.state?.allocationDetail }})
        }else{
            this.props.history.push({pathname: `/allocation_list`})
        }
    }

    addItemsfromSearch          =       (selectedItems)     =>  {
        let allocationItemRows = selectedItems.map((pu_item, k) => {
            return {
                item                        :          {...pu_item.item,isAddRemark : pu_item.remark ? true : false},
                req_allocation_loading      :           true,
                req_allocation              :           [],
                id                          :           pu_item.item.id,
                qty                         :           pu_item.qty,
                rate                        :           pu_item.rate,
                remark                      :           '',
                gst                         :           pu_item.gst,
                discount_type               :           '',
                discount_rate               :           '',
                po_item_id                  :           '',
                req_item_id                 :           '',
                selected_allocation         :           null,
                expiry_date                 :           '',
                batch_number                :           '',
                stock_id                    :           null,
            };
        });
        let allRows = this.state.allocationItemRows ?  this.state.allocationItemRows.filter(i => i.item ? true : false) : [];
        let allocationItems = allRows.concat(allocationItemRows);
        allocationItems.forEach((consumedItem, key) => {
            this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,false,false);
        });
        
    
    }

    allocationFormSubmitHandler = (event, status = 1) => {
        event.preventDefault();
        let showErrors = [];
        let formData = {
            ...this.state.allocationForm,
            status: status,
            transaction_date: moment(this.state.allocationForm.transaction_date, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            employee_id: this.state.allocationForm.allocation_for === "employee" && this.state.searchedEmployee ? this.state.searchedEmployee.value : ''
        };
    
        let items = this.state.allocationItemRows.filter(ir => ir.item).map(i => {
            return {
                id: i.item.id,
                batch_number: i.selected_stock ? i.selected_stock.batch_number : '',
                expiry_date: i.selected_stock ? i.selected_stock.expiry_date : '',
                allocation_item_id: i.allocation_item_id ? i.allocation_item_id : '',
                qty: i.qty,
                free_qty: i.item.category !== "services" ? i.free_qty : i.qty,
                rate: i.rate,
                remark: i.remark,
                demand_item_id: i.demand_item_id,
                demand_pending_qty: i.demand_pending_qty ? i.demand_pending_qty : 0,
                demand_transaction_id: this.state.allocationForm.demand_transaction_id
            };
        })
    
        // Validate basic fields
        if (formData.transaction_date === '') {
            showErrors = [...showErrors, 'Please enter Transaction Date.'];
        }
    
        if (formData.warehouse_id === '') {
            showErrors = [...showErrors, 'Please select Warehouse.'];
        }
    
        if (formData.allocation_for === '') {
            showErrors = [...showErrors, 'Please select allocation for.'];
        }
    
        if (formData.allocation_for === 'asset') {
            if (formData.asset_id === '') {
                showErrors = [...showErrors, 'Please select Asset.'];
            }
        } else if (formData.allocation_for === 'workstation') {
            if (formData.workstation_id === '') {
                showErrors = [...showErrors, 'Please select Workstation.'];
            }
        }
    
        if (items.length === 0) {
            showErrors = [...showErrors, 'Please select Item and their allocation qty.'];
        } else if (items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors = [...showErrors, 'Every Item\'s allocation qty must be more than 0.'];
        } else if (status === '34') {
            if (items.find(i => i.qty > i.free_qty)) {
                showErrors = [...showErrors, 'In Allocate Items, allocation qty must be less than Free Qty.'];
            }
        } else if (this.state.negative_stock === 'N') {
            if (items.find(i => i.qty > i.free_qty)) {
                showErrors = [...showErrors, 'allocation qty can not more than Free Qty.'];
            }
        }
    
        
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            formData = {
                ...formData,
                items: items,
            };
           
            if (this.state.allocationDetail) {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('PUT', this.allocationUrl, this.props.access_token, {}, { ...formData, transaction_id: this.state.allocationDetail.transaction_id }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/allocation_list`, state: { allocationId: response.data.transaction_id } });
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('POST', this.allocationUrl, this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/allocation_list`, state: { allocationId: response.data.transaction_id } });
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }
    


   pickItemModalInit        =       ()          =>      {
       this.pickItemModal.show()
   }

   pickItemDemandModalInit        =       ()      =>      {
    this.pickItemDemandModal.show();
        // if(!this.state.itemDemandLoaded && this.state.allocationForm.demand_transaction_id){
        //     this.getItemDemandLwisting(this.state.allocationForm.demand_transaction_id)
        // }
    }

    getAllDemandListing        =       (selectedOption)      =>      {
    
        
       if(selectedOption){
        this.setState({demand_listing_loading  : true})
        let params                          =   {
            action                      :       'data', 
            search_warehouse_ids        :      [this.state.allocationForm.warehouse_id],
            search_demand_for          :       this.state.allocationForm.allocation_for,
            search_asset_ids            :     this.state.allocationForm.asset_id ?  [this.state.allocationForm.asset_id] : [],
            search_employee_ids         :      this.state.allocationForm.employee_id ?  [this.state.allocationForm.employee_id] : [],
            search_workstation_ids      :      this.state.allocationForm.workstation_id ? [this.state.allocationForm.workstation_id] : [],
            search_sub_assembly_ids     :      this.state.allocationForm.sub_assembly_id ?  [this.state.allocationForm.sub_assembly_id] : [],
            search_status_levels          :       [3,4],
            more_than_pending_qty        :       0,
            per_page                    :       100,
            page                        :       1
        };
        HttpAPICall.withAthorization('GET',  this.fetchDemandUrl, this.props.access_token, {...params},null , (resp) => {
            let respData = resp.data;
            this.setState({
                allDemandListing : respData && respData.data && respData.data.length > 0 ? respData.data.map(dt => {return({value : dt.transaction_id,label : dt.transaction_id})}) : []
            })
            
        },(err) => {
            
        }).then(() => this.setState({demand_listing_loading  : false}))
       }
   
    }

    
    pageTitlejsx                        =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.allocationDetail ? "Edit" : "New"} Allocation {this.state.allocationDetail ? <span> of Transaction ID : {this.state.allocationDetail.transaction_id}</span> : null}</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
    
    allocationItemsJsx                 =   ()  =>  {
        return (<div className="my-3 row">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary align-middle">
                        <tr className="">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" >Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Demand Pending Qty</th>
                            {this.state.track_batch_expiry == "Y"
                                ? <th scope="col" className="text-center" style={{ width: "18%" }}>Batch Number <br /> Expiry Date</th>
                                : null}
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "10%" }}>Amount</th>
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.allocationItemRows.map((itemRow, itemKey) => {
                            let item = itemRow.item;
                           
                            return <tr className="" key={itemKey}>
                                <td className="text-center align-middle">{itemKey + 1}</td>
                                {(item
                                    ? <Ax>
                                        <td>
                                        <div className="row">
                                            <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>{item.name}</span></div>
                                               
                                                <div className='col-sm-2 text-end'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li><a href="#" className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</a></li>
                                                        {this.props?.permissions?.permissions?.includes("isc-item-edit") &&  <li><a href="#" className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => {this.itemViewModalInit(item, itemKey); this.editItemModalInit(item)})  }}>Edit Item</a></li>}
                                                       <li className="dropdown-item" onClick={() => { this.addRemark(itemRow.item, itemKey) }}>Add Remark</li>
                                                    </ul>
                                                </div>
                                            </div>
                                           
                                            <div className="form-text"><b>Code: </b>{item.item_code}</div>
                                            <div className="form-text">
                                                <b>Mfr: </b>{item.manufacturer ? item.manufacturer.name : '-'} (<b>MPN:</b> {item.manufacturer_part_no ? item.manufacturer_part_no : '-'})
                                            </div>
                                            {itemRow.item && itemRow.item.isAddRemark && itemRow.item.isAddRemark == true
                                                ?
                                                <input
                                                    name="remark"
                                                    type="text"
                                                    value={itemRow.remark}
                                                    onChange={(e) => this.dynamicInputHandlerByKey(e, itemKey, 'allocationItemRows')}
                                                    className="form-control mt10"
                                                    autoComplete="off"
                                                    placeholder="Item Remarks"
                                                />
                                                : null}

                                        </td>
                                        
                                      
                                        {
                                            itemRow.stock_loading && item.category == "goods"
                                                ? <td colSpan={this.state.track_batch_expiry == 'Y' ? 5 : 4}><Loader /></td>
                                                : (<Ax>
                                                    <td>
                                                        <small className={['my-1',].join(' ')}>
                                                            {this.state.allocationForm.demand_transaction_id ? <><b>Pending Qty: </b> {itemRow.demand_pending_qty} <br /></> : "-"}
                                                        </small>

                                                    </td>
                                                    {this.state.track_batch_expiry == "Y"
                                                        ? <td>
                                                            {item.category == "goods" ? (<Ax>
                                                                {itemRow && itemRow.current_stocks && itemRow.current_stocks.length > 1 ?
                                                                    <TapSelect
                                                                        changeEvent={(selectedOption, e) => { this.onBatchChange(selectedOption, itemKey); }}
                                                                        options={itemRow.current_stocks}
                                                                        isSearchable={false}
                                                                        placeholder="Change"
                                                                        isDisabled={this.state.allocationDetail && itemRow.pending_qty !== itemRow.item_current_qty ? true  :false}
                                                                    />
                                                                    : null}
                                                                <div className='py-1'>
                                                                    Batch Number : <b> {itemRow.selected_stock ? itemRow.selected_stock.batch_number : "-"} </b> <br />
                                                                   Expiry Date : <b> {itemRow.selected_stock ? itemRow.selected_stock.expiry_date_display : "-"} </b><br />
                                                                </div>
                                                            </Ax>) : (<div className="align-middle text-center">-</div>)}
                                                        </td> : null
                                                    }
                                                    <td>
                                                        <TapInputNumber
                                                            name="qty"
                                                            value={itemRow.qty}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'allocationItemRows',null,'qty',e)}
                                                            placeholder="Qty"
                                                            required={true}
                                                            min={.01}
                                                            disabled={this.state.allocationDetail && itemRow.pending_qty !== itemRow.item_current_qty ? true  :false}
                                                        />
                                                        <span className="text-helping input-group-text text-end pr0">{item && item.measuring_unit ? item.measuring_unit.name : ''}</span>
                                                        {item.category !== "services" && (
                                                            <small className={['my-1', (itemRow.qty > (itemRow.selected_stock ? itemRow.free_qty : 0)) ? 'text-danger' : ''].join(' ')}>
                                                                <b>Stock In Hand: </b> {itemRow.selected_stock ? itemRow.stock_qty : "Nil"} <br />
                                                                <b>Free Stock: </b> {itemRow.selected_stock ? itemRow.free_qty : "Nil"} <br />
                                                            </small>
                                                        )}
                                                    </td>


                                                    <td>
                                                        <TapInputNumber
                                                            name="rate"
                                                            value={itemRow.rate}
                                                            onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'allocationItemRows',null,'rate',e)}
                                                            placeholder="Unit Rate"
                                                            required={true}
                                                             disabled={this.state.allocationDetail && itemRow.pending_qty !== itemRow.item_current_qty ? true  :false}
                                                        />
                                                    </td>
                                                    <td className="pt-4 text-end">{(itemRow.rate * itemRow.qty).toFixed(2)}</td>

                                                </Ax>)}

                                    </Ax>
                                    : <Ax>
                                        <td className="align-middle">
                                            <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.onItemSelect(selectedOption, itemKey);
                                                }}
                                                only_stocked_item={this.state.only_stocked_item}
                                                stock_warehouse_ids={[this.state.allocationForm.warehouse_id]}
                                                only_active_item='Y'
                                            />
                                        </td>
                                        <td colSpan={this.state.track_batch_expiry == 'Y' ? 5 : 4}></td>
                                    </Ax>
                                )}
                                <td className="text-center align-middle">
                                    {/* {this.state.allocationDetail && itemRow.item_current_qty == itemRow.pending_qty ? */}
                                        {this.state.allocationItemRows.length > 1 && !this.state.allocationDetail
                                            ? <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                                <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                            </a>
                                            : this.state.allocationItemRows.length > 1 && this.state.allocationDetail && itemRow.item_current_qty == itemRow.pending_qty
                                            ? <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a> :  null}
                                        {/* : null} */}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <div className="row">
                    <div className="col-sm-8">
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={this.state.only_stocked_item == "Y" ? "N" : "Y"}
                                checked={this.state.only_stocked_item == "Y"}
                                onChange={(e) => { this.setState({ only_stocked_item: e.target.value, }); }}
                                id="onlyStockedItem"
                                // disabled={this.state.negative_stock == 'N' ? true : false}
                            />
                            <label className="form-check-label" htmlFor="onlyStockedItem">
                                Show only those items that are available in the selected warehouse
                            </label>
                        </div>
                    </div>
                    {!this.state.allocationForm.demand_transaction_id &&  <div className="col-sm-4 text-end">
                    {this.state.allocationForm && this.state.allocationForm.warehouse_id  ?
                    <a role="button"  onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                    : null} 
                      {this.props?.permissions?.permissions?.includes("isc-item-add") &&  <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>}
                   
                </div>
            </div>
        </div>);
    }

    checkAllItemDemandList() {
        let indentItemForCheckbox      =   document.getElementsByClassName('demand_id_checkbox')
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                indentItemForCheckbox[i].checked = document.getElementById('all_check_demand').checked;
            }
        }
    }

    submitPickItemDemandFormSubmit          =   (e)         =>      {
        e.preventDefault();
        let itemDataList                          =    [];
        let allocationItemRows        =       [];
        let indentItemForCheckbox      =   document.getElementsByClassName('demand_id_checkbox');
        if(indentItemForCheckbox && indentItemForCheckbox.length > 0) {
            for (let i = 0; i < indentItemForCheckbox.length; i++) {
                if(indentItemForCheckbox[i].checked) {
                    
                  let selectedItemRow = this.state.allItemWiseDemandListing && this.state.allItemWiseDemandListing.length > 0 ? this.state.allItemWiseDemandListing.find(indent => indent.demand_data.demand_item_id == indentItemForCheckbox[i].value )  : null
                    itemDataList.push(selectedItemRow)
                }
            }
        }
        
        if (itemDataList.length == 0) {
            toast.error('Please select atleast one Item', { position: toast.POSITION.TOP_RIGHT });
        }else {
           
            itemDataList.forEach((consumedItem, key) => {
                let data = consumedItem & consumedItem.demand_data ? consumedItem.demand_data : null;
                let item = data && data.item_profile ? data.item_profile : null 
                this.onItemSelect({...consumedItem ,demand_item_id : consumedItem.demand_data && consumedItem.demand_data.demand_item_id ? consumedItem.demand_data.demand_item_id : '',
                    demand_pending_qty : consumedItem.demand_data && consumedItem.demand_data.pending_qty ? consumedItem.demand_data.pending_qty : '',
                    demand_transaction_id : consumedItem.demand_data && consumedItem.demand_data.transaction_id ? consumedItem.demand_data.transaction_id : '', itemdata : {...consumedItem.demand_data.item_profile,isAddRemark : consumedItem?.remark ? true : false,demand_item_id : data && data.demand_item_id ? data.demand_item_id : '' ,demand_transaction_id : data && data.transaction_id ? data.transaction_id : '' }}, this.state.allocationItemRows.length + key ,consumedItem.new_qty ? consumedItem.new_qty : 0, 0,null,false,true);
            });

            
            this.pickItemDemandModal.hide();
           
        }
    }


    pickDemandItemModalJsx              =   ()  =>  {
        return (
            <div className="modal fade" id="pickItemDemandModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="vendorDetailsModalLabel">Pick Item from Approved Demand </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                       
                            <div className="modal-body">
                                <table className="table table-hover table-borderless my-2">
                                    <tbody>
                                        <tr>
                                            <td style={{ width: "25%" }}>Warehouse</td>
                                            <td>{this.state?.warehouseDetail?.name}</td>
                                        </tr>
                                       

                                    </tbody>
                                </table>
                                <form onSubmit={this.submitPickItemDemandFormSubmit} className="bg-white"  id="pickDemandForm"  method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive table-sm bg-white mt-3">
                                    <thead className="table-secondary">
                                        <tr  className="text-end">
                                            <th scope="col" style={{ width: "5%" }} className="text-center">
                                            <input type="checkbox" onChange={this.checkAllItemDemandList} id="all_check_demand" />
                                                </th>
                                            <th scope="col" className="text-start" style={{ width: "25%" }}>Item Name</th>
                                            <th scope="col" className="text-start" style={{ width: "25%" }}>Item Code</th>
                                            <th scope="col" style={{ width: "15%" }}>Demand ID</th>
                                            <th scope="col" style={{ width: "15%" }}>Pending Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {this.state.demand_loading ? <tr colSpan={5}><td colSpan={5} className='text-center'><Loader/></td></tr> : this.state.allItemWiseDemandListing && this.state.allItemWiseDemandListing.length > 0 ? this.state.allItemWiseDemandListing.map((dl,k) => {
                                            let data = dl.demand_data ? dl.demand_data : null;
                                            let item = data && data.item_profile ? data.item_profile : null;
                                            return(<tr className="text-end">
                                                 <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                name="demand_id_checkbox"
                                                                className="demand_id_checkbox"
                                                                value={data.demand_item_id}
                                                                data-itemId={data.item_id}
                                                                data-qty={data?.new_qty}
                                                                // disabled={this.state.allocationItemRows.find(element => {
                                                                //     if (element?.demand_item_id == data.demand_item_id && element?.item?.id == data.item_id) {
                                                                //         return true;
                                                                //     } else {
                                                                //         return false;
                                                                //     }
                                                                // })}
                                                            />
                                                        </td>
                                                <td className="text-start">{item && item.name ? item.name : ''}</td>
                                                <td className="text-start">{item && item.item_code ? item.item_code : ''}</td>
                                                <td className="text-center">{data && data.transaction_id ? data.transaction_id : ''}</td>
                                                <td>{data && data.pending_qty ? data.pending_qty : ''}</td>

                                            </tr>)
                                        }) : <tr colSpan={5}><td colSpan={5} className='text-center'>No Record</td></tr>}
                                        
                                    </tbody>
                                </table>
                                 </form>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" disabled={this.state.savePickItemSubmitting} className="btn btn-primary" form="pickDemandForm">Add to Allocation {this.state.savePickItemSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                            </div>
                       
                    </div>
                </div>
            </div>
        );
    }

   
    saveallocationFormJsx              =   ()  =>  {
        let sub_total_amount                =   0;
        this.state.allocationItemRows.forEach((item,key) => {
            if(item && item.item) {
                    sub_total_amount     +=  item.rate * item.qty;
            }
        });
        
        let transactionDate = this.state.allocationForm && this.state.allocationForm.transaction_date ?  moment(this.state.allocationForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
        
        return (<form className="bg-white p-3" onSubmit={this.allocationFormSubmitHandler}>
            
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.allocationForm.transaction_date
                                ? moment(this.state.allocationForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'allocationForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        required={true}
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'allocationForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        required={true}
                        //search_site_id={this.state.ticketSelectedSite ? this.state.ticketSelectedSite : ""}
                        isDisabled={this.state.allocationDetail ? true : false}
                        value={this.state.allWarehouses.find(m => m.value == this.state.allocationForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }

            <div className="row my-3">
                <div className="col-sm-3">
                    <label className="form-label require">Allocation For</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'allocationForm', 'allocation_for', () => {
                                this.setState({
                                    selectedAsset: null,
                                    searchedWorkstation: null,
                                    searchedEmployee: null,
                                    searchSubassembly: null,
                                    allDemandListing  : [],
                                    allItemWiseDemandListing : [],

                                    allocationForm: {
                                        ...this.state.allocationForm,
                                        workstation_id: '',
                                        asset_id: '',
                                        employee_id: '',
                                        sub_assembly_id: '',
                                        demand_transaction_id   : '',
                                    },
                                })
                            });
                        }}
                        options={this.state.allAllocationsFor}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.allocationForm.ticket_id}
                        value={this.state.allAllocationsFor.find(s => s.value == this.state.allocationForm.allocation_for)}
                        placeholder="Select Allocation For"
                    />
                </div>
                <div className="col-sm-4">
                    {this.state.allocationForm.allocation_for == "asset"
                        ? <Ax>
                            <InputAssetSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'allocationForm', 'asset_id',() =>  this.getAllDemandListing(selectedOption));
                                    this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                                }}
                                placeholder="Search Asset"
                                search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                filter={true}
                                required={true}
                                disabled={this.state.allocationForm.ticket_id}
                                value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                            />

                            {this.state.selectedAsset
                                ?
                                <AssetMiniDetail assetDetail={this.state.selectedAsset} />

                                : null
                            }
                        </Ax>
                        : this.state.allocationForm.allocation_for == "workstation"
                            ? <Ax>
                                <InputWorkstationSearch
                                    placeholder="Please Select Workstation"
                                    changeEvent={(selectedOption) => {
                                        this.setState({
                                            searchedWorkstation: selectedOption ? selectedOption : null,
                                            allocationForm: { ...this.state.allocationForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                        },() => this.getAllDemandListing(selectedOption))

                                    }}
                                    menuPlacement="top"
                                    disabled={this.state.allocationForm.ticket_id}
                                    search_site_id={this.state.warehouseSites.map(wk => wk.id)}
                                    value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                />

                            </Ax>
                            : this.state.allocationForm.allocation_for == "employee"
                                ? <Ax>
                                    {this.props.permissions.group_modules.includes("hrm")
                                        ?
                                        <InputEmployeeSearch
                                            placeholder="Please Select Employee"
                                            search_site_ids={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'allocationForm', 'employee_id',() => this.getAllDemandListing(selectedOption));
                                                this.setState({ searchedEmployee: selectedOption ? selectedOption : null });
                                            }}
                                            transaction_date={transactionDate ? transactionDate : null}
                                            disabled={this.state.allocationForm.ticket_id}
                                            value={this.state.searchedEmployee ? this.state.searchedEmployee : null}
                                        />

                                        : null}
                                </Ax> : this.state.allocationForm.allocation_for == "sub_assembly"
                                    ? <Ax>
                                        <InputSubAssemblySearch
                                            placeholder="Search Sub Assembly"
                                            search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'allocationForm', 'sub_assembly_id',() => this.getAllDemandListing(selectedOption));
                                                this.setState({ searchSubassembly: selectedOption ? selectedOption : null });
                                            }}
                                            required={true}
                                            menuPlacement="top"
                                            isMulti={false}
                                            filter={true}
                                            disabled={this.state.allocationForm.ticket_id}
                                            value={this.state.searchSubassembly ? this.state.searchSubassembly : null}
                                        />

                                    </Ax> : null
                    }
                </div>
            </div>
           
   
             <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'allocationForm', 'status')
                        }}
                        options={this.state.allStatus}
                        isSearchable={true}
                        required={true}
                        value={this.state.allStatus.find(m => m.value == this.state.allocationForm.status)}
                        placeholder="Please Select Status"
                        isDisabled={this.state.allocationDetail ? true  : false}
                    />
                </div>
            </div>
            {this.state.ticket_allocation == 'Y' && 
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label">Ticket</label>
                </div>
                <div className="col-sm-4">
                <InputTicketSearch
                        changeEvent={(selectedOption) => {
                            let warehouseList = this.state.allWarehouses
                            if(selectedOption && selectedOption.data){
                                warehouseList = warehouseList.filter(st =>  st.site_id.includes(selectedOption.data.site.id))       
                            }else{
                                warehouseList = this.props.all_warehouses && this.props.all_warehouses.length > 0 ? this.props.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_ids}; }) : []
                            }
                           this.setState({ 
                               allocationForm : {
                                    ...this.state.allocationForm,
                                    ticket_id   :   selectedOption && selectedOption.value ? selectedOption.value : '',
                                    sub_assembly_id : selectedOption && selectedOption.data && selectedOption.data.subAssembly ? selectedOption.data.subAssembly.enc_id : this.state.allocationForm.sub_assembly_id,
                                    workstation_id : selectedOption  && selectedOption.data && selectedOption.data.workStation ? selectedOption.data.workStation.id : this.state.allocationForm.workstation_id,
                                    employee_id : selectedOption  && selectedOption.data && selectedOption.data.employee ? selectedOption.data.employee.enc_id : this.state.allocationForm.employee_id,
                                    asset_id : selectedOption && selectedOption.asset ? selectedOption.asset.asset_id : this.state.allocationForm.asset_id,
                                    allocation_for   :  selectedOption && selectedOption.data && selectedOption.data.ticket_for ? selectedOption.data.ticket_for : this.state.allocationForm.allocation_for
                                },
                                allWarehouses       :   warehouseList,
                                searchSubassembly   :   selectedOption && selectedOption.data && selectedOption.data.subAssembly ? {subAssembly :selectedOption.data.subAssembly, value : selectedOption.data.subAssembly.enc_id, label : `${selectedOption.data.subAssembly.name}`,display_label : `${selectedOption.data.subAssembly.name}` } : null,
                                searchedEmployee    :   selectedOption && selectedOption.data.employee ? {value : selectedOption.data.employee.enc_id, label : `${selectedOption.data.employee.name}` } : null,
                                selectedAsset       :   selectedOption  && selectedOption.asset ? {...selectedOption.asset, label : `${selectedOption.asset.name} (${selectedOption.asset.asset_code})` } : null,
                                searchedWorkstation     :   selectedOption && selectedOption.data.workStation ? {value : selectedOption.data.workStation.id, label : `${selectedOption.data.workStation.workstation_name}` } : null,
                                selectedTicket: selectedOption ? { ...selectedOption.asset,asset_id : selectedOption && selectedOption.asset ? selectedOption.asset.asset_id : '', label: selectedOption.display_label,data:selectedOption && selectedOption.data  ? selectedOption.data : null } : null,
                             },() => {
                                
                            });
                        }}
                        placeholder="Search Ticket"
                        search_site_id={this.state.warehouseSites.map(st => st.id)}
                        search_asset_id={this.state.allocationForm.asset_id ? this.state.allocationForm.asset_id : null }
                        search_employee_id={this.state.allocationForm.employee_id ? this.state.allocationForm.employee_id : null}
                        search_workstation={this.state.allocationForm.workstation_id ? this.state.allocationForm.workstation_id : null}
                        search_sub_assembly={this.state.allocationForm.sub_assembly_id ? this.state.allocationForm.sub_assembly_id : null}
                       // disabled={(!this.state.allocationForm.warehouse_id)}
                        value={this.state.selectedTicket ? { ...this.state.selectedTicket } : null}
                        search_exclude_ticket_status={[0,26,10]}
                    />
                 
                </div>
            </div>}
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="voucher_number" className="form-label">Voucher Number & Date</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="voucher_number"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.allocationForm.voucher_number}
                        onChange={(e) => this.formInputHandler(e, 'allocationForm')}
                        placeholder="Enter Voucher Number"
                    />
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={this.state.allocationForm.voucher_date ? moment(this.state.allocationForm.voucher_date).toDate() : false}
                        name="voucher_date"
                        onChange={(value, event) => this.formDateHandler('voucher_date', value, 'allocationForm')}
                        // maxDate={moment().toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Enter Voucher Date`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="allocation_date" className="add_calender-icon" />
                </div>
            </div>
            {/* <div className="row align-items-center my-3 ">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Item Demand <br /></label>
                </div>
                <div className="col-sm-7">
                    <button
                        type="button"
                        disabled={this.state.allocationForm.warehouse_id && (this.state.allocationForm.asset_id || this.state.allocationForm.sub_assembly_id || this.state.allocationForm.employee_id || this.state.allocationForm.workstation_id) ? false : true}
                        className="btn btn-link text-decoration-none"
                       onClick={() => this.pickItemDemandModalInit()}
                    ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />  Pick Item from Approved Item Demand</button>
                </div>


            </div> */}
             <div className="row align-items-center my-3">
                    <div className="col-sm-3">
                        <label htmlFor="site_id" className="form-label ">Item Demand</label>
                    </div>
                {this.state.demand_listing_loading ? <div className='text-center col-sm-8'><Loader /></div>
                    : <Ax>
                        <div className="col-sm-4">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'allocationForm', 'demand_transaction_id', () => this.getItemDemandLwisting(selectedOption ? selectedOption.value : ''))
                                    // this.setState({ allocationItemRows: [{ item: null }],}) 
                                }}
                                options={this.state.allDemandListing}
                                isSearchable={true}
                                isClearable={true}
                                required={true}
                                isDisabled={this.state.allocationForm.warehouse_id && !this.state.allocationDetail && (this.state.allocationForm.asset_id || this.state.allocationForm.employee_id || this.state.allocationForm.workstation_id || this.state.allocationForm.sub_assembly_id) ? false : true}
                                value={this.state.allDemandListing.find(s => s.value == this.state.allocationForm.demand_transaction_id)}
                                placeholder="Select Item Demand"
                            />
                        </div>
                        <div className="col-sm-4">
                            <button
                                type="button"
                                disabled={this.state.allocationForm.demand_transaction_id ? false : true}
                                className="btn btn-link text-decoration-none"
                                onClick={() => this.pickItemDemandModalInit()}
                            ><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} />  Pick Item from Approved Item Demand</button>
                        </div>
                    </Ax>
                }
            </div>

            {this.allocationItemsJsx()}
            <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.allocationForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'allocationForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table table-borderless">
                        <tbody>
                            <tr className="subtotal_bg_color"><th>Sub Total</th><td className="text-end">{(sub_total_amount).toFixed(2)}</td></tr>
                            <tr className="subtotal_bg_color pt-2">
                                <th>Adjustment</th>
                                <td>
                                    <input
                                        name="adjustment_amount"
                                        type="number"
                                        className="form-control text-end"
                                        autoComplete="off"
                                        value={this.state.allocationForm.adjustment_amount}
                                        onChange={(e) => this.formInputHandler(e, 'allocationForm')}
                                        placeholder="Adjustment Amount"
                                    />
                                </td>
                            </tr>
                            <tr className="subtotal_bg_color">
                                <th>Final Total</th>
                                <td className="text-end">
                                    {this.state.allocationForm.adjustment_amount
                                        ? (sub_total_amount + parseFloat(this.state.allocationForm.adjustment_amount)).toFixed(2)
                                        : (sub_total_amount).toFixed(2)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
           {!this.state.buttonDisabled && <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                {!this.state.allocationDetail || (this.state.allocationDetail && this.state.allocationDetail.status == 0)
                    ? <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-secondary ms-2" onClick={(e) => { this.allocationFormSubmitHandler(e, 1); }}>
                        Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                    </button>
                    : null
                }
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-primary ms-2" onClick={(e) => { this.allocationFormSubmitHandler(e, this.state.allocationForm.status); }}>
                    Save  {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>

            </div>}
        </form>);
    }
    
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Add Allocation</title></Helmet>
            {this.pageTitlejsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    { this.state.allocationFormDataLoaded 
                        ? this.saveallocationFormJsx()
                        : <Loader/>
                    }
                    </div>
                </div>
            </div>
            {/* {this.allocationHistoryModalJsx()} */}
            {this.pickDemandItemModalJsx()}
            {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
            {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
            <ItemDetailsModal itemData={this.state.itemSearchedDetails} closeEvent={this.itemDetailsClose} /> 
            <allocationHistoryModal ref={this.allocationHistoryRef}/>
            {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                            afterSelectingItems={(selectedItems) => {
                                this.addItemsfromSearch(selectedItems);
                            }}
                        />}
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.group_sites : [],
        permissions            :   state.app.acl_info,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};

export default connect(mapStateToProps)(AllocationAdd);