import React from 'react';
import axios from 'axios';
import TapSelect from '../../../components/ui/TapSelect';
import tapIcon from '../../../services/TapIcon';
import Ax from "../../../components/hoc/Ax";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';
import Loader from "../../../components/ui/Loader/Loader";
import AppBaseComponent from "../../../components/AppBaseComponent";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { Helmet } from 'react-helmet';
import InputAssetSearch from '../../includes/ui/InputAssetSearch';
import AssetMiniDetail from '../../includes/AssetMiniDetail';
import InputEmployeeSearch from '../../includes/ui/InputEmployeeSearch';
import moment from 'moment';
import WarehouseAddress from '../includes/WarehouseAddress';
import HttpAPICall from '../../../services/HttpAPICall';
import TapApiUrls from '../../../services/TapApiUrls';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemSearchModal from '../../items/itemSearchModal';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import InputSubAssemblySearch from '../../includes/ui/InputSubAssemblySearch';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import InputTicketSearch from '../../includes/ui/InputTicketSearch';
import InputWorkstationSearch from '../../includes/ui/InputWorkstationSearch';


class ItemDemandAdd extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initIndentFormState        =       {
            transaction_date            :       moment(new Date(),'YYYY-MM-DD').toDate(),
            warehouse_id                :       '',
            status_id                   :       '',
            title                       :       '',
            demand_for                  :       '',
            workstation_id              :       '',
            asset_id                    :       '',
            employee_id                 :       '',
            tag_users                       :   [],
            sub_assembly_id             :       '',
            ticket_id                   :       ''
        }

        this.state                     =     {
            saveFormSubmitting              :     false,
            storeIndentItemRows             :     [{item : null}],
            addStoreIndentForm              :     {...this.initIndentFormState},
            selectedAsset                   :     null,
            searchedWorkstation            :     null,
            searchedEmployee                :     null,
            warehouseDetailLoading          :     false,
            warehouseDetail                 :     null,
            warehouseSites                  :     [],
            allWarehouses                   :     [],
            storeIndentFormDataLoaded       :     false,
            allStatus                       :     [],
            allIndentOptions                :     [],
            allGstSlabs                     :     [],
            itemRowNumber                   :      0  ,
            formData                        :     '',
            itemDemandData                :      null,
            form_loading                    :      false,
            allUsers                        :       [],
            interchangeItemData                 :        null,
            interchangeableItemList             :    [],
            interchangeableListingMeta          :   null,
            interchangeableTotalListingCount    :   0,
            interchangeable_loading             :   false,
            interchangeable_page                :   1,
            saveInterchangeableItemForm         :   false,
            interchangeItemForm                 :    {notes:''},
            searchSubassembly                   :   null,
            allowedStatus                       :   [],
            selectedTicket                      :   null,
            searchedWorkstation                 :   null
        };
        this.itemSearchRef              =         React.createRef();
        this.addItemModalRef            =       React.createRef();
        this.editItemModalRef            =      React.createRef();
        this.formDataUrl                    =  INVENTORY_API_BASE_URL_2  + '/item_demand/form_data';
        this.addUrl                      =  INVENTORY_API_BASE_URL_2  + '/item_demand/add';
        this.editUrl                      =  INVENTORY_API_BASE_URL_2  + '/item_demand/edit';
        this.itemDetailUrl              =   INVENTORY_API_BASE_URL_2 + '/item';
    }

    componentDidMount() {
        this.initilaizeFormData(this.props);
        this.interchangebleItemModal                 =   new Modal(document.getElementById('interchangebleItemModal'), {keyboard: false, backdrop :false});
        this.viewItemModal             =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.all_warehouses !== this.props.all_warehouses) {
            this.initilaizeFormData(nextProps);
        }
    }

    initilaizeFormData                  =   (pr)  =>  {
        let sites                       =   this.props.iam_group_sites;
         if(!this.state.storeIndentFormDataLoaded) {
            this.setState({form_loading : true})
            HttpAPICall.withAthorization('GET', this.formDataUrl, this.props.access_token, null, null, (response) => {
                 let formData = response.data
                    this.setState({
                        storeIndentFormDataLoaded   :  true ,
                        formData                    :   formData ? formData : null,
                        allIndentOptions            :   [{value:'asset',label:'Asset'},{value:'workstation',label:'Workstation'},{value:'employee',label:'Employee'},{value:'sub_assembly',label:'Sub Assembly'}],
                        allStatus                   :   formData && formData.group_status_list && formData.group_status_list.length > 0 ? formData.group_status_list.map((s) => { return {value: s.id, label: s.name}; }) : [],
                        allowedStatus               :   formData && formData.allowed_status_list && formData.allowed_status_list.length > 0 ? formData.allowed_status_list.map((s) =>  s) : [],
                        allWarehouses               :   pr.all_warehouses && pr.all_warehouses.length > 0 ? pr.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_ids}; }) : [],
                        allUsers                     :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],
                    }, () => {
                        let ParamObject                 =       new URLSearchParams(this.props.location.search);
                        if(pr.location && pr.location.state && pr.location.state.itemDemandData){
                            
                            let indentData          =       pr.location.state.itemDemandData;
                            let indentItemRows      =       pr.location.state.itemDemandData.items;
                            let updateFormState = [];
                            if (indentData.additional_attribute_data) {
                                indentData.additional_attribute_data.map((i, k) => {
                                    let name = 'additional_attributes_' + i.key;

                                    updateFormState[name] = i.value
                                });

                            }
                           
                            this.setState({
                                addStoreIndentForm              :     {...this.initIndentFormState,
                                    transaction_date            :       pr.location && pr.location.state && pr.location.state.clone ?  moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') : indentData.transaction_date,
                                    transaction_id              :       indentData.transaction_id,
                                    warehouse_id                :       indentData.warehouse.id,
                                    status_id                   :       indentData.status ? indentData.status.id : '',
                                    title                       :       indentData.title,
                                    demand_for                  :       indentData.demand_for,
                                    ticket_id                   :   indentData.ticket ? indentData.ticket.activity_id : '',
                                    asset_id                    :       indentData.asset ? indentData.asset.asset_id : null,
                                    workstation_id              :       indentData.workstation ? indentData.workstation.id : null,
                                    employee_id                 :       indentData.employee ? indentData.employee.enc_id : null,
                                    sub_assembly_id             :       indentData.sub_assembly ? indentData.sub_assembly.enc_id : null,
                                    tag_users                       :       indentData.tagged_users && indentData.tagged_users.length > 0 ? indentData.tagged_users.map(users => users.enc_id) : [],
                                    ...updateFormState
                                },
                                selectedTicket              :   indentData.ticket ? {...indentData.ticket, value: indentData.ticket.activity_id, label: `${indentData.ticket.activity_id} : ${indentData.ticket.header}`, display_label: `${indentData.ticket.activity_id} : ${indentData.ticket.header}` } : null, 
                                itemDemandData                 :     pr.location && pr.location.state && pr.location.state.clone ? null :  indentData
                            },() => {
                                let assetProfile        =   indentData.asset;
                                if(assetProfile) {
                                    this.setState({selectedAsset : {...assetProfile, label : `${assetProfile.name} (${assetProfile.asset_code})` }});
                                }
    
                                let employeeProfile        =   indentData.employee;
                                if(employeeProfile) {
                                    this.setState({searchedEmployee : {value : employeeProfile.enc_id, label : `${employeeProfile.name}` }});
                                }
                                let subAssemblyProfile        =   indentData.sub_assembly;
                                if(subAssemblyProfile) {
                                    this.setState({searchSubassembly : {value : subAssemblyProfile.enc_id, label : `${subAssemblyProfile.name}` }});
                                }
                                let workStation        =   indentData.workstation;
                                if(workStation) {
                                    this.setState({searchedWorkstation: {value : workStation.id, label : `${workStation.workstation_name}` }});
                                }
                                this.getWarehouseDetail(indentData.warehouse.id)
                                indentItemRows.forEach((consumedItem, key) => {
                                
                                    
                                    this.onItemSelect({...consumedItem,itemdata:consumedItem.item_profile,item:consumedItem.item_profile}, key ,consumedItem.qty, consumedItem.rate,false);
                                });
                                this.addNewItemRow();
                            })
                        }
                        if(ParamObject.get('ticketId')) {

                            const storedData = localStorage.getItem('selectedTicketToAdddemand');
                            if (storedData) {
                                const { asset, site_id, ticket_header, ticketFor, ticketForData } = JSON.parse(storedData);
                                let ticket_id = ParamObject.get('ticketId');
    
                                this.setState({
                                    ticketFromTicket        :   true,
                                    demand_for          :   ticketFor,
                                    selectedAsset           :   ticketFor === 'asset' && ticketForData  ? { ...ticketForData, label: `${ticketForData?.name} (${ticketForData?.asset_code})` } : null,
                                    addStoreIndentForm          :   {
                                        ...this.initIndentFormState,
                                        demand_for      :   ticketFor,
                                        asset_id            :   ticketForData && ticketFor === 'asset' && ticketForData ? ticketForData.asset_id : "",
                                        ticket_id: ticket_id,
                                        sub_assembly_id     :   ticketForData && ticketFor === 'sub_assembly' && ticketForData  ? ticketForData.enc_id : "",
                                        workstation_id      :   ticketForData && ticketFor === 'workstation' && ticketForData ? ticketForData.id : "",
                                        employee_id         :   ticketForData && ticketFor === 'employee' && ticketForData  ? ticketForData.enc_id : "", 
                                    },
                                    searchSubassembly       :   ticketFor === 'sub_assembly' && ticketForData  ? {
                                        label                   :   ticketForData.name,
                                        value                   :   ticketForData.enc_id,
                                        display_label           :   ticketForData.name,
                                        subAssembly             :   ticketForData
                                    } : null,
                                    searchedEmployee        :   ticketFor === 'employee' && ticketForData ? {
                                        value : ticketForData.enc_id, label : ticketForData.display_full_name, employee : ticketForData
                                    } : null, 
                                    ticketFromAllocation    :   ticket_id && ticketFor !== 'site' ? true : false,
                                    allWarehouses           :   this.state.allWarehouses.filter(wd => wd.site_id.includes(site_id)),
                                    selectedTicket          :   ticket_header ? {asset_id : asset ? asset.asset_id : '', value: ticket_id, label: `${ticket_id} : ${ticket_header}`, display_label: `${ticket_id} : ${ticket_header}` } : null
                                }, () => {
                                    let workstation        =   ticketForData && ticketFor === 'workstation' ? ticketForData : null;
                                    if(workstation) {
                                        this.setState({searchedWorkstation : {value:workstation.id,label:workstation.workstation_name }});
                                    }
                                })
                            } 
                          
                        } 
                    });
            }).then(() => {
                this.setState({form_loading : false});
            });
        }
    }

    onWarehouseChange                   =   (selectedOption) =>  {
       if(!this.state.selectedTicket){
        this.setState({
            selectedAsset               :   null,
            searchedWorkstation        :   null,
            searchedEmployee            :   null,
            searchSubassembly           :    null,
            warehouseSites              :   [],
            
            addStoreIndentForm             :   {
                ...this.state.addStoreIndentForm,
                workstation_id          :   '',
                asset_id                :   '',
                employee_id             :   '',
                tag_users           :       [],
                sub_assembly_id         :   ''
            },
            // storeIndentItemRows         :   [...this.iniitstoreIndentItemRows]
        }, () => {
           
        });
       }
       this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
    }
    
    getWarehouseDetail                  =   (warehouse_id)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null, warehouseSites : []});
            HttpAPICall.withAthorization('GET', TapApiUrls.WAREHOUSE_URL + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                let sites = respData.data && respData.data.sites.length > 0 ? respData.data.sites.map(st => st.id) : []
                this.setState({
                    warehouseDetail         :   respData.data,
                    warehouseSites          :   respData.data.sites,
                    allUsers                :   this.state.allUsers && this.state.allUsers.length > 0 && sites && sites.length > 0 ?  this.state.allUsers.filter(us =>  us.site_ids.some(value => sites.includes(value))) : this.state.allUsers
                });
               
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null,warehouseSites : []})
        }
    }

    onItemSelect                =           (selectedItem,key,qty = 1, price = null,addRow= true,edit=false)          =>      {
    //    console.log(selectedItem)
        if(selectedItem) {
            let itemdata                    =   selectedItem.itemdata;
            let storeIndentItemRows     =   this.state.storeIndentItemRows;
           
            if(itemdata){
                if(this.state.storeIndentItemRows.find((req_item) => req_item.item && req_item.item.id == itemdata.id) && edit == false){
                    toast.error('This Item is already available in this  Warehouse Indent, please update the item', {position: toast.POSITION.TOP_RIGHT })
                } else{
                    //let row                         =   {...storeIndentItemRows[key], item: itemdata, item : itemdata , qty : qty ? qty : this.state?.storeIndentItemRows[key]?.qty ? this.state?.storeIndentItemRows[key]?.qty : 0  , rate : price ? price : itemdata.purchase_price ? itemdata.purchase_price : 0, gst :   selectedItem.gst ? selectedItem.gst : itemdata.gst,remark:selectedItem.remark ? selectedItem.remark : '',demand_item_id:selectedItem.demand_item_id ? selectedItem.demand_item_id : null};
                    let row                         =   {...storeIndentItemRows[key], item: itemdata , itemdata : itemdata , qty : qty ? qty : this.state?.storeIndentItemRows[key]?.qty ? this.state?.storeIndentItemRows[key]?.qty : 0  , rate : price ? price : itemdata.purchase_price ? itemdata.purchase_price : 0, gst :   selectedItem.gst ? selectedItem.gst : itemdata.gst,remark:selectedItem && selectedItem.remark ? selectedItem.remark : '',demand_item_id:selectedItem && selectedItem.demand_item_id ? selectedItem.demand_item_id : null};
                    storeIndentItemRows[key]            =   row;
                    
                    this.setState({storeIndentItemRows : [...storeIndentItemRows]}, () => {
                        if(selectedItem.item_id){
                            this.getStockLevel(selectedItem.item_id,key)
                        }
                        if(!this.state.storeIndentItemRows.find(ir => !ir.item)) {
                            if(addRow == true){
                                setTimeout(() => {this.addNewItemRow()}, 1000);
                            }
                        }
                        this.interchangebleItemModal.hide()
                        
                   });
                }
            }
       
        }
    }

    getStockLevel       =           (item_id,key)      =>      {
        let storeIndentItemRows         =   this.state.storeIndentItemRows;
        storeIndentItemRows[key]        =   {...storeIndentItemRows[key] , stock_loading : true,};
        this.setState({storeIndentItemRows : [...storeIndentItemRows]});
        HttpAPICall.withAthorization('GET',  this.itemDetailUrl + '/' + item_id , this.props.access_token, {current_stock:'Y',warehouse_id:this.state.addStoreIndentForm.warehouse_id,interchangeable_current_stock:'Y'}, {}, (resp) => {
                            let respData                        =       resp.data;
                            let storeIndentItemRows         =   this.state.storeIndentItemRows;
                              let row                         =   {...storeIndentItemRows[key], item : {...respData.data}};
                        storeIndentItemRows[key]            =   row;
                       
                        this.setState({storeIndentItemRows : [...storeIndentItemRows]}, () => {
                })
            })
    }

    addNewItemRow                           =   ()  =>  {
        this.setState({storeIndentItemRows : [...this.state.storeIndentItemRows, {item : null}] });
    }

    removeItemRow                       =   (k) =>  {
        let storeIndentItemRows             =   this.state.storeIndentItemRows;
        if(storeIndentItemRows.length > 1) {
            storeIndentItemRows.splice(k, 1);
            this.setState({storeIndentItemRows : storeIndentItemRows});
        }
    }

    itemSearchModalInit             =       ()          =>      {
        let allRows                 =   this.state.storeIndentItemRows;
        let item_ids                =   [];
        if(allRows && allRows.length > 0){
            item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
        }
        this.itemSearchRef.current.searchItemModalInit(item_ids)
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.storeIndentItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.storeIndentItemRows[k] , item  : itemdata};
        this.setState({storeIndentItemRows : allRows});
    }

     //********************EDIT ITEM DETAILS ******************************* */

     editItemModalInit          =       (data)           =>      {
        this.editItemModalRef.current.itemEditModalInit(data);  
    }

    submitEditItem          =       ()      =>      {
    
        HttpAPICall.withAthorization('GET',this.itemDetailUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,true)
               });
        })
       
    }

    addItemModalInit          =       ()           =>      {
        this.addItemModalRef.current.itemAddModalInit();  
    }

      //****************************CLOSE CONSUMPTION *********************/
      closeEvent              =           ()      =>      {
        
            this.props.history.push({pathname: `/item_demand_list`})
        
    }

    itemSearchModalInit             =       ()          =>      {
        let allRows                 =   this.state.storeIndentItemRows;
        let item_ids                =   [];
        if(allRows && allRows.length > 0){
            item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
        }
        this.itemSearchRef.current.searchItemModalInit(item_ids)
    }

    addItemsfromSearch          =       (selectedItems)     =>  {
        let storeIndentItemRows = selectedItems.map((pu_item, k) => {

            return {
                item                        :          {...pu_item.item,isAddRemark : pu_item.remark ? true : false},
                remark                      :           '',
                gst                         :           pu_item.gst,
            };
        });
        let allRows = this.state.storeIndentItemRows ?  this.state.storeIndentItemRows.filter(i => i.item ? true : false) : [];
        let indentItemRows = allRows.concat(storeIndentItemRows);
        indentItemRows.forEach((consumedItem, key) => {
            this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key ,consumedItem.qty, consumedItem.rate,false,false);
        });
        
    
    }

    itemViewModalInit                       =   (data,k)  =>  {
        this.setState({ itemSearchedDetails        :    data,itemRowNumber : k})
        
    }

    viewInterchangeableModalInit        =       (itemdata=null,key)   =>      {
        
        this.interchangebleItemModal.show();
        this.setState({
            interchangeItemData     :   itemdata ,
            interchangeItemForm     :    {notes:''},
            selectedInterchangeableKey  :   key,   
        },() => this.getInterChangeableItemList(itemdata?.item?.id,1,))
    }

    getInterChangeableItemList      =       (id,page=1)      =>      {
        this.setState({ interchangeable_loading: true, interchangeable_page: page,interchangeableItemList:[] });
        HttpAPICall.withAthorization('GET', INVENTORY_API_BASE_URL_2 + '/item_current_stock/' + id, this.props.access_token, {interchangeable_items_current_stock : 'Y',warehouse_id : this.state.addStoreIndentForm.warehouse_id}, {}, (resp) => {
            let respData = resp.data;
           
             this.setState({
                interchangeableItemList                :    respData && respData.interchangable && respData.interchangable.length > 0 ?  respData.interchangable : [] ,
            //     interchangeableListingMeta             :   respData.meta,
            //     interchangeableTotalListingCount       :   respData.meta.total,
               
             })
        }).then(() => this.setState({ interchangeable_loading : false }))
    }
    
    checkAllInterchangeList                          =   ()  =>  {
        let interchangeItemCheckboxCollections      =   document.getElementsByClassName('interchange_id_checkbox');
        if(interchangeItemCheckboxCollections && interchangeItemCheckboxCollections.length > 0) {
            for (let i = 0; i < interchangeItemCheckboxCollections.length; i++) {
                interchangeItemCheckboxCollections[i].checked = document.getElementById('all_check_interchange').checked;
            }
        }
    }

    
    submitSelectedInterchangeItem = (e) => {
        e.preventDefault();
    
        const { selectedInterchangeItem, interchangeableItemList } = this.state;
        
        if (!selectedInterchangeItem) {
            toast.error('Please select at least one item', { position: toast.POSITION.TOP_RIGHT });
            return;
        }
    
        // Find the selected item
        const selectedItem = interchangeableItemList.find(item => item.item.id === selectedInterchangeItem);
        
        if (selectedItem) {
            const qty = selectedItem.free_qty; // Use appropriate attribute for quantity
            this.onItemSelect(selectedItem, this.state.selectedInterchangeableKey, qty);
        } else {
            toast.error('Selected item not found', { position: toast.POSITION.TOP_RIGHT });
        }
    }
    


    addStoreIndentFormSubmitEvent       =       (e,status = 0)     =>      {
        e.preventDefault();
        let items                   =        [...this.state.storeIndentItemRows];
        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.addStoreIndentForm[name] ? this.state.addStoreIndentForm[name] : '';
        });
        
        let itemsLists              =        items.filter(i => i.item ? true : false).map(i => {
                                                
                                                 return {...i, id : i.item.id, item : null,qty : i.qty ? i.qty : 0,remark : i.remark ? i.remark : ''};
                                                });
        let addStoreIndentForm       =      {...this.state.addStoreIndentForm , transaction_date : moment(this.state.addStoreIndentForm.transaction_date,'YYYY-MM-DD').format('YYYY-MM-DD'),status_id : status == 1 ? status : this.state.addStoreIndentForm.status_id }                          

        let showErrors                  =   [];
        let formData                        =  { ...addStoreIndentForm,items : itemsLists};

        if(formData.transaction_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }

        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status.'];
        }
     
        if(itemsLists.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and their qty.'];
        } else if(items.find(i => parseFloat(i.qty) <= 0)) {
            showErrors                      =   [...showErrors , 'Item quantity must be more than 0.'];
        }
        
       
        if (showErrors.length > 0) {
            toast.error(showErrors[0], { position: toast.POSITION.TOP_RIGHT });
        } else {
            if (this.state.itemDemandData) {
                this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('PUT', this.editUrl, this.props.access_token, {}, { ...formData, additional_attributes: additional_attributes }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/item_demand_list`,state: {indentId : response.data.transaction_id } })
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true })
                HttpAPICall.withAthorization('POST', this.addUrl, this.props.access_token, {}, { ...formData, additional_attributes: additional_attributes }, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({ pathname: `/item_demand_list` })
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
            
            
           
        }
    }


    storeIndentItemJsx          =       ()          =>      {
        return (<div className="my-3 row">
            <div className="col-12">
                <table className="table table-bordered table-responsive bg-white">
                    <thead className="table-secondary">
                        <tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" className="text-center" >Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "18%" }}>Free Stock</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Quantity</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Amount</th>
                            
                            <th scope="col" className="" style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.storeIndentItemRows.map((i, k) => {
                            let tdRows = (i.item) ? (<Ax>
                                <td className="text-start">
                                    <div className="">
                                        <div className=" d-flex justify-content-between">
                                            <div><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>{i.item.name}</span></div>
                                            <div>
                                                <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                <ul className="dropdown-menu cursor_pointer">
                                                    <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item); this.viewItemModalInit() }}>View Details</li>
                                                    {this.props?.permissions?.permissions?.includes("isc-item-edit") && <li className="dropdown-item" onClick={() => { this.itemViewModalInit(i.item, k); this.editItemModalInit(i.item) }}>Edit Item</li>}
                                                    <li className="dropdown-item" onClick={() => { this.addRemark(i.item, k) }}>Add Remark</li>
                                                    <li className="dropdown-item" onClick={() => { this.viewInterchangeableModalInit(i,k) }}>Interchange Item</li>
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="form-text"><b>Code: </b>{i.item.item_code}</div>
                                        <div className="form-text">
                                            <b>Mfr: </b>{i.item.manufacturer ? i.item.manufacturer.name : '-'} (<b>MPN:</b> {i.item.manufacturer_part_no ? i.item.manufacturer_part_no : '-'})
                                        </div>
                                        {i.item?.tags?.length > 0
                                            ? (i.item?.tags.map((t, k) => <span key={k} className="badge bg-secondary">{t.name}</span>))
                                            : null
                                        }
                                    </div>
                                    {i.item && i.item.isAddRemark && i.item.isAddRemark == true
                                        ? <input
                                            name="remark"
                                            type="text"
                                            value={this.state.storeIndentItemRows[k].remark}
                                            onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'storeIndentItemRows')}
                                            className="form-control mt10"
                                            autoComplete="off"
                                            placeholder="Item Remarks"
                                        />

                                        : null}

                                </td>
                                <td className="text-end">
                                    <small className=" text-end pr0">
                                        {/* <b >Stock In Hand: </b> {i.item  && i.item.current_stock ? i.item.current_stock.free_qty : 0} <br /> */}
                                        <b >Free Stock: </b> {i.item  && i.item.current_stock ? i.item.current_stock.free_qty : 0} <br />
                                        <span><b>Interchangeable Item:  </b> {i.item  && i.item.current_stock ? i.item.current_stock.interchangeable_free_qty : 0}</span>
                                    </small>
                                </td>
                                <td className="req_table">
                                    <input
                                        name="qty"
                                        type="number"
                                        value={this.state.storeIndentItemRows[k].qty}
                                        onChange={(e) => this.dynamicInputHandlerByKey(e, k, 'storeIndentItemRows')}
                                        className="form-control text-end"
                                        autoComplete="off"
                                        placeholder="Qty"
                                        required={true}
                                        min={1}
                                        step=".01"
                                    />
                                    <span className="text-helping input-group-text text-end pr0">{i.item?.measuring_unit?.name}</span>
                                </td>
                                <td className='text-end'>
                                    {i.item && i.item.purchase_price ? i.item.purchase_price : 0}
                                </td>
                               
                                <td className="text-end">
                                    {(this.state.storeIndentItemRows[k].qty * i?.item?.purchase_price).toFixed(2)}
                                </td>
                            </Ax>)
                                : (<Ax>
                                    <td>
                                        <InputItemSearch
                                            changeEvent={(selectedOption) => {
                                                this.onItemSelect(selectedOption, k);
                                            }}
                                            current_stock='Y'
                                            warehouse_id={this.state.addStoreIndentForm.warehouse_id}
                                            interchangeable_current_stock='Y'
                                            only_active_item='Y'
                                            
                                        />
                                    </td>
                                    <td colSpan="4" className="text-center align-middle" >-</td>
                                </Ax>);
                            return (<tr key={k}>
                                <td className="align-middle">{k + 1}</td>
                                {tdRows}
                                <td className="align-middle">
                                    <a href="#" onClick={() => this.removeItemRow(k)}>
                                        <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                    </a>
                                </td>
                            </tr>);
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-12 text-end">
                {this.state.addStoreIndentForm && this.state.addStoreIndentForm.warehouse_id ?
                    <a role="button" onClick={this.itemSearchModalInit} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                    : null}
                {this.props?.permissions?.permissions?.includes("isc-item-add") && <a role="button" onClick={this.addItemModalInit} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
            </div>
        </div>)
    }


    saveStoreIndentFormJsx                  =   ()  =>  {
        let item_total_qty          =   0;
        this.state.storeIndentItemRows.forEach((item) => {
            // Check if the item exists and has a valid quantity (assuming qty is numeric)
            // console.log(item)
            if (item && item.item) {
                // Add the quantity of the current item to item_total_qty
                item_total_qty += Number(item.qty);
            }
        });

        //console.log(this.state.storeIndentItemRows,'row')
        
        let transactionDate = this.state.addStoreIndentForm && this.state.addStoreIndentForm.transaction_date ?  moment(this.state.addStoreIndentForm.transaction_date).format('YYYY-MM-DD 00:00:00') : null;
        return (<form className="bg-white p-3" onSubmit={this.addStoreIndentFormSubmitEvent}>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Demand</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addStoreIndentForm.transaction_date
                                ? moment(this.state.addStoreIndentForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'addStoreIndentForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        disabled={this.state.itemDemandData ? true : false}
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="transaction_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.addStoreIndentForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'status_id');
                        }}
                        options={this.state.allStatus.filter(st => this.state.allowedStatus.includes(st.value))}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.itemDemandData ? true :false}
                        value={this.state.allStatus.find(s => s.value == this.state.addStoreIndentForm.status_id)}
                        placeholder="Select Status"
                    />
                </div>

            </div>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label">Title</label>
                </div>
                <div className="col-sm-4">
                    <input
                        name="title"
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        value={this.state.addStoreIndentForm.title}
                        onChange={(e) => this.formInputHandler(e, 'addStoreIndentForm')}
                        placeholder="Enter Title"
                    />
                </div>
            </div>

            <div className="row my-3">
                <div className="col-sm-3">
                    <label className="form-label require">Demand For</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'demand_for',() => {
                                this.setState({
                                    selectedAsset               :   null,
                                    searchedWorkstation        :   null,
                                    searchedEmployee            :   null,
                                    searchSubassembly           :    null,
                                    
                                    addStoreIndentForm             :   {
                                        ...this.state.addStoreIndentForm,
                                        workstation_id          :   '',
                                        asset_id                :   '',
                                        employee_id             :   '',
                                        sub_assembly_id         :   ''
                                    },
                                })
                            });
                        }}
                        options={this.state.allIndentOptions}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        isDisabled={this.state.addStoreIndentForm.ticket_id}
                        value={this.state.allIndentOptions.find(s => s.value == this.state.addStoreIndentForm.demand_for)}
                        placeholder="Select Demand For"
                    />
                </div>
                <div className="col-sm-4">
                    {this.state.addStoreIndentForm.demand_for == "asset"
                        ? <Ax>
                            <InputAssetSearch
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'asset_id');
                                    this.setState({ selectedAsset: selectedOption ? { ...selectedOption.asset, label: selectedOption.display_label } : null });
                                }}
                                placeholder="Search Asset"
                                search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                filter={true}
                                required={true}
                                disabled={this.state.addStoreIndentForm.ticket_id}
                                value={this.state.selectedAsset ? { ...this.state.selectedAsset } : null}
                            />

                            {this.state.selectedAsset
                                ?
                                <AssetMiniDetail assetDetail={this.state.selectedAsset} />

                                : null
                            }
                        </Ax>
                        : this.state.addStoreIndentForm.demand_for == "workstation"
                            ? <Ax>
                                
                                <InputWorkstationSearch
                                    placeholder="Please Select Workstation"
                                    changeEvent={(selectedOption) => {
                                        this.setState({
                                            searchedWorkstation : selectedOption ? selectedOption : null,
                                            addStoreIndentForm : { ...this.state.addStoreIndentForm, workstation_id: selectedOption ? selectedOption.value : '' }
                                        })

                                    }}
                                    menuPlacement="top"
                                    disabled={this.state.addStoreIndentForm.ticket_id}
                                    search_site_id={this.state.warehouseSites.map(wk => wk.id)}
                                    value={this.state.searchedWorkstation ? this.state.searchedWorkstation : null}
                                />

                            </Ax>
                            : this.state.addStoreIndentForm.demand_for == "employee"
                                ? <Ax>
                                    {this.props.permissions.group_modules.includes("hrm")
                                        ?
                                        <InputEmployeeSearch
                                            placeholder="Please Select Employee"
                                            search_site_ids={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'employee_id');
                                                this.setState({ searchedEmployee: selectedOption ? selectedOption : null });
                                            }}
                                            transaction_date={transactionDate ? transactionDate : null}
                                            disabled={this.state.addStoreIndentForm.ticket_id}
                                            value={this.state.searchedEmployee ? this.state.searchedEmployee : null}
                                        />

                                        : null}
                                </Ax> :   this.state.addStoreIndentForm.demand_for == "sub_assembly"
                            ? <Ax>
                                        <InputSubAssemblySearch
                                            placeholder="Search Sub Assembly"
                                            search_site_id={this.state.warehouseSites.map((w) => w.id)}
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'sub_assembly_id');
                                                this.setState({ searchSubassembly: selectedOption ? selectedOption : null });
                                            }}
                                            required={true}
                                            menuPlacement="top"
                                            isMulti={false}
                                            filter={true}
                                            disabled={this.state.addStoreIndentForm.ticket_id}
                                            value={this.state.searchSubassembly ? this.state.searchSubassembly : null}
                                        />
                               
                            </Ax> :null
                    }
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label">Ticket</label>
                </div>
                
                <div className="col-sm-4">
                    <InputTicketSearch
                        changeEvent={(selectedOption) => {
                            let warehouseList = this.state.allWarehouses
                            if(selectedOption && selectedOption.data){
                                warehouseList = warehouseList.filter(st =>  st.site_id.includes(selectedOption.data.site.id))       
                            }else{
                                warehouseList = this.props.all_warehouses && this.props.all_warehouses.length > 0 ? this.props.all_warehouses.map((s) => { return {value: s.id, label: `${s.name} (${s.code})` , site_id : s.site_ids}; }) : []
                            }
                           this.setState({ 
                               addStoreIndentForm : {
                                    ...this.state.addStoreIndentForm,
                                    ticket_id   :   selectedOption && selectedOption.value ? selectedOption.value : '',
                                    sub_assembly_id : selectedOption && selectedOption.data && selectedOption.data.subAssembly ? selectedOption.data.subAssembly.enc_id : this.state.addStoreIndentForm.sub_assembly_id,
                                    workstation_id : selectedOption  && selectedOption.data && selectedOption.data.workStation ? selectedOption.data.workStation.id : this.state.addStoreIndentForm.workstation_id,
                                    employee_id : selectedOption  && selectedOption.data && selectedOption.data.employee ? selectedOption.data.employee.enc_id : this.state.addStoreIndentForm.employee_id,
                                    asset_id : selectedOption && selectedOption.asset ? selectedOption.asset.asset_id : this.state.addStoreIndentForm.asset_id,
                                    demand_for   :  selectedOption && selectedOption.data && selectedOption.data.ticket_for ? selectedOption.data.ticket_for : this.state.addStoreIndentForm.demand_for
                                },
                                allWarehouses       :   warehouseList,
                                searchSubassembly   :   selectedOption && selectedOption.data && selectedOption.data.subAssembly ? {subAssembly :selectedOption.data.subAssembly, value : selectedOption.data.subAssembly.enc_id, label : `${selectedOption.data.subAssembly.name}`,display_label : `${selectedOption.data.subAssembly.name}` } : null,
                                searchedEmployee    :   selectedOption && selectedOption.data.employee ? {value : selectedOption.data.employee.enc_id, label : `${selectedOption.data.employee.name}` } : null,
                                selectedAsset       :   selectedOption  && selectedOption.asset ? {...selectedOption.asset, label : `${selectedOption.asset.name} (${selectedOption.asset.asset_code})` } : null,
                                searchedWorkstation    :   selectedOption && selectedOption.data.workStation ? {value : selectedOption.data.workStation.id, label : `${selectedOption.data.workStation.workstation_name}` } : null,
                                selectedTicket: selectedOption ? { ...selectedOption.asset,asset_id : selectedOption && selectedOption.asset ? selectedOption.asset.asset_id : '', label: selectedOption.display_label,data:selectedOption && selectedOption.data  ? selectedOption.data : null } : null,
                             },() => {
                                
                            });
                        }}
                        placeholder="Search Ticket"
                        search_site_id={this.state.warehouseSites.map(st => st.id)}
                        search_asset_id={this.state.addStoreIndentForm.asset_id ? this.state.addStoreIndentForm.asset_id : null }
                        search_employee_id={this.state.addStoreIndentForm.employee_id ? this.state.addStoreIndentForm.employee_id : null}
                        search_workstation={this.state.addStoreIndentForm.workstation_id ? this.state.addStoreIndentForm.workstation_id : null}
                        search_sub_assembly={this.state.addStoreIndentForm.sub_assembly_id ? this.state.addStoreIndentForm.sub_assembly_id : null}
                       // disabled={(!this.state.addStoreIndentForm.warehouse_id)}
                        value={this.state.selectedTicket ? { ...this.state.selectedTicket } : null}
                        search_exclude_ticket_status={[0,26,10]}
                    />
                </div>
            </div>
            
            <div className="row align-items-center mb-3 mt-3">
                <div className="col-3">
                    <label htmlFor="site_id" className="form-label ">Tag Users</label>
                </div>
                <div className="col-4">
                <TapSelect
                        changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'addStoreIndentForm', 'tag_users')}
                        options={this.state.allUsers}
                        isSearchable={true}
                        isClearable={true}
                        isMulti={true}
                        value={this.state.allUsers.filter(t => this.state.addStoreIndentForm.tag_users.includes(t.value))}
                        placeholder="Please Select User"
                    />
                </div>
               
            </div>

            {
                this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0
                    ? (
                        <AdditionalAttributeForm
                    additionalAttributes={this.state.formData.additional_attributes}
                    formState={this.state.addStoreIndentForm}
                    onAttributeChange={(value,attributeName) => {
                        this.setState(() => ({
                            addStoreIndentForm: {
                            ...this.state.addStoreIndentForm,
                            [attributeName]: value
                        }
                    }))}
                } 
                />
                    )
                    : null
            }
            {this.storeIndentItemJsx()}
            <div className="row align-items-center mb-5">
                <div className="col-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addStoreIndentForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addStoreIndentForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="exampleInputEmail1" className="form-label"><p> &nbsp; </p></label>
                    <table className="table  table-borderless">
                        <tbody>
                            <tr className="total_bg_color"><th>Total Qty</th><td className="text-end">{item_total_qty}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-secondary ms-2" onClick={(e) => { this.addStoreIndentFormSubmitEvent(e, 1); }}>
                    Save as a Draft {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>

                <button type="button" disabled={this.state.saveFormSubmitting} className="btn btn-primary ms-2" onClick={(e) => { this.addStoreIndentFormSubmitEvent(e); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>
        </form>);
    }

    handleRadioChange = (event) => {
        this.setState({ selectedInterchangeItem: event.target.value });
    }

    viewInterchangeableItemJsx = () => {
        let data = this.state.interchangeItemData ? this.state.interchangeItemData : null;
        let max_qty = data && data.pending_qty ? data.pending_qty : 0;
    
        const { interchangeableItemList, addStoreIndentForm, selectedInterchangeItem } = this.state;
    
        // Filter and group data by warehouse_id and item_id
        const filteredItems = interchangeableItemList.filter(item => item.warehouse_id === addStoreIndentForm.warehouse_id);
        
        // Use reduce to aggregate free_qty for each item_id
        const groupedItems = filteredItems.reduce((acc, item) => {
            const key = item.item.id; // Assuming item_id is unique
            if (!acc[key]) {
                acc[key] = {
                    item: item.item, // Assuming item details are same for all rows of same item_id
                    totalFreeQty: 0
                };
            }
            acc[key].totalFreeQty += item.free_qty;
            return acc;
        }, {});
    
        // Prepare rows for rendering
        const rows = Object.keys(groupedItems).map((itemId, index) => {
            const item = groupedItems[itemId].item;
            const totalFreeQty = groupedItems[itemId].totalFreeQty;
            const isChecked = selectedInterchangeItem === item.id;
    
            return (
                <tr key={index} className="text-center">
                    <td>
                        <input
                            type='radio'
                            className="interchange_id_radio"
                            name="interchangebleItem"
                            value={item.id}
                            data-qty={totalFreeQty}
                            checked={isChecked}
                            onChange={this.handleRadioChange}
                        />
                    </td>
                    <td className="text-start">
                        <div>
                            <b>{item.name}</b><br />
                            <small>Code: <b>{item.item_code}</b></small><br />
                            <small>Mfr: <b>{item.manufacturer?.name ?? '-'}</b> (MPN: <b>{item.manufacturer_part_no ?? '-'}</b>)</small>
                        </div>
                    </td>
                    <td>{totalFreeQty}</td>
                </tr>
            );
        });
    
        const noRecordRow = (
            <tr>
                <td className='text-center' colSpan={6}>No Record</td>
            </tr>
        );
    
        return (
            <div className="modal fade" id="interchangebleItemModal" tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="interchangebleItemModal">Interchange Item</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {data && <div className='p-1 bg-light border-bottom'>
                            <table className='table table-sm mb-0 table-borderless text-sm'>
                                <tbody>
                                    <tr>
                                        <td style={{ width: "26%" }}>Item Name</td>
                                        <th>{data.item && data.item.name ? data.item.name : ""}</th>
                                    </tr>
                                    <tr>
                                        <td>Item Code</td>
                                        <th>{data.item && data.item.item_code ? data.item.item_code : "-"}</th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        }
    
                        <div className="modal-body">
                            <form className="bg-white" onSubmit={this.submitSelectedInterchangeItem} id="interchangeItemForm" method="post" encType="multipart/form-data">
                                <table className="table table-hover table-bordered table-responsive bg-white my-3 table-sm">
                                    <thead className="table-secondary">
                                        <tr className="text-center">
                                            <th style={{ "width": '7%' }}></th>
                                            <th className="text-start">Item Details</th>
                                            <th style={{ width: "13%" }} className="text-end">Qty</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.interchangeable_loading
                                            ? (<tr><td colSpan="6"><Loader /></td></tr>)
                                            : (rows.length > 0 ? rows : noRecordRow)
                                        }
                                    </tbody>
                                </table>
                                <div className="modal-footer">
                                    <button type="submit" disabled={this.state.saveInterchangeableItemForm} className="btn btn-primary">Submit {this.state.saveInterchangeableItemForm ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render                                  =   ()  =>  {
        return (<ApplicationLayout>
             <Helmet><title>Item Demand - Add</title></Helmet>
            <div className="page_title row m0">
                <div className="col-12">
                    <h3>{this.state.itemDemandData ? "Edit" : "New"} Item Demand {this.state.itemDemandData ? <span> of Transaction ID : {this.state.itemDemandData?.transaction_id}</span> : null}</h3>
                    <div className="text-end mt15">
                        <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                        </button>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                        {this.state.form_loading ? <Loader/> : this.saveStoreIndentFormJsx()}
                    </div>
                </div>
            </div>
           {this.viewInterchangeableItemJsx()}
            <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />
            <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />
            <ItemDetailsModal itemData={this.state.itemSearchedDetails} closeEvent={this.itemDetailsClose} /> 
            <ItemSearchModal ref={this.itemSearchRef}
                afterSelectingItems={(selectedItems) => {
                    let allRows = this.state.storeIndentItemRows ?  this.state.storeIndentItemRows.filter(i => i.item ? true : false) : [];
                    let items = allRows.concat(selectedItems)
                     this.setState({storeIndentItemRows :  items})
                }}
               
                />
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        permissions             :   state.app.acl_info,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        iam_group_sites         :   state.app && state.app.group_sites ? state.app.group_sites : [],
    };
};

export default connect(mapStateToProps)(ItemDemandAdd);