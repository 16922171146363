import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import { connect } from 'react-redux';
import moment from 'moment';
import DatePicker from "react-datepicker";
import Loader from "../../../components/ui/Loader/Loader";
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import TapSelect from '../../../components/ui/TapSelect';
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2, ISC_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import WarehouseAddress from '../includes/WarehouseAddress';
import InputItemSearch from '../../includes/ui/InputItemSearch';
import AddItemModal from '../../items/itemsAdd/AddItemModal';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import ItemDetailsModal from '../../items/itemDetails/ItemDetailsModal';
import ItemEditModal from '../../items/itemsAdd/ItemEditModal';
import { Helmet } from 'react-helmet';
import ItemSearchModal from '../../items/itemSearchModal';
import axios from 'axios';
import DateService from '../../../services/DateService';
import AdditionalAttributeForm from '../../includes/ui/AdditionalAttributeForm';
import TapInputNumber from '../../../components/ui/TapInputNumber';

class InventoryAdjustmentAdd extends AppBaseComponent {
    
    constructor(props) {
        super(props);
        
        this.initAdjustmentForm        =   {
            transaction_date                :   '',
            warehouse_id                    :   '',
            items                           :   [],
            notes                           :   '',
            adjustment_amount               :   0,
            status                          :  ''
        }
        
        this.initAdjustmentItemRows   =   [{
            item                            :   null,
            remark                          :   '',
            free_qty                        :   0,
            new_qty                         :   0,
            add_qty                         :   0,
            subtract_qty                    :   0,
            current_stocks                  :   [],
            stock_loading                   :   false,
            adjustment_type                 :   'add' ,
            adjustment_item_id              :    '',
            allApiCallsCompleted            :   false,
        }];
        
        this.initalState                =   {
            adjustmentFormDataLoaded       :   false,
            allWarehouses                   :   [],
            addAdjustmentForm               :   {...this.initAdjustmentForm},
            adjustmentItemRows             :   [...this.initAdjustmentItemRows] ,
            warehouseDetailLoading          :   false,
            warehouseDetail                :   null, 
            warehouseSites                 :   [],
            allStatus                      :   [],
            formData                       :   null,
            track_batch_expiry             :   'N' ,
            itemSearchedDetails            :    null,
            itemRowNumber                   :   0  ,
            saveFormSubmitting              :   false,
            buttonDisabled                  :       false, 
            showItemSearchModal             :       false,
            showItemAddModal                :       false,
            showItemEditModal               :       false 
        }
        
        this.state                      =   {
            ...this.initalState
        }
        this.addItemModalRef            =   React.createRef();
        this.editItemModalRef            =   React.createRef();
        this.itemSearchRef              =         React.createRef();

        this.invAdjustmentUrl       =   INVENTORY_API_BASE_URL_2 + '/inventory_adjustment';
        this.warehouseUrl           =   ISC_API_BASE_URL_2  + '/warehouse';
        this.vendorUrl              =   INVENTORY_API_BASE_URL_2 + '/vendor';
        this.itemUrl                =   INVENTORY_API_BASE_URL_2 + '/item';
        this.itemCurrentStockUrl    =   INVENTORY_API_BASE_URL_2 + '/item_current_stock';

    }
    
    componentDidMount() {
        setTimeout(() => {
            this.initilaizeFormData(this.props);  
         }, 1000); 
        this.viewItemModal                           =   new Modal(document.getElementById('viewItemModal'), {keyboard: false, backdrop :false});
    }

    // componentWillReceiveProps(nextProps){
    //     if(this.props.location && this.props.location.state){
    //         if(nextProps.location.state !== this.props.location.state) {
    //             this.initilaizeFormData(nextProps)
    //          }  
    //     }        
    // }
    
    initilaizeFormData                  =   (pr)  =>  {
        let sites                       =   this.props.iam_group_sites;
         if(!this.state.adjustmentFormDataLoaded) {
            HttpAPICall.withAthorization('GET', this.invAdjustmentUrl + '/add_form_data', this.props.access_token, null, null, (response) => {
                 let formData = response.data
                this.setState({
                    adjustmentFormDataLoaded   :   formData ? true : false,
                     allWarehouses               :   this.props.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}),
                    formData                     :      formData ,
                     allStatus                   :   formData && formData.status && formData.status.length > 0 ?  formData.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                    track_batch_expiry          :   pr.isc_configuration && pr.isc_configuration.track_batch_expiry ? pr.isc_configuration.track_batch_expiry : 'N',
                }, () => {
                    if(pr.location && pr.location.state && pr.location.state.adjustmentViewData) {
                        this.setState({buttonDisabled: true})
                        let adjustmentDetail       =   pr.location.state.adjustmentViewData;
                        let adjustmentItems        =   adjustmentDetail.items;
                        let employeeProfile        =   adjustmentDetail.employee;
                        let updateFormState        =    [];
                        if (adjustmentDetail.additional_attributes_data) {
                            adjustmentDetail.additional_attributes_data.map((i, k) => {
                                let name = 'additional_attributes_' + i.key;
                                updateFormState[name] = i.value
                            });

                        }
                        
                        setTimeout(() => {
                            this.setState({
                                adjustmentDetail       :   pr.location && pr.location.state && pr.location.state.clone ? null : adjustmentDetail,
                                addAdjustmentForm         :   {
                                    ...this.initAdjustmentForm,
                                     transaction_id          :   pr.location && pr.location.state && pr.location.state.clone ?  '' : adjustmentDetail?.transaction_id, 
                                     transaction_date        :   pr.location && pr.location.state && pr.location.state.clone ?  moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD') : adjustmentDetail.transaction_date,
                                     warehouse_id            :   adjustmentDetail.warehouse_id,
                                    notes                   :   adjustmentDetail.notes,
                                     status                  :   adjustmentDetail.status_data.id,
                                     ...updateFormState,
                                },
                                
                            }, () => {
                                this.getWarehouseDetail(adjustmentDetail.warehouse_id, true);
                               
                               
                                adjustmentItems.forEach((consumedItem, key) => {
                                   let adjustment_type = '';
                                  if(consumedItem.qty.includes('-')){
                                    adjustment_type =   'subtract'
                                  }else{
                                    adjustment_type =   'add'
                                  }
                                let pre_selected_stock = {batch_number : consumedItem.batch_number, expiry_date : consumedItem.expiry_date , qty : consumedItem.qty, adjustment_type :adjustment_type,adjustment_item_id : consumedItem.id};
                                    this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem.remark ? true : false}}, key ,1, consumedItem.rate, pre_selected_stock,false,adjustment_type);
                                });
                            });
                            this.addNewItemRow()
                        },500)
                       
                    }else if(pr.location && pr.location.state && pr.location.state.addFromClosingStockData){
                        let adjustmentDetail       =   pr.location.state.addFromClosingStockData;
                        let adjustmentItems        =   adjustmentDetail.items;
                        this.setState({buttonDisabled: true})
                        setTimeout(() => {
                            this.setState({
                                adjustmentDetail       :   null,
                                addAdjustmentForm         :   {
                                    ...this.initAdjustmentForm,
                                     transaction_id          :    '', 
                                     transaction_date        :   moment(new Date(),'YYYY-MM-DD').format('YYYY-MM-DD'),
                                     warehouse_id            :   adjustmentDetail.warehouse_id,
                                    
                                },
                                
                            }, () => {
                                this.getWarehouseDetail(adjustmentDetail.warehouse_id, true);
                               
                               
                                adjustmentItems.forEach((consumedItem, key) => {
                                   let adjustment_type = 'subtract';
                                  
                                    let stock = consumedItem && consumedItem.stock ? consumedItem.stock : null;
                                    let pre_selected_stock = {batch_number : stock && stock.batch_number ? stock.batch_number : null, expiry_date : stock && stock.expiry_date ? DateService.dateTimeFormat(stock.expiry_date,'YYYY-MM-DD') : null, qty : null, adjustment_type :adjustment_type,adjustment_item_id : "",adjustment_from:'closing_stock'};
                                    this.onItemSelect({...consumedItem.stock , itemdata : {...consumedItem.item,isAddRemark : false}}, key ,1, stock.rate, pre_selected_stock,false,adjustment_type);
                                });
                            });
                            this.addNewItemRow()
                        },1500)
                    }
                   
                      
                });
            });
        }
    }
    
    onWarehouseChange                   =   (selectedOption) =>  {
        this.setState({
            adjustmentItemRows         :   [...this.initAdjustmentItemRows]
        }, () => {
            this.getWarehouseDetail(selectedOption ? selectedOption.value : null);
        });
    }
    
    getWarehouseDetail                  =   (warehouse_id)  =>  {
        if (warehouse_id) {
            this.setState({warehouseDetailLoading : true, warehouseDetail : null, warehouseSites : []});
            HttpAPICall.withAthorization('GET', this.warehouseUrl + '/' + warehouse_id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                this.setState({
                    warehouseDetail         :   respData.data,
                    warehouseSites          :   respData.data.sites
                });
               
            }).then(() => {
                this.setState({warehouseDetailLoading : false});
            });
        } else {
            this.setState({warehouseDetail: null})
        }
    }
 
    itemViewModalInit                       =   (data,k)  =>  {
        this.setState({ itemSearchedDetails        :    data,itemRowNumber : k})
    }

    addRemark       =   (data,k)      =>      {
        let allRows                 =   this.state.adjustmentItemRows;
        let itemdata = {...data,isAddRemark : true}
        allRows[k]                  =   {...this.state.adjustmentItemRows[k] , item  : itemdata};
        this.setState({adjustmentItemRows : allRows});
    }

     //********************EDIT ITEM DETAILS ******************************* */

     itemSearchModalInit             =       ()          =>      {
        this.setState(prevState => ({ showItemSearchModal: !prevState.showItemSearchModal }), () => {
            if (this.state.showItemSearchModal) {
                let allRows                 =   this.state.adjustmentItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if (this.itemSearchRef.current) {
                    this.itemSearchRef.current.searchItemModalInit(item_ids);
                }
            }
        });
        
    }

    editItemModalInit          =       (data)           =>      {
        this.setState(prevState => ({ showItemEditModal: !prevState.showItemEditModal }), () => {
            if (this.state.showItemEditModal) {
                let allRows                 =   this.state.adjustmentItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }
                if(this.editItemModalRef.current){
                    this.editItemModalRef.current.itemEditModalInit(data); 
                }
                
            }
        });
        
    }

    addItemModalInit          =       ()           =>      {
        this.setState(prevState => ({ showItemAddModal: !prevState.showItemAddModal }), () => {
            if (this.state.showItemAddModal) {
                let allRows                 =   this.state.adjustmentItemRows;
                let item_ids                =   [];
                if(allRows && allRows.length > 0){
                    item_ids = allRows.filter(i => i.item ? true : false).map(item => {return(item?.item?.id)} )
                }

                if(this.addItemModalRef.current){
                    this.addItemModalRef.current.itemAddModalInit(); 
                }
            
            }
        });
        
    }
    
    submitEditItem          =       ()      =>      {
        HttpAPICall.withAthorization('GET', this.itemUrl + '/' + this.state.itemSearchedDetails.id, this.props.access_token, null, null, (response) => {
           let selectedItem = {
                label               :   (<Ax>
                                            <b>Item : </b> {response.data.data.name} <small> ({response.data.data.item_code})</small><br/>
                                            <small>
                                                <b>Manufacturer : </b> {response.data.data.manufacturer ? response.data.data.manufacturer.name : ''}<br/>
                                                <b>MPN : </b> {response.data.data.manufacturer_part_no}</small></Ax>),
                value               :   response.data.data.id ,
                display_label       :   `${response.data.data.name} (${response.data.data.item_code})`,
                itemdata            :   response.data.data
            };
            this.setState({
                  itemSearchedDetails        :    response.data.data
               },() => {
                   this.onItemSelect(selectedItem,this.state.itemRowNumber,1,response?.data?.data?.purchase_price,false)
               });
        })
       
    }

    closeEvent              =           ()      =>      {
        // if(this.state.adjustmentDetail){
        //     this.props.history.push({pathname: `/consumption_list`, state: {consumptionData : this.state?.adjustmentDetail }})
        // }else{
            this.props.history.push({pathname: `/inventory_adjustment_list`})
        //}
    }

    addItemsfromSearch          =       (selectedItems)     =>  {
        let adjustmentItemRows = selectedItems.map((pu_item, k) => {
            return {
                item                        :          {...pu_item.item,isAddRemark : pu_item.remark ? true : false},
                id                          :           pu_item.item.id,
                rate                        :           pu_item.rate,
                remark                      :           '',
                expiry_date                 :           '',
                batch_number                :           '',
                adjustment_type             :           'add',
                add_qty                     :           0,
                new_qty                     :           0,
                subtract_qty                :           0
            };
        });
        let allRows = this.state.adjustmentItemRows ?  this.state.adjustmentItemRows.filter(i => i.item ? true : false) : [];
        let adjustmentItems = allRows.concat(adjustmentItemRows);
        adjustmentItems.forEach((consumedItem, key) => {
            this.onItemSelect({...consumedItem , itemdata : {...consumedItem.item,isAddRemark : consumedItem?.remark ? true : false}}, key,consumedItem.rate,null,false );
        });
        
    
    }


    onItemSelect                        =   (selectedOption, key, qty = 1,purchase_price=null, pre_selected_stock =  null,addRow = true,adjustment_type='add')  =>  {
       
        if(selectedOption) {
                if(!this.state.addAdjustmentForm.warehouse_id){
                    toast.error('Please select  Warehouse First', { position: toast.POSITION.TOP_RIGHT })
                }else{
                    let adjustmentItemRows         =   this.state.adjustmentItemRows;
                
                    let itemdata                    =   selectedOption.itemdata;
                    if(!purchase_price) {
                        purchase_price           =   itemdata && itemdata.purchase_price ? itemdata.purchase_price : 0;
                    }
                   
                    let row                         =   {
                            ...adjustmentItemRows[key], 
                            item    :       itemdata , 
                            qty     :       Number(qty) ,
                            rate    :       purchase_price,
                            remark  :       selectedOption.remark ? selectedOption.remark : "",
                            adjustment_type :  adjustment_type,
                            batch_number    :   adjustment_type == 'add' ? selectedOption.batch_number : '',
                            expiry_date     :   adjustment_type == 'add' ? selectedOption.expiry_date : '',
                            adjustment_item_id : pre_selected_stock && pre_selected_stock.adjustment_item_id ? pre_selected_stock.adjustment_item_id : ''
                            // free_qty        :   adjustment_type == 'add' ? Number(selectedOption.qty) : '',
                            // add_qty         :   adjustment_type == 'add' ? Number(selectedOption.qty) : 0,
                            // new_qty         :   adjustment_type == 'add' ? Number(selectedOption.qty) : 0,
                            // subtract_qty :   adjustment_type == 'add' ? 0 : '',
                    };

                    adjustmentItemRows[key]            =   row;
                    this.setState({adjustmentItemRows : [...adjustmentItemRows]}, () => {
                        
                        if(!this.state.adjustmentItemRows.find(ir => !ir.item)) {
                            if(addRow == true){
                                setTimeout(() => {this.addNewItemRow()}, 1000);
                            }
                        }
                        if(itemdata && itemdata.category && itemdata.category !== 'services') {
                            //load Inventory Level of this item at given warehouse for this key :-
                            this.getItemInventoryLevel(itemdata.id, key, pre_selected_stock);
                        }
                        
                    });
            }
        }
        
       
       
    }
    
    getItemInventoryLevel               =   (item_id, key, pre_selected_stock = null)  =>  {
        this.setState({ buttonDisabled: true });
        let adjustmentItemRows         =   this.state.adjustmentItemRows;
        adjustmentItemRows[key]        =   {...adjustmentItemRows[key] , stock_loading : true,selected_stock:null,current_stocks:[]};
        this.setState({adjustmentItemRows : [...adjustmentItemRows]});
       
        const headers                         =   { 'Accept': 'application/json','Content-Type':'application/json','Authorization':'Bearer ' + this.props.access_token};
        Promise.all([
            axios({ method: 'GET', headers: headers, url: this.itemCurrentStockUrl + '/' + item_id,params :  {interchangeable_items_current_stock: "N", warehouse_id: this.state.addAdjustmentForm.warehouse_id}}), 
        ])
        .then((results) => {
            
            let current_stock_list          =           results[0].data;
            
                let current_stocks              =   current_stock_list.current_stock.map(cs => {
                                                        return {
                                                            value : cs.qty,
                                                            label : (<Ax><b>Batch Number : </b> {cs.batch_number} <br />
                                                                <b>Expiry Date : </b> {cs.expiry_date_display}<br />
                                                                <b>Free Qty : </b> {cs.free_qty}</Ax>),
                                                            stock : cs,
                                                            display_label: `${cs.batch_number} & ${cs.expiry_date_display} `
                                                        }
                                                    });
                let adjustmentItemRows         =   this.state.adjustmentItemRows;
                adjustmentItemRows[key]        =   {
                        ...adjustmentItemRows[key],
                        stock_loading           :       false,
                        current_stocks          :      current_stocks,
                        allApiCallsCompleted: true
                };
                
                let selectedStock               =  null;
               
                if(adjustmentItemRows && adjustmentItemRows[key] && adjustmentItemRows[key].adjustment_type == 'add'){
                   if(current_stocks && current_stocks.length > 0){
                        selectedStock            =   current_stocks.find(cs => {
                            return cs.stock.batch_number == null && cs.stock.expiry_date == null;
                        });
                   }else if(current_stocks && current_stocks.length == 0 && pre_selected_stock){
                   
                        selectedStock           =  {
                            stock : {
                                // consumption_item_id                :   pre_selected_stock.consumption_item_id,
                                batch_number            :   pre_selected_stock.batch_number,
                                expiry_date             :   pre_selected_stock.expiry_date,
                                adjustment_type         :  pre_selected_stock.adjustment_type,
                                new_qty                 :  Math.abs(pre_selected_stock.qty),
                                add_qty                 :  pre_selected_stock.adjustment_type == 'add' ? Number(pre_selected_stock.qty) : 0,
                                subtract_qty            :  pre_selected_stock.adjustment_type == 'subtract' ? Math.abs(pre_selected_stock.qty) : 0
                            }
                        };
                   }
                  
                }else{
                    
                  selectedStock =   current_stocks && current_stocks.length > 0 ? current_stocks[0] : null;
                }
                
               
                if(pre_selected_stock) {
                    let preSelectedStock            =   current_stocks.find(cs => {
                                                            return cs.stock.batch_number == pre_selected_stock.batch_number 
                                                                        && cs.stock.expiry_date == pre_selected_stock.expiry_date;
                                                      });  

                    if(preSelectedStock == undefined || preSelectedStock == null) {
                        selectedStock           =  {...selectedStock,stock:{
                            batch_number            :   pre_selected_stock.batch_number,
                            expiry_date             :   pre_selected_stock.expiry_date,
                            adjustment_type         :  pre_selected_stock.adjustment_type,
                            new_qty                 :  Math.abs(pre_selected_stock.qty),
                            free_qty                :    Math.abs(pre_selected_stock.qty),
                            add_qty                 :  pre_selected_stock.adjustment_type == 'add' ? Number(pre_selected_stock.qty) : 0,
                            subtract_qty            :  pre_selected_stock.adjustment_type == 'subtract' ? Math.abs(pre_selected_stock.qty) : 0
                        },
                            new_stock               :       {
                                batch_number            :   pre_selected_stock.batch_number,
                                expiry_date             :   pre_selected_stock.expiry_date,
                                adjustment_type         :  pre_selected_stock.adjustment_type,
                                new_qty                 :  Math.abs(pre_selected_stock.qty),
                                free_qty                :    Math.abs(pre_selected_stock.qty),
                                add_qty                 :  pre_selected_stock.adjustment_type == 'add' ? Number(pre_selected_stock.qty) : 0,
                                subtract_qty            :  pre_selected_stock.adjustment_type == 'subtract' ? Math.abs(pre_selected_stock.qty) : 0
                            }  
                        };
                    } else {
                      
                        let cs = preSelectedStock.stock;
                        let qty             =   cs.qty;
                        let free_qty        =   pre_selected_stock && pre_selected_stock.qty  ? cs.free_qty - Number(pre_selected_stock.qty)  :  cs.free_qty;
                       
                       selectedStock           =   {...preSelectedStock , stock : {
                                    ...cs,
                                    qty         :   Number(qty),
                                    free_qty    :   Number(free_qty),
                                    add_qty     :   pre_selected_stock && pre_selected_stock.adjustment_type && pre_selected_stock.adjustment_type == 'add' ? Number(pre_selected_stock.qty) : 0,
                                     subtract_qty   : pre_selected_stock && pre_selected_stock.adjustment_type && pre_selected_stock.adjustment_type == 'subtract' ? Math.abs(pre_selected_stock.qty) : 0,
                                    new_qty         :   Number(free_qty) + Number(pre_selected_stock.qty)   
                            }};
                    }
                }

                this.setState({adjustmentItemRows : [...adjustmentItemRows]} , () => {
                    this.onBatchChange(selectedStock, key);
                    this.handleButtonEnable()
                });
            
        })
     
    }

    handleButtonEnable = () => {
        const allApiCallsCompleted = this.state.adjustmentItemRows.filter(i => i.item ? true : false).every(item => item.allApiCallsCompleted);
        this.setState({ buttonDisabled: !allApiCallsCompleted });
    };
    
    onBatchChange                       =   (selectedOption, key)  =>  {
       
        if(selectedOption && selectedOption.stock) {
            let selected_stock              =   selectedOption.stock;
            let new_selected_stock         =   selectedOption && selectedOption.new_stock ? selectedOption.new_stock : null;
            let adjustmentItemRows         =   this.state.adjustmentItemRows;
           
            let obj = {}
            if(this.state.adjustmentDetail){
                obj['add_qty']  =       selected_stock.add_qty;
                obj['subtract_qty'] =   Math.abs(selected_stock.subtract_qty);
                obj['new_qty']      =   selected_stock.new_qty;
            }

            if(new_selected_stock){
                obj['add_qty']  =       new_selected_stock.add_qty;
                obj['subtract_qty'] =   Math.abs(new_selected_stock.subtract_qty);
                obj['new_qty']      =   new_selected_stock.new_qty;
                obj['batch_number'] =   new_selected_stock.batch_number;
                obj['expiry_date']  =   new_selected_stock.expiry_date;

            }
           
            adjustmentItemRows[key]        =   {
                ...adjustmentItemRows[key], 
                selected_stock  :   new_selected_stock ? null : selected_stock,
                free_qty        :   Number(selected_stock.free_qty),
                 ...obj
               
            };
            this.setState({adjustmentItemRows : [...adjustmentItemRows]});
        }
    }

    addNewItemRow                       =   ()  =>  {
        this.setState({adjustmentItemRows : [...this.state.adjustmentItemRows, ...this.initAdjustmentItemRows ] });
    }

    removeItemRow                       =   (k) =>  {
        let adjustmentItemRows             =   this.state.adjustmentItemRows;
        if(adjustmentItemRows.length > 1) {
            adjustmentItemRows.splice(k, 1);
            this.setState({adjustmentItemRows : adjustmentItemRows});
        }
       
    }

    adjustQtyFields(itemKey, fieldName, newValue) {
        const adjustmentItemRows = [...this.state.adjustmentItemRows];
        const itemRow = adjustmentItemRows[itemKey];
      
        if (fieldName === 'new_qty') {
          const newQty = parseFloat(Number(newValue));
          const freeQty = itemRow.selected_stock ? itemRow.free_qty : 0;
          
          if (newQty >= freeQty) {
            itemRow.add_qty = newQty - freeQty;
            itemRow.subtract_qty = 0;
          } else {
            itemRow.add_qty = 0;
            itemRow.subtract_qty = freeQty - newQty;
          }
          
          itemRow.new_qty = newQty;
        } else if (fieldName === 'add_qty') {
          const addQty = parseFloat(Number(newValue));
          itemRow.add_qty = addQty;
          itemRow.subtract_qty = 0;
          itemRow.new_qty = addQty + (itemRow.selected_stock ? itemRow.free_qty : 0);
        } else if (fieldName === 'subtract_qty') {
          const subtractQty = parseFloat(Number(newValue));
          itemRow.subtract_qty = subtractQty;
          itemRow.add_qty = 0;
          itemRow.new_qty = (itemRow.selected_stock ? itemRow.free_qty : 0) - subtractQty;
        }
      
        adjustmentItemRows[itemKey] = itemRow;
       
    }

    handleRadioChange = (itemKey, adjustmentType,item_id) => {
        const adjustmentItemRows = [...this.state.adjustmentItemRows];
        const itemRow = adjustmentItemRows[itemKey];
        if (adjustmentType == 'add') {
            itemRow.selected_stock = null;
            itemRow.current_stocks = [];
            itemRow.batch_number  =   '';
            itemRow.expiry_date   =   '';
        }
        itemRow.qty   =   0;
        itemRow.free_qty  =   0;
        itemRow.add_qty   =   0;
        itemRow.subtract_qty  =   0;
        itemRow.new_qty       =   '';
        
        itemRow.adjustment_type = adjustmentType;
        this.setState({ adjustmentItemRows : adjustmentItemRows },() => {
        // if(adjustmentType == 'subtract'){
            this.getItemInventoryLevel(item_id, itemKey)
        //}
        });
    };
      
    adjustmentFormSubmitHandler       =       (e,status)     =>      {
        let showErrors                  =   [];
        let formData                        =   {...this.state.addAdjustmentForm, status : status};
       
        let items                           =   this.state.adjustmentItemRows.filter(ir => ir.item).map(i => {
                                                  
                                                   let batch_number = '',expiry_date='';
                                                    if(i.selected_stock){
                                                        batch_number =  i.selected_stock.batch_number;
                                                        expiry_date  =   i.selected_stock.expiry_date;
                                                    }else if(i.new_stock){
                                                      
                                                        batch_number =  i.new_stock.batch_number;
                                                        expiry_date  =   i.new_stock.expiry_date;
                                                        //}
                                                    }else {
                                                        batch_number = i.batch_number;
                                                        expiry_date=i.expiry_date;
                                                    }
                                                    
                                                    return {
                                                        id                  :   i.item.id,
                                                        batch_number        :   batch_number,
                                                        expiry_date         :   expiry_date,
                                                        qty                 :   i.adjustment_type == 'add' ? `+${i.add_qty}`  : `-${i.subtract_qty}`,
                                                        rate                :   i.rate,
                                                        remark              :   i.remark,
                                                        new_qty             :   i.new_qty,
                                                        adjustment_item_id  :   i.adjustment_item_id ? i.adjustment_item_id : ''
                                                    };
                                                });
                
        let additional_attributes = {};
        this.state.formData.additional_attributes.forEach((i, k) => {
            let name = 'additional_attributes_' + i.key;
            additional_attributes[i.key] = this.state.addAdjustmentForm[name] ? this.state.addAdjustmentForm[name] : '';
        });

        let allItemLists                    =            [];
        let dublicateItemExists             =            false;                                                  
        if(items.length > 0){
            items.forEach(item => {

                let combinationKey          =           item.id  + item.batch_number + item.expiry_date + item.adjustment_type;
                if (allItemLists.includes(combinationKey)) {
                    dublicateItemExists = true;
                }
                return allItemLists.push(combinationKey); 
            })
        }   
                                          
        if(formData.transaction_date == '') {
            showErrors                      =   [...showErrors , 'Please enter Transaction Date.'];
        }
        
        if(formData.warehouse_id == '') {
            showErrors                      =   [...showErrors , 'Please select Warehouse.'];
        }
       

        if(formData.status == '') {
            showErrors                      =   [...showErrors , 'Please select Status'];
        }
        
        if(items.length == 0) {
            showErrors                      =   [...showErrors , 'Please select Item and qty.'];
        }else if(dublicateItemExists == true) {
            showErrors                      =   [...showErrors , 'Inventory Adjustment should not contain duplicate Item'];
        }
      
        
        if(showErrors.length > 0) {
            toast.error(showErrors[0], {position: toast.POSITION.TOP_RIGHT});
        } else {
            formData                        =   {
                ...formData , 
                items                          :   items,
                additional_attributes: additional_attributes
            };
          
            if(this.state.adjustmentDetail) {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('PUT', this.invAdjustmentUrl + '/edit', this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/inventory_adjustment_list`, state: {adjustmentId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            } else {
                this.setState({ saveFormSubmitting: true });
                HttpAPICall.withAthorization('POST', this.invAdjustmentUrl + '/add', this.props.access_token, {}, formData, (response) => {
                    toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                    this.props.history.push({pathname: `/inventory_adjustment_list`, state: {adjustmentId : response.data.transaction_id }})
                }).then(() => this.setState({ saveFormSubmitting: false }));
            }
        }
    }

    pageTitlejsx                        =   ()  =>  {
        return (<div className="page_title row m0">
            <div className="col-12">
                <h3>{this.state.adjustmentDetail ? "Edit" : "Add"} Inventory Adjustment {this.state.adjustmentDetail ? <span> of Transaction ID : {this.state.adjustmentDetail.transaction_id}</span> : null}</h3>
                <div className="text-end mt15">
                    <button onClick={this.closeEvent} disabled={this.state.saveFormSubmitting ? true : false} type="button" className="btn btn-secondary">
                        <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="Close Requisition" />
                    </button>
                </div>
            </div>
        </div>);
    }
  
    saveAdjustmentFormJsx              =   ()  =>  {
        let total_add       =   0;
        let total_subtact   =   0;

        this.state.adjustmentItemRows.forEach((item,key) => {
            if(item && item.item && item.adjustment_type == 'add') {
                    total_add     +=  item.add_qty;
            }
            if(item && item.item && item.adjustment_type == 'subtract') {
                total_subtact     +=  item.subtract_qty;
             }
        });

        return (<form className="bg-white p-3" onSubmit={this.adjustmentFormSubmitHandler}>

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="site_id" className="form-label require">Date of Transaction</label>
                </div>
                <div className="col-sm-4 add_calender_section">
                    <DatePicker
                        selected={
                            this.state.addAdjustmentForm.transaction_date
                                ? moment(this.state.addAdjustmentForm.transaction_date, 'YYYY-MM-DD').toDate()
                                : false
                        }
                        name="transaction_date"
                        onChange={(value, event) => this.formDateHandler('transaction_date', value, 'addAdjustmentForm')}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                        dateFormat="dd-MMM-yyyy"
                        className={"form-control"}
                        showMonthDropdown
                        showYearDropdown
                        autoComplete="off"
                        scrollMonthYearDropdown
                        placeholderText={`Please Enter Date of Transaction`}
                    />
                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="requisition_date" className="add_calender-icon" />
                </div>
            </div>
            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Warehouse</label>
                </div>
                <div className="col-sm-7">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addAdjustmentForm', 'warehouse_id', () => {
                                this.onWarehouseChange(selectedOption);
                            });
                        }}
                        options={this.state.allWarehouses}
                        isSearchable={true}
                        required={true}
                        value={this.state.allWarehouses.find(m => m.value == this.state.addAdjustmentForm.warehouse_id)}
                        placeholder="Please Select Warehouse"
                        isDisabled={this.state.adjustmentDetail}
                    />
                </div>
            </div>
            {this.state.warehouseDetail
                ? <div className="row align-items-center mt1">
                    <div className="offset-3 col-sm-8"><WarehouseAddress warehouseDetail={this.state.warehouseDetail} /></div>
                </div>
                : (this.state.warehouseDetailLoading ? <Loader /> : null)
            }

            <div className="row align-items-center my-3">
                <div className="col-sm-3">
                    <label htmlFor="warehouse_id" className="form-label require">Status</label>
                </div>
                <div className="col-sm-4">
                    <TapSelect
                        changeEvent={(selectedOption) => {
                            this.tapSelectChange(selectedOption, 'addAdjustmentForm', 'status');
                        }}
                        options={this.state.allStatus}
                        isSearchable={true}
                        isClearable={true}
                        required={true}
                        value={this.state.allStatus.find(s => s.value == this.state.addAdjustmentForm.status)}
                        placeholder="Select Status"
                        isDisabled={this.state.adjustmentDetail}
                    />
                </div>
            </div>
            {this.state.formData && this.state.formData.additional_attributes && this.state.formData.additional_attributes.length > 0 && <AdditionalAttributeForm
                    additionalAttributes={this.state.formData.additional_attributes}
                    formState={this.state.addAdjustmentForm}
                    onAttributeChange={(value,attributeName) => {
                        this.setState(() => ({
                            addAdjustmentForm: {
                            ...this.state.addAdjustmentForm,
                            [attributeName]: value
                        }
                    }))}
                } 
                />}
             {this.adjustmentItemsJsx()}
             <div className="row align-items-center my-3">
                <div className="col-sm-6">
                    <label htmlFor="notes" className="form-label">Notes</label>
                    <textarea
                        name="notes"
                        value={this.state.addAdjustmentForm.notes}
                        onChange={(e) => this.formInputHandler(e, 'addAdjustmentForm')}
                        className="form-control"
                        style={{ height: "100px" }}
                    />
                </div>
                <div className="col-sm-6">
                    <table className="table table-borderless">
                        <tbody>
                            <tr className="subtotal_bg_color"><th>Total Qty Added</th><td className="text-end">{parseFloat(total_add).toFixed(2)}</td></tr>
                            <tr className="subtotal_bg_color"><th>Total Qty Subtracted</th><td className="text-end">{parseFloat(total_subtact).toFixed(2)}</td></tr>
                        </tbody>
                    </table>
                </div>
            </div>

           {!this.state.buttonDisabled && <div className="col-12 text-end fixed_footer">
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} onClick={this.closeEvent} className="btn btn-secondary ms-2">Cancel</button>
               
                <button type="button" disabled={this.state.saveFormSubmitting || this.state.buttonDisabled} className="btn btn-primary ms-2" onClick={(e) => { this.adjustmentFormSubmitHandler(e, this.state.addAdjustmentForm.status); }}>
                    Save {this.state.saveFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                </button>
            </div>}
        </form>);
    }

    adjustmentItemsJsx                 =   ()  =>  {
        return (<div className="my-3 row">
            <div className="col-sm-12">
                <table className="table table-bordered table-responsive bg-white ">
                    <thead className="table-secondary align-middle">
                        <tr className="">
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col">Item Details</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Unit Rate</th>
                            <th scope="col" className="text-center" style={{ width: "12%" }}>Adjustment Type</th>
                            {this.state.track_batch_expiry == "Y" ?
                                <th scope="col" className="text-center" style={{ width: "14%" }}>Batch Number <br /> Expiry Date</th>
                                : null}
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Current Stock</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Add Qty</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>Subtract Qty</th>
                            <th scope="col" className="text-center" style={{ width: "8%" }}>New Qty</th>
                            <th scope="col" className="" style={{ width: "6%" }}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.adjustmentItemRows.map((itemRow, itemKey) => {
                            let item = itemRow && itemRow.item ? itemRow.item : null;
                           
                            return <tr className="" key={itemKey}>
                                <td className="text-center align-middle">{itemKey + 1}</td>
                                {(item
                                    ? <Ax>
                                        <td>
                                            <div className="row">
                                                <div className='col-sm-10'><span className="fw-bold link-primary cursor_pointer" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>{item.name}</span></div>

                                                <div className='col-sm-2 text-end'>
                                                    <tapIcon.imageIcon icon={tapIcon.ListActionIcon} alt="action-icon" className="img-fluid pr10 pl10" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" />
                                                    <ul className="dropdown-menu">
                                                        <li><a href="#" className="dropdown-item" onClick={() => { this.itemViewModalInit(item); this.viewItemModalInit() }}>View Details</a></li>
                                                        {this.props?.permissions?.permissions?.includes("isc-item-edit") && <li><a href="#" className="dropdown-item" onClick={() => {this.setState({showItemEditModal : false},() => {this.itemViewModalInit(item, itemKey); this.editItemModalInit(item)})  }}>Edit Item</a></li>}

                                                        <li className="dropdown-item" onClick={() => { this.addRemark(itemRow.item, itemKey) }}>Add Remark</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="form-text"><b>Code: </b>{item.item_code}</div>
                                            <div className="form-text">
                                                <b>Mfr: </b>{item.manufacturer ? item.manufacturer.name : '-'} (<b>MPN:</b> {item.manufacturer_part_no ? item.manufacturer_part_no : '-'})
                                            </div>
                                            {itemRow.item && itemRow.item.isAddRemark && itemRow.item.isAddRemark == true
                                                ?
                                                <input
                                                    name="remark"
                                                    type="text"
                                                    value={itemRow.remark}
                                                    onChange={(e) => this.dynamicInputHandlerByKey(e, itemKey, 'adjustmentItemRows')}
                                                    className="form-control mt10"
                                                    autoComplete="off"
                                                    placeholder="Item Remarks"
                                                />
                                                : null}

                                        </td>
                                        <td>
                                            <TapInputNumber
                                                name="rate"
                                                value={itemRow.rate}
                                                onChange={(e) => this.dynamicInputHandlerByKey(null, itemKey, 'adjustmentItemRows',null,'rate',e)}
                                                placeholder="Unit Rate"
                                                required={true}
                                                min={0}
                                            />
                                        </td>
                                        <td>
                                            <div key={itemKey} className="form-check form-check-inline">
                                                <input
                                                    name={`adjustment_type_${itemKey}`} // Use a unique name for each group
                                                    type="radio"
                                                    value="add"
                                                    onChange={() => this.handleRadioChange(itemKey, 'add', item.id)}
                                                    checked={itemRow.adjustment_type == "add"}
                                                    className="form-check-input"
                                                    id={`adjustmentForm${item.id}Add${itemKey}`}
                                                />
                                                <label className="form-check-label" htmlFor={`adjustmentForm${item.id}Add${itemKey}`}>Add</label>
                                            </div>
                                            <div className="form-check form-check-inline">
                                                <input
                                                    name={`adjustment_type_${itemKey}`} // Use the same unique name for both radio buttons in the group
                                                    type="radio"
                                                    value="subtract"
                                                    onChange={() => this.handleRadioChange(itemKey, 'subtract', item.id)}
                                                    checked={itemRow.adjustment_type == "subtract"}
                                                    className="form-check-input"
                                                    id={`adjustmentForm${item.id}Subtract${itemKey}`}
                                                />
                                                <label className="form-check-label" htmlFor={`adjustmentForm${item.id}Subtract${itemKey}`}>Subtract</label>
                                            </div>



                                        </td>
                                        {
                                            itemRow.stock_loading && item.category == "goods"
                                                ? <td colSpan={this.state.track_batch_expiry == 'Y' ? 7 : 6}><Loader /></td>
                                                : (<Ax>

                                                    {this.state.track_batch_expiry == "Y"
                                                        ? <td>
                                                            {item.category == "goods" ? (<Ax>
                                                                {itemRow.adjustment_type == 'add' ? <div>
                                                                    <input
                                                                        className="form-control"
                                                                        placeholder="Batch Number"
                                                                        name="batch_number"
                                                                        type="text"
                                                                        value={this.state.adjustmentItemRows[itemKey].batch_number}
                                                                        onChange={(e) => this.dynamicInputHandlerByKeyNameVal(e.target.value, 'batch_number', itemKey, 'adjustmentItemRows')}
                                                                        autoComplete="off"
                                                                        disabled={item.category == "services" ? true : false}
                                                                    />
                                                                    <br />
                                                                    <DatePicker
                                                                        selected={
                                                                            this.state.adjustmentItemRows[itemKey].expiry_date
                                                                                ? moment(this.state.adjustmentItemRows[itemKey].expiry_date, 'YYYY-MM-DD').toDate()
                                                                                : false
                                                                        }
                                                                        name="expiry_date"
                                                                        onChange={(value, e) =>
                                                                            this.dynamicFormDateHandler(value, 'expiry_date', itemKey, 'adjustmentItemRows')
                                                                        }
                                                                        dateFormat="dd-MMM-yyyy"
                                                                        className={"form-control"}
                                                                        showMonthDropdown
                                                                        showYearDropdown
                                                                        autoComplete="off"
                                                                        scrollMonthYearDropdown
                                                                        disabled={item.category == "services" ? true : false}
                                                                        placeholderText={`Enter Expiry Date`}
                                                                    />
                                                                </div> :
                                                                    <Ax>
                                                                        {itemRow && itemRow.current_stocks && itemRow.current_stocks.length > 1 ?
                                                                            <TapSelect
                                                                                changeEvent={(selectedOption) => { this.onBatchChange(selectedOption, itemKey); }}
                                                                                options={itemRow.current_stocks}
                                                                                isSearchable={false}
                                                                                placeholder="Change"
                                                                                isDisabled={itemRow && itemRow.consumption_item_id ? true : false}
                                                                            />
                                                                            : null}
                                                                        <div className='py-1'>
                                                                            <b >Batch Number : </b> {itemRow.selected_stock ? itemRow.selected_stock.batch_number : "-"} <br />
                                                                            <b >Expiry Date : </b> {itemRow.selected_stock ? itemRow.selected_stock.expiry_date_display : "-"} <br />

                                                                        </div>
                                                                    </Ax>}
                                                            </Ax>) : (<div className="align-middle text-center">-</div>)}
                                                        </td> : null
                                                    }

                                                    <td className='text-center'>
                                                        {item.category == "services" ? null : <div className={['my-1', (itemRow.selected_stock && itemRow.selected_stock.free_qty < 0) ? 'text-danger' : ''].join(' ')}>
                                                            {itemRow.selected_stock ? itemRow.free_qty : this.state.adjustmentDetail && itemRow.adjustment_type == 'add' ? itemRow.free_qty : "0"}
                                                        </div>}
                                                    </td>
                                                    <td>
                                                        <TapInputNumber
                                                            name="add_qty"
                                                            value={itemRow.add_qty}
                                                            onChange={(e) => {
                                                                this.dynamicInputHandlerByKey(null, itemKey, 'adjustmentItemRows',null,'add_qty',e);
                                                                this.adjustQtyFields(itemKey, 'add_qty', e);
                                                            }}
                                                            placeholder="Add Qty"
                                                            min={0}
                                                            disabled={itemRow.adjustment_type === "subtract" ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <TapInputNumber
                                                            name="subtract_qty"
                                                            value={itemRow.subtract_qty}
                                                            onChange={(e) => {
                                                                this.dynamicInputHandlerByKey(null, itemKey, 'adjustmentItemRows',null,"subtract_qty",e);
                                                                this.adjustQtyFields(itemKey, 'subtract_qty', e);
                                                            }}
                                                            
                                                            placeholder="Subtract Qty"
                                                            min={0}
                                                            disabled={itemRow.adjustment_type === "add" ? true : false}
                                                        />
                                                    </td>
                                                    <td>
                                                        <TapInputNumber
                                                            name="new_qty"
                                                            value={itemRow.new_qty}
                                                            onChange={(e) => {
                                                                this.dynamicInputHandlerByKey(null, itemKey, 'adjustmentItemRows',null,'new_qty',e);
                                                                this.adjustQtyFields(itemKey, 'new_qty', e);
                                                            }}
                                                            placeholder="New Qty"
                                                            min={0}
                                                        />
                                                    </td>
                                                </Ax>)}
                                    </Ax>
                                    : <Ax>
                                        <td className="align-middle">
                                            <InputItemSearch
                                                changeEvent={(selectedOption) => {
                                                    this.onItemSelect(selectedOption, itemKey);
                                                }}
                                                only_active_item='Y'
                                            />
                                        </td>
                                        <td colSpan={this.state.track_batch_expiry == 'Y' ? 7 : 6}></td>
                                    </Ax>
                                )}
                                <td className="text-center align-middle">
                                    {this.state.adjustmentItemRows.length > 1
                                        ? <a href="#" onClick={() => this.removeItemRow(itemKey)}>
                                            <tapIcon.imageIcon icon={tapIcon.CloseCircleIcon} className="img-fluid" />
                                        </a>
                                        : null
                                    }
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-sm-12">
                <div className="row">

                    <div className="col-sm-12 text-end">
                        {this.state.addAdjustmentForm && this.state.addAdjustmentForm.warehouse_id ?
                            <a role="button" onClick={() => {this.setState({showItemSearchModal : false},() => this.itemSearchModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faSearch} /> Search Item </a>
                            : null}
                        {this.props?.permissions?.permissions?.includes("isc-item-add") && <a role="button" onClick={() => {this.setState({showItemAddModal : false},() => this.addItemModalInit())}} className="link-primary me-3"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Item</a>}
                        <a role="button" onClick={this.addNewItemRow} className="link-primary"><tapIcon.FontAwesomeIcon icon={tapIcon.faPlus} /> Add Row </a>
                    </div>

                </div>
            </div>
        </div>);
    }

  
    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Add Inventory Adjustment</title></Helmet>
            {this.pageTitlejsx()}
            <div className="container-fluid">
                <div className="page_containt row pl2">
                    <div className="pageTbl col-12 pl4">
                    { this.state.adjustmentFormDataLoaded 
                        ? this.saveAdjustmentFormJsx()
                        : <Loader/>
                    }
                    </div>
                </div>
                {this.state.showItemAddModal && <AddItemModal parentProps={this.props} ref={this.addItemModalRef} />}
                {this.state.showItemEditModal && <ItemEditModal parentProps={this.props} ref={this.editItemModalRef} afterSubmitItem={this.submitEditItem} />}
                <ItemDetailsModal itemData={this.state.itemSearchedDetails} closeEvent={this.itemDetailsClose} />
                {this.state.showItemSearchModal && <ItemSearchModal ref={this.itemSearchRef}
                    afterSelectingItems={(selectedItems) => {
                        this.addItemsfromSearch(selectedItems);
                    }}
                />}
            </div>
           
        </ApplicationLayout>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        iam_group_sites         :   state.app && state.app.user_sites ? state.app.group_sites : [],
        permissions            :   state.app.acl_info,
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
    };
};

export default connect(mapStateToProps)(InventoryAdjustmentAdd);