import React from 'react';
import { Modal } from 'bootstrap';
import { connect } from 'react-redux';
import Loader from "../../../components/ui/Loader/Loader";
import { toast } from 'react-toastify';
import tapIcon from "../../../services/TapIcon";
import Ax from "../../../components/hoc/Ax";
import HttpAPICall from '../../../services/HttpAPICall';
import { INVENTORY_API_BASE_URL_2 } from '../../../services/TapApiUrls';
import moment from 'moment';
import { DisplayListPagination, DisplaySearchCriteria, PeriodSelectionWithCustom } from '../../../components/TapUi';
import TapSelect from '../../../components/ui/TapSelect';
import ApplicationLayout from '../../../layouts/ApplicationLayout';
import InputVendorSearch from '../../includes/ui/InputVendorSearch';
import AppBaseComponent from '../../../components/AppBaseComponent';
import download from '../../../services/DownloadFile';
import swal from "sweetalert";
import InputItemSearch from '../../includes/ui/InputItemSearch';
import PurchaseDetail from './PurchaseDetail';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CurrencyFormatter from '../../../services/CurrencyFormatter';

class PurchaseList extends AppBaseComponent {

    constructor(props) {
        super(props);

        this.initPurchaseSearchForm         =       {
            search_transaction_id           :       '',
            date_range        :       null,
            date_range_start  :       null,
            date_range_end    :       null,
            search_purchase_bill_number     :       '',
            search_purchase_order_number    :       '',
            search_e_way_bill_number        :       '',
            search_vendor_ids               :       [],
            search_warehouse_ids            :       [],
            search_item_ids                 :       [],
            search_status_ids               :       [],
        }

        this.purchaseSearchFormLabel        =       {
            search_transaction_id           :       'Transaction ID : ',
            date_range                      :       'Date Range : ',
            search_purchase_bill_number     :       'Bill Number : ',
            search_purchase_order_number    :       'PO Bill Number : ',
            search_e_way_bill_number        :       'Eway Bill Number : ',
            search_warehouse_ids            :       'Warehouse : ',
            search_vendor_ids               :       'Vendor : ',
            search_item_ids                 :       'Items : ',
            search_status_ids               :        'Status : ',
        }

        this.updateCommentFormInit            =        {
            status                            :         '',
            notes                             :         '',
        }

        this.state                         =     {
            purchaseAddScreenView           :      false,
            purchaseUpdateScreenView        :      false,
            minimizeTable                   :       false,
            purchaseFormDataLoaded          :       false,
            purchaseAddFormData             :       [],
            listing_loading                 :       false,
            listing_tbl_page                :       1,
            purchase_listing                :       [],
            listingMeta                     :       null,
            totalListingCount               :       0,
            all_periods                     :       [],
            all_warehouses                  :       [],
            searchedVendor                  :       [],
            searchedItem                    :        [],
            purchaseSearchForm              :       {...this.initPurchaseSearchForm},
            submittedPurchaseFilterForm     :       {...this.initPurchaseSearchForm},
            formSearchedElems               :        [],
            pdfDownloading                  :       false,
            view_loading                    :       false,
            purchaseViewData                :       [],
            purchaseViewHeader              :       '',
            isc_configuration               :       {},
            reportDownloading               :       false,
            allStatusOptions                :       [],
            addCommentForm                  :       {...this.updateCommentFormInit},
            allAllowedStatus                :       []
        };
        this.addConumptionRef              =   React.createRef();
        this.addReturnPurchaseRef          =   React.createRef();
        this.myRef                         =   React.createRef();

        this.poReportUrl    =   INVENTORY_API_BASE_URL_2 + '/report/item-wise-purchase-order';
        this.purchaseUrl    =   INVENTORY_API_BASE_URL_2 + '/purchase';
        this.purListUrl     =   INVENTORY_API_BASE_URL_2 +  '/purchases';
    }
    
    
    componentDidMount() {
        this.initializeData(this.props);

        if (this.props?.match?.params?.id || this.props?.location?.state?.id) {
            const purchaseId     =   this.props?.match?.params?.id || this.props?.location?.state?.id;
            this.viewPurchaseDetail(purchaseId);
        }
      
        let ParamObject                 =   new URLSearchParams(this.props.location.search);
        if(ParamObject.get('search_purchase_id') || ParamObject.get('search_id') || ParamObject.get('transaction_id')){
            let id              =   ParamObject.get('search_purchase_id') || ParamObject.get('search_id') || ParamObject.get('transaction_id');
            this.viewPurchaseDetail(id)
        }

        if(ParamObject.get('search_id')){
            let id              =   ParamObject.get('search_id')
            this.viewPurchaseDetail(id)
        }

        //****************After Add and update ***************************/
         if(this.props.location && this.props.location.state && this.props.location.state.purchaseId){
            this.viewPurchaseDetail(this.props.location.state.purchaseId)
         }
         if(this.props.location && this.props.location.state && this.props.location.state.purchaseData){
              this.setState({minimizeTable      :  true,
                          purchaseViewData      :  this.props.location.state.purchaseData ,  
                           purchaseViewHeader   :  this.props.location.state.purchaseData.transaction_id })
         }
        this.purchaseSearchModal         =    new Modal(document.getElementById('purchaseSearchModal'), {keyboard: false, backdrop :false});
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.all_periods !== this.props.all_periods) {
           this.initializeData(nextProps)
        }
            
    }

    initializeData     =      (pr)      =>      {
        if(pr){
            let all_periods             =       [...pr.all_periods , {key : 'custom' , display : 'Custom Date Range'}] ;
           
            HttpAPICall.withAthorization('GET', this.purListUrl + '/list_form_data', pr.access_token, null, null, (response) => {
              
                this.setState({
                    all_warehouses          :        pr.all_warehouses ? pr.all_warehouses.map((m) => { return { value: m.id, label: `${m.name} (${m.code})` }}) : [] ,
                    all_periods             :        all_periods ,
                    isc_configuration       :        pr.isc_configuration,
                    allStatusOptions        :        response.data && response.data.status && response.data.status.length > 0 ?  response.data.status.map((s) => { return {value: s.id, label: s.name}; }) : [],
                }, () => {
                    let ParamObject                 =   new URLSearchParams(this.props.location.search);
                    if(ParamObject.get('transaction_period_range_start')) {
                        let updateFilterForm = {date_range_start : ParamObject.get('transaction_period_range_start'),
                                                date_range_end   :  ParamObject.get('transaction_period_range_end'),
                                                date_range       : "custom"};
                    
            
                        if(ParamObject.get('search_warehouse_ids')) {
                            let warehouse                =   this.props.all_warehouses.find(us => us.id == ParamObject.get('search_warehouse_ids'));
                            if(warehouse) {
                                updateFilterForm['search_warehouse_ids']         =   [warehouse.id];
                            }
                        }
                        
                        setTimeout(() => {
                            this.setState({purchaseSearchForm    : 
                                        {   ...this.state.purchaseSearchForm,...updateFilterForm } 
                            }, () => {
                                    this.submitPurchaseSearchForm()
                            });
                        }, 1)
                    
                    }else{
                        this.loadListingTblData(1);
                        
                    }
                    
                });
            }, (err) => {

            });
        }
    }

    //************************GET PURCHASE LISTING**************** */
    loadListingTblData                 =   (page = 1)  =>         {
        this.setState({listing_loading: true, listing_tbl_page: page});
        let params                      =   {...this.state.submittedPurchaseFilterForm,page:page};
        HttpAPICall.withAthorization('GET', this.purListUrl, this.props.access_token, params, {}, (resp) => {
            let respData            =       resp.data;
            this.setState({
                purchase_listing    :       respData.data,
                listingMeta         :       respData.meta,
                totalListingCount   :       respData.meta.total,
        });
        }).then(() => this.setState({listing_loading: false}));
    }

    //************************GET PURCHASE DETAILS**************** */
    getPurchaseViewDetails             =    (transaction_id)        =>  {
    //    if(transaction_id !== this.state.purchaseViewHeader){
        this.setState({view_loading : true})
        HttpAPICall.withAthorization('GET',  this.purchaseUrl + '/' + transaction_id, this.props.access_token, {need_documents : "Y"}, {}, (resp) => {
            let respData = resp.data;
            this.setState({
                purchaseViewData        : respData.data,
                purchaseViewHeader      : respData.data.transaction_id,
                allAllowedStatus     :   respData.data && respData.data.user_status && respData.data.user_status.length > 0 ? respData.data.user_status.map((s) => { return {value: s.id, label: s.name}; }) : [] ,
                addCommentForm       :   {...this.updateCommentFormInit , status : respData.data.status}
            });
           
        },(err) => {
            if(err){
                this.setState({minimizeTable : false})
            }
        }).then(() => this.setState({view_loading: false}));
    //    }
    }

    //************************PURCHASE SEARCH **********************/
    purchaseSearchModalInit               =       ()             =>        {
        this.purchaseSearchModal.show();
    }

    submitPurchaseSearchForm                =       (e)          =>          {
        e &&  e.preventDefault()
        let serachFormKeys              =   Object.keys(this.state.purchaseSearchForm);
       
        let searchedElems               =   [];
        let searchParams                =   {};
        serachFormKeys.map((key)        =>  {
            
            let label                       =   this.purchaseSearchFormLabel[key];
            let value                       =   this.state.purchaseSearchForm[key];
           
            if(value   && value.length > 0) {
                searchParams[key]      =   value;
           if(label) {
               
                    let show_val            =   value;
                    if(key == 'search_vendor_ids') {
                        show_val            =   this.state.searchedVendor ? this.state.searchedVendor.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_warehouse_ids') {
                        show_val            =   this.state.all_warehouses.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'search_transaction_id') {
                        show_val            =   this.state.purchaseSearchForm ? this.state.purchaseSearchForm.search_transaction_id : '';
                    }
                    if(key == 'search_purchase_bill_number') {
                        show_val            =   this.state.purchaseSearchForm ? this.state.purchaseSearchForm.search_purchase_bill_number : '';
                    }
                    if(key == 'search_purchase_order_number') {
                        show_val            =   this.state.purchaseSearchForm ? this.state.purchaseSearchForm.search_purchase_order_number : '';
                    }
                    if(key == 'search_e_way_bill_number') {
                        show_val            =   this.state.purchaseSearchForm ? this.state.purchaseSearchForm.search_e_way_bill_number : '';
                    }
                    if(key == 'search_item_ids') {
                        show_val            =   this.state.searchedItem ? this.state.searchedItem.map(s => s.display_label).join(', ') : '';
                    }
                    if(key == 'search_status_ids') {
                        show_val            =   this.state.allStatusOptions.filter((s) => value.includes(s.value)).map(s => s.label).join(', ');
                    }
                    if(key == 'date_range') {
                        
                        let  start_range = moment(this.state.purchaseSearchForm.date_range_start).format('DD-MMM-YYYY');
                        let end_range = moment(this.state.purchaseSearchForm.date_range_end).format('DD-MMM-YYYY')
                         let display_custom = `Custom Date Range (${start_range} - ${end_range})`
                         show_val            =  this.state.all_periods.filter((s) => value.includes(s.key)).map(s => s.display_with_date ? s.display_with_date : display_custom);
                        
                    }
                   
                  searchedElems.push(label + `<b>${show_val}</b>`);

                }
            }
        });
        this.setState({
            submittedPurchaseFilterForm   :   {...this.state.purchaseSearchForm},
            formSearchedElems           :   searchedElems
        }, () => {
            this.purchaseSearchModal.hide()
            this.loadListingTblData(1);
        });
    }

    purchaseSearchClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedItem                    :   [],
            purchaseSearchForm            :   {...this.initPurchaseSearchForm},
            submittedPurchaseFilterForm     :   {...this.initPurchaseSearchForm}
        }, () => {
            this.loadListingTblData(1);
            this.purchaseSearchModal.hide();
            this.props.history.push('/purchase_list')
        });
    }

    purchaseClear                  =        ()             =>        {
        this.setState({
            formSearchedElems               :   [],
            searchedVendor                  :   [],
            searchedItem                    :   [],
            purchaseSearchForm            :   {...this.initPurchaseSearchForm},
            submittedPurchaseFilterForm     :   {...this.initPurchaseSearchForm}
        }, () => {
            this.purchaseSearchModal.hide();
        });
    }

   

    //***********************DOWNLOAD PDF **************************** */
    downloadPdfHandler              =    (transaction_id, type=null)     =>         {
        this.setState({pdfDownloading:  true});
        let params = "download";
        if(type === "placement_slip_download") {
            params="placement_slip_download";
        }
        HttpAPICall.withAthorization('GET',  this.purchaseUrl + '/' + transaction_id, this.props.access_token,{download : params},{},(response) => {
            download.file(response.data.file_path);
        })
        .then(() => this.setState({pdfDownloading: false}));
    }

    
    //***********************DELETE PDF **************************** */
    deletePurchase              =           (transaction_id)              =>      {
        swal({
            title: "Delete",
            text: "This action cannot be reversed, Are you sure you want to proceed?",
            icon: "warning",
            // dangerMode: true,
            buttons: ["No", "Yes"],
        })
            .then(willDelete => {
                if (willDelete) {
                    HttpAPICall.withAthorization('DELETE', this.purchaseUrl + '/' + transaction_id, this.props.access_token, {}, {}, (response) => {
                        toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
                        this.setState({minimizeTable : false})
                        this.loadListingTblData(1)
                     }).then(() => {

                    });
                }
            });
    }

    openAddPurchaseScreen       =      ()      =>     {
        this.props.history.push('/purchase_list/add')     
    }

    clonePurchaseScreenInit             =       ()      =>      {
        // if (this.state.purchaseViewData.transaction_type == "purchase_consumption") {
        //     this.props.history.push({ pathname: `/purchase_list/update_with_consumption`, state: { purchaseEditData: this.state.purchaseViewData,clone : 'yes' } })
        // } else {
            this.props.history.push({pathname: `/purchase_list/add`, state: { purchaseCloneData: this.state.purchaseViewData }})
        // }
        
    }
    
    openUpdatePurchaseScreen       =      (id)      =>     {
        if (!this.state.purchaseViewData) {
            HttpAPICall.withAthorization('GET', this.purchaseUrl + '/' + id, this.props.access_token, {}, {}, (resp) => {
                let respData = resp.data;
                if (this.state.purchaseViewData.transaction_type == "purchase_consumption") {
                    this.props.history.push({ pathname: `/purchase_list/update_with_consumption`, state: { purchaseEditData: respData } })
                } else {
                    this.props.history.push({ pathname: `/purchase_list/update`, state: { purchaseData: respData } })
                }

            })
        } else {
            if (this.state.purchaseViewData.transaction_type == "purchase_consumption") {
                this.props.history.push({ pathname: `/purchase_list/update_with_consumption`, state: { purchaseEditData: this.state.purchaseViewData } })
            } else {
                this.props.history.push({ pathname: `/purchase_list/update`, state: { purchaseData: this.state.purchaseViewData } })
            }
        }
    }
      
    viewPurchaseDetail          =      (transaction_id)        =>     {
        if (transaction_id) {
            this.setState({ minimizeTable: true })
            this.getPurchaseViewDetails(transaction_id)
        }
    }

    closeViewDetails           =      ()      =>    {
        this.setState({minimizeTable  :  false})
        this.props.history.push('/purchase_list')
    }
    
    purchaseDownloadHandler                 =   ()  =>  {
        let params                          =   {...this.state.submittedPurchaseFilterForm};
        if(JSON.stringify(this.state.submittedPurchaseFilterForm) !== JSON.stringify(this.initPurchaseSearchForm)){
            this.purchaseDownloadStart(params);
        } else {
            swal({ title: "Download",
            text: "The download will be for Current Month's Transaction, in case you want different Transaction Period then please change by filter.",
            icon: "warning",
            buttons: ["Cancel", "Okay"],
            }).then(willDownload => {
                if (willDownload) {
                    params = {...params , date_range: "current_month"}
                    this.purchaseDownloadStart(params);
                }
            });
        }
    }
    
    purchaseDownloadStart                   =   (params)  =>  {
        this.setState({reportDownloading : true});
        HttpAPICall.withAthorization('GET', this.poReportUrl, this.props.access_token, {...params, download : 'download'} , {} , (response) => download.file(response.data.file_path)).then(() => this.setState({reportDownloading: false}));
    }

    addToConsumptionHandler         =       ()      =>      {
       
        this.addConumptionRef.current.addToConsumptionHandler()
    }

    addToInventoryTransferHandler         =       ()      =>      {
       
        this.addConumptionRef.current.addToInventoryTransferHandler()
    }

    addToAllocation                 =       ()          =>      {
        this.addConumptionRef.current.addToAllocationHandler()
    }

    addToPurchaseReturn         =       ()      =>      {
        this.addConumptionRef.current.addToPurchaseReturnHandler()
    }

    updateComment           =           ()          =>      {
        this.myRef.current.scrollIntoView()
    }

    submitUpdateCommentForm         =       (e)          =>      {
        e.preventDefault()
        this.setState({addCommentFormSubmitting : true})
        let frmData = {
             ...this.state.addCommentForm,
             transaction_id : this.state.purchaseViewHeader
           }
           HttpAPICall.withAthorization('PUT', this.purchaseUrl + '/comment', this.props.access_token, {}, { ...frmData }, (response) => {
               toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
               this.viewPurchaseDetail(this.state.purchaseViewHeader);
               this.loadListingTblData(1)
               this.setState({addCommentForm : {...this.updateCommentFormInit}})
           }).then(() => this.setState({ addCommentFormSubmitting : false }));
    }

     //***********************PURCHASE JSX****************

    purchaseListjsx            =       ()      =>    {
        return (<Ax><div className="page_title row m0">
            <div className={this.state.minimizeTable ? "col-3" : "col-12"}>
                <h3>Purchase</h3>
                <div className="text-end mt15">
                    {(!this.state.minimizeTable) ?
                        <Ax><button type="button" className="btn btn-primary" disabled={!this.props.permissions.includes('isc-purchase-add')} onClick={this.openAddPurchaseScreen}>New Purchase</button>
                            <button type="button" className="btn btn-secondary"
                                disabled={this.state.reportDownloading || this.state.listing_loading}
                                onClick={this.purchaseDownloadHandler} >
                                <tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} />
                                {this.state.reportDownloading ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </Ax>
                        : null
                    }
                    <button type="button" className="btn btn-secondary" onClick={this.purchaseSearchModalInit} disabled={this.state.reportDownloading || this.state.listing_loading} >
                        <tapIcon.imageIcon icon={tapIcon.SearchIcon} className="img-fluid" alt="item-action" />
                    </button>
                    {this.state.minimizeTable ? <span className="dropdown">
                        <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                            <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {this.props.permissions.includes('isc-purchase-add') && <li><a className="dropdown-item" onClick={this.openAddPurchaseScreen}>New Purchase</a></li>}
                            <li><a className="dropdown-item" disabled={this.state.reportDownloading || this.state.listing_loading} onClick={this.purchaseDownloadHandler}>Download</a></li>
                        </ul>
                    </span> : null}
                </div>
            </div>
            {
                this.state.minimizeTable
                    ? (
                        <div className="col-9 tap_border_left">
                            <h3>Purchase : {this.state.purchaseViewHeader}</h3>
                            <div className="text-end mt15">
                                <button disabled={(this.state.purchaseViewData && this.state.purchaseViewData.can_edit == 'N') || !this.props.permissions.includes('isc-purchase-edit') ? true  : false} type="button" className="btn btn-secondary" onClick={() => this.openUpdatePurchaseScreen(this.state.purchaseViewData?.transaction_id)}>
                                    Edit
                                </button>
                                <button  type="button" className="btn btn-secondary"  onClick={() => this.updateComment()} >Update Status </button>
                             
                                <button type="button" id='downloadMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary" disabled={this.state.pdfDownloading}><tapIcon.FontAwesomeIcon icon={tapIcon.faDownload} /></button>
                                <ul className="dropdown-menu" aria-labelledby="downloadMenuButton">
                                    <li><Link className="dropdown-item" role="button" onClick={() => this.downloadPdfHandler(this.state.purchaseViewData?.transaction_id)}>Voucher PDF</Link></li>
                                    <li><Link className="dropdown-item" role="button" onClick={() => this.downloadPdfHandler(this.state.purchaseViewData?.transaction_id, 'placement_slip_download')}>Item Placement Slip</Link></li>
                                </ul>

                                <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-secondary">
                                    <tapIcon.imageIcon icon={tapIcon.ActionIcon} className="img-fluid" alt="item-action" />
                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    
                                    <li><a className="dropdown-item" onClick={() => this.clonePurchaseScreenInit()}>Clone</a></li>
                                    <li><a className={!this.props.permissions.includes('isc-purchase-delete') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={!this.props.permissions.includes('isc-purchase-delete') ? true : false} href="#" onClick={() => this.deletePurchase(this.state.purchaseViewData?.transaction_id)}>Delete</a></li>
                                    <li><a className={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") ||  !this.props.permissions.includes('isc-allocation-add') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || !this.props.permissions.includes('isc-allocation-add') ? true : false} onClick={() => this.addToAllocation()}>Add to Allocation</a></li>
                                    <li><a className={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || !this.props.permissions.includes('isc-consumption-add') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || !this.props.permissions.includes('isc-consumption-add') ? true : false} onClick={() => this.addToConsumptionHandler()}>Add to Consumption</a></li>
                                    <li><a className={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || !this.props.permissions.includes('isc-inventory_transfer-add') ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || !this.props.permissions.includes('isc-inventory_transfer-add') ? true : false} onClick={() => this.addToInventoryTransferHandler()}>Add to Inventory Transfer</a></li>
                                    <li><a className={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || (this.state?.purchaseViewData?.status_data?.level !== 3 && this.state?.purchaseViewData?.status_data?.level !== 4) || !this.props.permissions.includes('isc-purchase-return-add')  ? "dropdown-item disabled" : "dropdown-item"} role="button" disabled={(this.state.purchaseViewData && this.state.purchaseViewData.transaction_type == "purchase_consumption") || !this.props.permissions.includes('isc-purchase-return-add')  ? true : false} onClick={() => this.addToPurchaseReturn()}>Add to Purchase Return</a></li>
                                </ul>
                                <button type="button" className="btn btn-secondary" onClick={this.closeViewDetails}>
                                    <tapIcon.imageIcon icon={tapIcon.CloseIcon} className="img-fluid" alt="item-close" />
                                </button>
                            </div>
                        </div>
                    )
                    : null
            }
        </div>
            <div className="container-fluid pl5">
                <div className="page_containt row">
                    <div className={['pageTbl', this.state.minimizeTable ? 'col-3' : 'col-12'].join(' ')} >{this.purchaseListingTableJsx()}</div>
                    {this.state.minimizeTable ? <div className="col-9 pr4 pl4"><div className="bg-white p-2" style={{ height: "100%" }}>{this.state.view_loading ? <Loader /> : this.purchaseViewDetails()}</div></div> : null}
                </div>
            </div>
        </Ax>);
    }

    purchaseListingTableJsx            =   ()   =>   {
        return (<Ax>
            <DisplaySearchCriteria searchedElems={this.state.formSearchedElems} onClearFilter={this.purchaseSearchClear} />
            <table className="table table-bordered table-fixed  bg-white table-sm">
                <thead className="table-secondary" >
                    {this.state.minimizeTable
                        ? (<tr>
                            <th scope="col" style={{ width: "15%" }}>Purchase</th>
                        </tr>)
                        : (<tr>
                            <th scope="col" style={{ width: "5%" }}>S.No</th>
                            <th scope="col" style={{ width: "10%" }} className='text-center'>Date</th>
                            <th scope="col" style={{ width: "10%" }}> Transaction ID</th>
                            <th scope="col" style={{ width: "10%" }}>Bill Number</th>
                            <th scope="col" style={{ width: "17%" }}>Warehouse</th>
                            <th scope="col" style={{ width: "15%" }} className='text-center'>Vendor</th>
                            <th scope="col" style={{ width: "6%" }} className='text-center'>Total Quantity</th>
                            <th scope="col" style={{ width: "6%" }} className='text-center'>Currency</th>
                            <th scope="col" style={{ width: "8%" }} className='text-end'>Amount</th>
                            <th scope="col" style={{ width: "8%" }} className='text-center'>Status</th>
                            <th scope="col" style={{ width: "5%" }}>Action</th>
                        </tr>)
                    }
                </thead>
                <tbody>
                    {this.state.listing_loading
                        ? (<tr><td colSpan="10"><Loader /></td></tr>)
                        : (this.state.purchase_listing.length > 0
                            ? (this.state.purchase_listing.map((item, index) => {
                                return (<tr key={index} >
                                    {
                                        this.state.minimizeTable
                                            ? (
                                                <Ax>
                                                    <td style={{ width: "25%" }}> 
                                                    <Link className="text-capitalize" to={{ pathname: "/purchase_list/" + item.transaction_id }} onClick={() => this.viewPurchaseDetail(item.transaction_id)}
                                                    >{item.transaction_id ?  <div>{item.transaction_id} ({moment(item.transaction_date).format("DD-MMM-YYYY")}) </div> : "-"}</Link>
                                                    <div className="mt-1">
                                                        <small> <span className="d-inline-block text-truncate" style={{ 'max-width': '200px' }}>{item.warehouse?.name ?? "-"}</span> </small>
                                                        <small className="float-end">{item.status_data ? item.status_data.name : "-"}</small>
                                                    </div>
                                                    </td>
                                                </Ax>
                                            )
                                            : (<Ax>
                                                <td className="text-center">{this.state.listingMeta ? this.state.listingMeta.from + index : index}</td>
                                                <td className='text-center'>{item.transaction_date ? moment(item.transaction_date).format("DD-MMM-YYYY") : "-"}</td>
                                                <td>
                                                <Link to={{ pathname: "/purchase_list/" + item.transaction_id }} onClick={() => this.viewPurchaseDetail(item.transaction_id)}
                                                >{item.transaction_id ? item.transaction_id : "-"}</Link></td>
                                                <td>{item.purchase_bill_number ? item.purchase_bill_number : "-"}
                                                </td>
                                                <td className='text-capitalize'>{item.warehouse ? <div>{item.warehouse.name}</div>
                                                    : "-"}</td>
                                                <td className='text-capitalize'>{item.vendor ? <div>{item.vendor.name}</div>
                                                    : "-"}</td>
                                                <td className='text-center'>{item.total_qty ? item.total_qty : 0}</td>
                                                <td className='text-center'>{item.currency ? item.currency : "INR"}</td>
                                                <td className='text-end'>{item.total_amount_vc ? <CurrencyFormatter currencyCode={item.currency ? item.currency : "INR"} amount={parseFloat(item.total_amount_vc).toFixed(2)} /> : 0}</td>
                                                <td className='text-center'>{item.status_data ? item.status_data.name : "-"}</td>
                                                <td className="text-center">
                                                    <button type="button" id='actionMenuButton' data-bs-toggle="dropdown" aria-expanded="false" className="btn">
                                                        <tapIcon.FontAwesomeIcon icon={tapIcon.faEllipsisV} />
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><a className="dropdown-item" role="button" onClick={() => this.viewPurchaseDetail(item.transaction_id)}>View</a></li>
                                                 </ul>
                                                </td>
                                            </Ax>
                                            )}
                                </tr>)
                            }))
                            : (<tr><td colSpan="11" className="text-center">No Records</td></tr>)
                        )
                    }
                </tbody>
            </table>
            <DisplayListPagination
                meta={this.state.listingMeta}
                onPageChange={(e) => this.loadListingTblData(e.selected + 1)}
            />
        </Ax>)
    }

    purchaseViewDetails                =   ()   =>   {
        let purchaseDetails = this.state.purchaseViewData;
        
        return (<div className='tab_content_header'>
            <PurchaseDetail purchaseData={purchaseDetails} ref={this.addConumptionRef} parentProps={this.props} />
            <div>
                <div className="tab_content_wrapper mt-4" ref={this.myRef}>
                    <span className="content_heading">Comments & History</span>
                </div>
                <form onSubmit={this.submitUpdateCommentForm}>
                    <div className="my-3 row">
                        <label className="col-sm-3 col-form-label">Change status to</label>
                        <div className="col-sm-5">
                            <TapSelect
                                changeEvent={(selectedOption) => {
                                    this.tapSelectChange(selectedOption, 'addCommentForm', 'status');
                                }}
                                value={this.state.allAllowedStatus.find(s => s.value == this.state.addCommentForm.status)}
                                options={this.state.allAllowedStatus}
                                isSearchable={false}
                                isDisabled={!this.props.permissions.includes('isc-purchase-status-change')}
                                placeholder="Please Select Status"
                            />
                        </div>
                    </div>
                    <div className="my-2">
                        <label className="form-label">Comments</label>
                        <textarea
                            name="notes"
                            value={this.state.addCommentForm.notes}
                            onChange={(e) => this.formInputHandler(e, 'addCommentForm')}
                            className="form-control"
                            placeholder="Please Add a comment"
                            required
                            style={{ height: "100px" }}
                        />
                    </div>
                    <div className="col-12 text-end">
                        <button type="submit" disabled={this.state.addCommentFormSubmitting ? true : false} className="btn btn-primary mx-2">
                            Update {this.state.addCommentFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                        </button>
                    </div>
                </form>
                <hr/>
                <div className="my-1">
                    {
                        purchaseDetails.comments && purchaseDetails.comments.length > 0 ? purchaseDetails.comments.map((c, k) => {
                            return (<Ax key={k}><div className="row">
                                {c.msg ? <div className="col-sm-8" dangerouslySetInnerHTML={{ __html: c.msg }} /> : <div className='col-sm-8'>-</div>}
                                <div className="col-sm-4 text-end">
                                    <tapIcon.imageIcon icon={tapIcon.CalenderIcon} className="img-fluid pr-5 fs12" alt="item-action" />
                                    <span> {c.created_at_display}</span>
                                </div>
                                <div className="col-sm-8 my-1">{c.notes}</div>
                            </div><hr /></Ax>);
                        })
                            : null
                    }
                </div>

            </div>
        </div>)
    }

     //***********************SEARCH PURCHASE JSX****************
     purchaseSearchModalJsx               =        ()             =>        {
       
        return (
            <div className="modal fade" id="purchaseSearchModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="searchAssetModalLabel">Purchase Search</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <form id="purchaseSearchForm" onSubmit={this.submitPurchaseSearchForm}>
                            <div className="modal-body">
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Transaction ID</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_transaction_id"
                                            type="text"
                                            value={this.state.purchaseSearchForm.search_transaction_id}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "purchaseSearchForm")}
                                            placeholder="Please enter Transaction ID"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Eway Bill Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_e_way_bill_number"
                                            type="text"
                                            value={this.state.purchaseSearchForm.search_e_way_bill_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "purchaseSearchForm")}
                                            placeholder="Please enter Eway Bill Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">Bill Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_purchase_bill_number"
                                            type="text"
                                            value={this.state.purchaseSearchForm.search_purchase_bill_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "purchaseSearchForm")}
                                            placeholder="Please enter Bill Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>
                                    <label className="col-sm-2 col-form-label col-form-label-sm  ">PO Number</label>
                                    <div className="col-sm-4">
                                        <input
                                            name="search_purchase_order_number"
                                            type="text"
                                            value={this.state.purchaseSearchForm.search_purchase_order_number}
                                            className="form-control"
                                            autoComplete="off"
                                            onChange={(e) => this.formInputHandler(e, "purchaseSearchForm")}
                                            placeholder="Please enter PO  Number"
                                            style={{ height: "30px", fontSize: "93%" }}
                                        />
                                    </div>


                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Warehouse</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.all_warehouses}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseSearchForm', 'search_warehouse_ids')}
                                            value={this.state.all_warehouses.filter(u => this.state.purchaseSearchForm?.search_warehouse_ids?.includes(u.value))}
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Warehouses"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Vendor</label>
                                    <div className="col-sm-10">
                                        <InputVendorSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'purchaseSearchForm', 'search_vendor_ids');
                                                this.setState({ searchedVendor: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            value={this.state.searchedVendor.length > 0 ? this.state.searchedVendor.map(a => { a.label = a.display_label; return a; }) : []}
                                            containerHeight="30px"
                                            fontSize="93%"

                                        />
                                    </div>

                                    
                                </div>
                                <div className="row my-3 align-items-center">
                                        <label className="col-sm-2 col-form-label col-form-label-sm ">Item</label>
                                        <div className="col-sm-10">
                                            <InputItemSearch
                                                placeholder="Search Item"
                                                isMulti={true}
                                                changeEvent={(selectedOption) => {
                                                    this.tapSelectChange(selectedOption, 'purchaseSearchForm', 'search_item_ids');
                                                    this.setState({
                                                        searchedItem: selectedOption ? selectedOption : null
                                                    }, () => { });
                                                }}
                                                containerHeight="30px"
                                                fontSize="93%"
                                                value={this.state.searchedItem.length > 0 ? this.state.searchedItem.map(a => { a.label = a.display_label; return a; }) : []}
                                            />
                                        </div>

                                    </div>
                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Status</label>
                                    <div className="col-sm-10">
                                        <TapSelect
                                            options={this.state.allStatusOptions}
                                            changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseSearchForm', 'search_status_ids')}
                                            value={this.state.allStatusOptions.filter(u => this.state.purchaseSearchForm?.search_status_ids?.includes(u.value))}
                                            isMulti={true}
                                            isClearable={true}
                                            placeholder="Select Status"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>

                                </div>

                                <div className="row my-3 align-items-center">
                                    <label className="col-sm-2 col-form-label col-form-label-sm ">Transaction Period</label>
                                    <div className="col-sm-10">
                                        <PeriodSelectionWithCustom
                                            periods={this.state.all_periods && this.state.all_periods !== undefined ? this.state.all_periods : []}
                                            value={this.state.purchaseSearchForm.date_range}
                                            startDate={this.state.purchaseSearchForm.date_range_start}
                                            endDate={this.state.purchaseSearchForm.date_range_end}
                                            onSelectPeriod={(period, startDate, endDate) => {
                                                this.setState({
                                                    purchaseSearchForm: {
                                                        ...this.state.purchaseSearchForm,
                                                        date_range: period,
                                                        date_range_start: startDate ? startDate : null,
                                                        date_range_end: endDate ? endDate : null
                                                    }
                                                });
                                            }}
                                            className="form-control-sm"
                                            containerHeight="30px"
                                            fontSize="93%"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={this.purchaseClear}>Close</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }


    render                              =   ()  =>  {
        return (<ApplicationLayout>
        <Helmet><title>Purchase Listing</title></Helmet>
            {this.purchaseListjsx()}
            {this.purchaseSearchModalJsx()}
        </ApplicationLayout>);
    }

}


const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        all_periods             :   state.app && state.app.all_periods ? state.app.all_periods : [],
        all_warehouses          :   state.app && state.app.warehouses ? state.app.warehouses : [],
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(PurchaseList);