import React from 'react';
import AppBaseComponent from '../../../components/AppBaseComponent';
import { connect } from 'react-redux';
import DateService from '../../../services/DateService';

class StepsDetailTableFields extends AppBaseComponent {
    constructor(props) {
        super(props);
    }

    render () {
        const {logisticViewData, field, originType, origin_type_detail, destinationType, destination_type_detail} = this.props;
        return (
            <>
            {
                field ===  "logistic_type" && 
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Logistic Type</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.logistic_type ? logisticViewData.logistic_type : ''}</td>
                </tr>
            }
            {
                field ===  "division" && 
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Division</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.division_name ? logisticViewData.division_name : ''}</td>  
                </tr>
            }
            {
                field ===  "origin_type" && 
                <>
                    <tr>
                        <td className="fs14 fw-normal" style={{ "width": "30%" }}>{originType}</td>
                        <td className="fs14 fw500" style={{ "width": "70%" }}>{origin_type_detail}</td>
                    </tr>
                    <tr>
                        <td className="fs14 fw-normal" style={{ "width": "30%" }}>Address</td>
                        <td className="fs14 fw500" style={{ "width": "70%" }}>
                            {
                                logisticViewData.display_origin_address 
                                ? 
                                `${logisticViewData.display_origin_address.address1 ? logisticViewData.display_origin_address.address1 + ", " : ""}${logisticViewData.display_origin_address.address2 ? logisticViewData.display_origin_address.address2 + ", " : ""}${logisticViewData.display_origin_address.landmark ? logisticViewData.display_origin_address.landmark + ", " : ""}${logisticViewData.display_origin_address.city ? logisticViewData.display_origin_address.city + ", " : ""}${logisticViewData.display_origin_address.country ? logisticViewData.display_origin_address.country + ", " : ""}${logisticViewData.display_origin_address.state ? logisticViewData.display_origin_address.state + ", " : ""}${logisticViewData.display_origin_address.district ? logisticViewData.display_origin_address.district + ", " : ""}${logisticViewData.display_origin_address.pincode ? logisticViewData.display_origin_address.pincode + ", " : ""}`
                                :
                                ''
                            }
                        </td>
                    </tr>

                </>
            }
            {
                field ===  "destination" && 
                <>
                    <tr>
                        <td className="fs14 fw-normal" style={{ "width": "30%" }}>{destinationType}</td>
                        <td className="fs14 fw500" style={{ "width": "70%" }}>{destination_type_detail}</td>
                    </tr>
                    <tr>
                        <td className="fs14 fw-normal" style={{ "width": "30%" }}>Address</td>
                        <td className="fs14 fw500" style={{ "width": "70%" }}>
                            {
                                logisticViewData.display_destination_address 
                                ?
                                `${logisticViewData.display_destination_address.address1 ? logisticViewData.display_destination_address.address1 + ", " : ""}${logisticViewData.display_destination_address.address2 ? logisticViewData.display_destination_address.address2 + ", " : ""}${logisticViewData.display_destination_address.landmark ? logisticViewData.display_destination_address.landmark + ", " : ""}${logisticViewData.display_destination_address.city ? logisticViewData.display_destination_address.city + ", " : ""}${logisticViewData.display_destination_address.country ? logisticViewData.display_destination_address.country + ", " : ""}${logisticViewData.display_destination_address.state ? logisticViewData.display_destination_address.state + ", " : ""}
                                ${logisticViewData.display_destination_address.district ? logisticViewData.display_destination_address.district + ", " : ""}${logisticViewData.display_destination_address.pincode ? logisticViewData.display_destination_address.pincode + ", " : ""}`
                                :
                                ''
                            }
                        </td>
                    </tr>
                </>
            }
            {
                field ===  "date_of_dispatch"  &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Date of Dispatch</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.dispatch_date ? DateService.dateTimeFormat(logisticViewData.dispatch_date, 'DD-MMM-YYYY') : ''}</td>
                </tr>
            }
            {
                field ===  "expected_delivery_date"  &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Expected Delivery Date</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.delivery_date ? DateService.dateTimeFormat(logisticViewData.delivery_date, 'DD-MMM-YYYY') : ''}</td>
                </tr>
            }
            {
                field ===  "delivery_date_at_godown" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Delivery Date at Godown</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.delivery_date_at_godown ? DateService.dateTimeFormat(logisticViewData.delivery_date_at_godown, 'DD-MMM-YYYY') : ''}</td>
                </tr>
            }
            {
                field ===  "delivery_date_at_site" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Delivery Date at Site</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.delivery_date_at_site ? DateService.dateTimeFormat(logisticViewData.delivery_date_at_site, 'DD-MMM-YYYY') : ''}</td>
                </tr>
            }
            {
                field ===  "transport_days" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Transport Days</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.transport_days ? logisticViewData.transport_days : ''}</td>
                </tr>
            }
            {
                field ===  "receiver_name" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Receiver Name</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.receiver_name}</td>
                </tr>
            }
            {
                field ===  "type_of_load" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Type of Load</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>
                        {logisticViewData.load_type ? logisticViewData.load_type : ''}
                    </td>
                </tr>
            }
            {
                field ===  "consigment_number" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Consigment Number</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.consigment_number ? logisticViewData.consigment_number : ''}</td>
                </tr>
            }
            {
                field ===  "booking_place" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Booking Place</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.booking_place ? logisticViewData.booking_place : ''}</td>
                </tr>
            } 
            {
                field ===  "transporter_name" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Courier/Transporter Name</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.transporter_name ? logisticViewData.transporter_name : ''}</td>
                </tr>
            } 
            {
                field ===  "vehicle_number" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Vehicle Number</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.vehicle_number ? logisticViewData.vehicle_number : ''}</td>
                </tr>
            } 
            {
                field ===  "weight" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Weight</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.weight ? logisticViewData.weight : ''} {logisticViewData.unit_of_measurement}</td>
                </tr>
            } 
            {
                field ===  "no_of_boxes" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>No. of Boxes</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.number_of_boxes ? logisticViewData.number_of_boxes : ''}</td>
                </tr>
            } 
            {
                field ===  "invoice_number" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Invoice Number</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.gst_invoice_number ? logisticViewData.gst_invoice_number : ''}</td>
                </tr>
            } 
            {
                field ===  "invoice_date" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Invoice Date</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.invoice_date ? DateService.dateTimeFormat(logisticViewData.invoice_date, 'DD-MMM-YYYY') : ''}</td>
                </tr>
            } 
            {
                field ===  "invoice_value" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Invoice Value</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.invoice_value ? logisticViewData.invoice_value : ''}</td>
                </tr>
            } 
            {
                field ===  "eway_bill_number" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Eway Bill Number</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.eway_bill_number ? logisticViewData.eway_bill_number : ''}</td>
                </tr>
            } 
            {
                field ===  "payment_mode" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Payment Mode</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }} >{logisticViewData.payment_mode ? logisticViewData.payment_mode : ''}</td>
                </tr>
            } 
            {
                field ===  "payment_date" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Payment Date</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }} >{logisticViewData.payment_date ? DateService.dateTimeFormat(logisticViewData.payment_date, 'DD-MMM-YYYY') : ''}</td>
                </tr>
            } 
            {
                field ===  "material_description" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Machinery/Material Description</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.machinery_description ? logisticViewData.machinery_description : ''}</td>
                </tr>
            } 
            {
                field ===  "cnf_agent" && logisticViewData.logistic_type === "International" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>C&F Agent</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.cnfAgent_name ? logisticViewData.cnfAgent_name : ''}</td>
                </tr>
            } 
            {
                field ===  "material_handler_name" && logisticViewData.logistic_type === "International" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Material Handler Name</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.handler_name ? logisticViewData.handler_name : ''}</td>
                </tr>
            }
            {
                field ===  "boe_number" && logisticViewData.logistic_type === "International" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>BOE Number</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.boe_number ? logisticViewData.boe_number : ''}</td>
                </tr>
            }
            {
                field ===  "logistic_cost" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Logistic Cost</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.logistic_cost ? logisticViewData.logistic_cost : ''}</td>
                </tr>
            }
            {
                field ===  "cha_cost" && logisticViewData.logistic_type === "International" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>CHA Cost</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.cha_cost ? logisticViewData.cha_cost : ''}</td>
                </tr>
            }
            {
                field ===  "handling_cost" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Handling Cost</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }}>{logisticViewData.handling_cost ? logisticViewData.handling_cost : ''}</td>
                </tr>
            }
            {
                field ===  "total_cost" &&
                <tr>
                    <td className="fs14 fw-normal" style={{ "width": "30%" }}>Total Cost</td>
                    <td className="fs14 fw500" style={{ "width": "70%" }} >{logisticViewData.total_cost ? logisticViewData.total_cost : ''}</td>
                </tr>
            }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token,
        isc_configuration       :   state.app && state.app.isc_configuration ? state.app.isc_configuration : null,
        permissions             :   state.app.acl_info && state.app.acl_info.permissions && state.app.acl_info.permissions.length > 0 ? state.app.acl_info.permissions : []
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(StepsDetailTableFields);