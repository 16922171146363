import React from 'react';
import { connect } from 'react-redux';
import HttpAPICall from '../../../services/HttpAPICall';
import { APP_BASE_URL_2 } from '../../../services/TapApiUrls';
import Ax from '../../../components/hoc/Ax';
import tapIcon from '../../../services/TapIcon';
import { Modal } from 'bootstrap';
import Loader from '../../../components/ui/Loader/Loader';
import TapSelect from '../../../components/ui/TapSelect';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import moment from "moment";
import AppBaseComponent from '../../../components/AppBaseComponent';
import uuid from 'react-uuid';
import InputPurchaseSearch from '../../includes/ui/InputPurchaseSearch';
import InputInventoryTransferSearch from '../../includes/ui/InputInventoryTransferSearch';
import InputPurchaseOrderSearch from '../../includes/ui/InputPurchaseOrderSearch';

class LogisticEditModal extends AppBaseComponent {
    
    constructor(props) {
        super(props);

        this.initialLinkingData         =   {
            purchase_transaction_ids        :   [],
            inv_transfer_transaction_ids    :   [],
            purchase_order_transaction_ids  :   [],
        };

        this.state                      =   {
            editFormSubmitting              :   false,
            loading_form                    :   false,
            addNewDistrict                  :   false,
            addNewPinCode                   :   false,

            paymentMode                     :   [],
            transportersList                :   [],
            cnfAgentsList                   :   [],
            handlersList                    :   [],

            divisionsList                   :   [],
            modalType                       :   '', 
            logisticDetail                  :   {...this.initialLinkingData},   
            transport_days                  :   '',   
            
            // linkingData                     :   {...this.initialLinkingData},
            searchedPurchase                :   [],
            searchedInvTransfer             :   [],
            searchedOrderPurchase           :   [],
            
            cnf                             :   {},
            billing                         :   {},
            consigment                      :   {},
            tracking                        :   {},
            purchaseInventory               :   {},
            allUsers                        :   [],

            editFieldData                   :   null,
            
            
        };

        this.domesticLoadType   =   [      
            {value: 'FULL TRUCK CONSIGNMENT', label: 'FULL TRUCK CONSIGNMENT'},
            {value: 'PARTIAL CONSIGNMENT', label: 'PARTIAL CONSIGNMENT'},
            {value: 'COURIER CONSIGNMENT', label: 'COURIER CONSIGNMENT'}
        ];
        this.internationalLoadType   =   [      
            {value: 'AIR CARGO', label: 'AIR CARGO'},
            {value: 'SEA CARGO', label: 'SEA CARGO'}
        ];
        this.unitOfMeasurement     =   [
            {value: "Tonne", label: "Tonne"},
            {value: "Kilogram", label: "Kilogram"}
        ]
        
        this.modalId            =   uuid();   
        this.logisticUrl        =   APP_BASE_URL_2 + '/logistic';
       
    }
    
  
    componentDidMount(){
        this.addModal                  =   new Modal(document.getElementById(this.modalId), {keyboard: false, backdrop :false});
    }

    showModalInit           =   (type)  =>  {
        const logisticDetail    =   this.props.LogisticDetail;
        const allFields         =   this.props.allFields;
        this.setState({
            loading_form    :   true,
            modalType       :   type,
            logisticDetail  :   {...logisticDetail},
            
        });
        
        let data = {};
        allFields && allFields.length > 0 && allFields.map(field => {
            switch(field) {
                case "logistic_type" :
                    data = {...data, logistic_type : logisticDetail.logistic_type};
                    break;   
                case "division" :
                    data = {...data, division_id : logisticDetail.division_id};
                    break;   
                case "type_of_load" :
                    data = {...data, load_type : logisticDetail.load_type};
                    break; 
                case "transporter_name" :
                    data = {...data, transporter_id : logisticDetail.transporter_id};
                    break;
                case "consigment_number" :
                    data = {...data, consigment_number : logisticDetail.consigment_number};
                    break;
                case "date_of_dispatch" :
                    data = {...data, dispatch_date : logisticDetail.dispatch_date};
                    break;
                case "expected_delivery_date" :
                    data = {...data, delivery_date : logisticDetail.delivery_date};
                    break;
                case "booking_place" :
                    data = {...data, booking_place : logisticDetail.booking_place};
                    break;
                case "vehicle_number" :
                    data = {...data, vehicle_number : logisticDetail.vehicle_number};
                    break;
                case "weight" :
                    data = {...data, weight : logisticDetail.weight};
                    break;
                case "unit_of_measurement" :
                    data = {...data, unit_of_measurement : logisticDetail.unit_of_measurement};
                    break;
                case "no_of_boxes" :
                    data = {...data, number_of_boxes : logisticDetail.number_of_boxes};
                    break;
                case "delivery_date_at_godown" :
                    data = {...data, delivery_date_at_godown : logisticDetail.delivery_date_at_godown};
                    break;
                case "delivery_date_at_site" :
                    data = {...data, delivery_date_at_site : logisticDetail.delivery_date_at_site};
                    break;
                // case "transport_days" :
                //     data = {...data, };
                //     break;
                case "receiver_name" :
                    data = {...data, receiver_name : logisticDetail.receiver_name};   
                    break;
                case "invoice_number" :
                    data = {...data, gst_invoice_number : logisticDetail.gst_invoice_number};   
                    break;
                case "invoice_date" :
                    data = {...data, invoice_date : logisticDetail.invoice_date};   
                    break;
                case "invoice_value" :
                    data = {...data, invoice_value : logisticDetail.invoice_value};   
                    break;
                case "eway_bill_number" :
                    data = {...data, eway_bill_number : logisticDetail.eway_bill_number};   
                    break;
                case "payment_mode" :
                    data = {...data, payment_mode : logisticDetail.payment_mode};   
                    break;
                case "payment_date" :
                    data = {...data, payment_date : logisticDetail.payment_date};   
                    break;
                case "material_description" :
                    data = {...data, machinery_description : logisticDetail.machinery_description};   
                    break;
                case "logistic_cost" :
                    data = {...data, logistic_cost : logisticDetail.logistic_cost};   
                    break;
                case "cha_cost" :
                    data = {...data, cha_cost : logisticDetail.cha_cost};   
                    break;
                case "handling_cost" :
                    data = {...data, handling_cost : logisticDetail.handling_cost};   
                    break;
                // case "total_cost" :
                //     data = {...data, };   
                //     break;
                case "cnf_agent" :
                    data = {...data, cnf_agent_id : logisticDetail.cnf_agent_id};   
                    break;
                case "material_handler_name" :
                    data = {...data, handler_id : logisticDetail.handler_id};   
                    break;
                case "boe_number" :
                data = {...data, boe_number : logisticDetail.boe_number};   
                break;
            }
        });

        if(type === 'Purchase & Inventory') {
            this.setState({
                purchaseInventory: {
                    purchase_transaction_ids : logisticDetail.purchase_transaction_ids,
                    purchase_order_transaction_ids : logisticDetail.purchase_order_transaction_ids,
                    inv_transfer_transaction_ids : logisticDetail.inv_transfer_transaction_ids,
                    users : logisticDetail.usersList && logisticDetail.usersList.length > 0 ? logisticDetail.usersList.map(u => u.enc_id) : []
                },
                searchedPurchase:   logisticDetail.purchase_transaction_ids && logisticDetail.purchase_transaction_ids.map((p,i) => {
                    return {
                        transaction_id : p,
                        label : p,
                        value : p
                    }
                }),
                searchedOrderPurchase:   logisticDetail.purchase_order_transaction_ids && logisticDetail.purchase_order_transaction_ids.map((p,i) => {
                    return {
                        transaction_id : p,
                        label : p,
                        value : p
                    }
                }),
                searchedInvTransfer:   logisticDetail.inv_transfer_transaction_ids && logisticDetail.inv_transfer_transaction_ids.map((p,i) => {
                    return {
                        transaction_id : p,
                        label : p,
                        value : p
                    }
                }),
            }, () => {
                this.addModal.show();
            })
        }else {
            this.setState({
                editFieldData : data
            }, () => {
                this.addModal.show();
            });
        }


        // if(type === 'Tracking') {
        //     this.setState({
        //         tracking : {
        //             dispatch_date : logisticDetail.dispatch_date,   
        //             delivery_date : logisticDetail.delivery_date,   
        //             receiver_name : logisticDetail.receiver_name,   
        //             delivery_date_at_godown : logisticDetail.delivery_date_at_godown,   
        //             delivery_date_at_site : logisticDetail.delivery_date_at_site,   
        //         }
        //     }, () => {
        //         this.addModal.show();
        //     });
        // }
        // if(type === 'Consigment') {
        //     this.setState({
        //         consigment : {
        //             load_type : logisticDetail.load_type,
        //             consigment_number : logisticDetail.consigment_number,   
        //             booking_place : logisticDetail.booking_place,   
        //             transporter_id : logisticDetail.transporter_id,   
        //             vehicle_number : logisticDetail.vehicle_number,   
        //             weight : logisticDetail.weight,   
        //             number_of_boxes : logisticDetail.number_of_boxes,    
        //         }
        //     }, () => {
        //         this.addModal.show();
        //     });
        // }
        // if(type === 'Billing') {
        //     this.setState({
        //         billing : {
        //             gst_invoice_number : logisticDetail.gst_invoice_number,
        //             invoice_date : logisticDetail.invoice_date,   
        //             invoice_value : logisticDetail.invoice_value,   
        //             machinery_description : logisticDetail.machinery_description,   
        //             payment_mode : logisticDetail.payment_mode,   
        //             payment_date : logisticDetail.payment_date,   
        //             eway_bill_number : logisticDetail.eway_bill_number,   
        //         }
        //     }, () => {
        //         this.addModal.show();
        //     });
        // }
        // if(type === 'C&F') {
        //     this.setState({
        //         cnf : {
        //             cnf_agent_id    :   logisticDetail.cnf_agent_id,
        //             handler_id      :   logisticDetail.handler_id,      
        //             boe_number      :   logisticDetail.boe_number,      
        //         }
        //     }, () => {
        //         this.addModal.show();
        //     });
        // }
        // if(type === 'Expense') {
        //     this.setState({
        //         expense : {
        //             logistic_cost : logisticDetail.logistic_cost,      
        //             handling_cost : logisticDetail.handling_cost,
        //             cha_cost : logisticDetail.cha_cost,      
        //         }
        //     }, () => {
        //         this.addModal.show();
        //     });
        // }
        if(['Transaction', 'Consigment', 'Billing', 'C&F', 'Purchase & Inventory'].includes(type)) {
            HttpAPICall.withAthorization('GET', `${this.logisticUrl}/form_data`, this.props.access_token, null, null, (response) => {
                const formData                   =   response.data;
                this.setState({
                     paymentMode                 :   formData.payment_mode,
                     logisticStatus              :   formData.status.map((s) => { return { value: s.id, label: s.name}}), 
                     transportersList            :   formData.transporter.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                     cnfAgentsList               :   formData.cnfAgent.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                     divisionsList               :   formData.division.map((s) => { return { value: s.id, label: `${s.name}`}}),       
                     handlersList                :   formData.handler.map((s) => { return { value: s.id, label: `${s.name}`}}),    
                     allUsers                    :   formData && formData.users ? formData.users.map((s) => { return {value: s.id, label: s.full_name,site_ids:s.linked_sites}; }) : [],    
                });
            }).then(() => { this.setState({ loading_form: false}) });
        }else {
            this.setState({ loading_form: false});
        }
        
    }


    //submit Edit Form
    editLogisticFormSubmit      =   (e) =>  {
        e.preventDefault();
        let data    =   {id : this.state.logisticDetail.id}
        // console.log(this.state.editFieldData, data);
        if(this.state.modalType === "Purchase & Inventory") {
            data                =   {...data, ...this.state.purchaseInventory}
        }else{
            data        =   {...data, ...this.state.editFieldData}
        }
        this.setState({editFormSubmitting : true});    
        HttpAPICall.withAthorization('PUT', `${this.logisticUrl}/edit`, this.props.access_token, null, data, (response) => {
            this.setState({
                editFieldData : null
            });
            toast.success(response.data.msg, { position: toast.POSITION.TOP_RIGHT });
            this.props.getLogisticViewDetails(this.state.logisticDetail.transaction_id);
            this.addModal.hide();
        }).then(() => this.setState({ editFormSubmitting: false }));

    }

    totalCost   =   ()  =>  {
        const arrInc            =   ['', null];
        const logisticDetail    =   this.state.expense;

        const handlingCost  =   arrInc.includes(logisticDetail.handling_cost)  ? 0 : parseFloat(logisticDetail.handling_cost);
        const logisticCost  =   arrInc.includes(logisticDetail.logistic_cost)  ? 0 : parseFloat(logisticDetail.logistic_cost);
        
        let totalCost       =   handlingCost  + logisticCost;
        if(this.state.logisticDetail.logistic_type === "International") {
            const chaCost   =   arrInc.includes(logisticDetail.cha_cost)  ? 0 : parseFloat(logisticDetail.cha_cost);
            totalCost       +=  chaCost;
        }
        return totalCost;
    }
   

    //*********************************ITEM ADD JSX*********************** */
    modalJsx                     =   ()  =>  {
        const logisticDetail            =   this.state.logisticDetail;
        const totalCost                 =   0; //this.totalCost();
        const {allFields}               =   this.props;

        return (
            <div className="modal fade" id={this.modalId} tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content  ">
                        <div className="modal-header">
                            <h5 className="modal-title" id="addModalLabel">Edit {this.state.modalType}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => this.setState({editFieldData : null})}></button>
                        </div>
                        <div className="modal-body ">
                            {
                            this.state.loading_form 
                            ? 
                            <Loader/>
                            : 
                            <form onSubmit={this.editLogisticFormSubmit} id="editAddressForm" method="post" encType="multipart/form-data">
                            <div className="row align-items-center my-3">
                                {
                                    allFields && allFields.length > 0 && allFields.map(field => {
                                        return (
                                            <>
                                                {
                                                    field === "logistic_type" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label">Logistic Type</label>
                                                        </div>
                                                        <div className="col-sm-10 mt-3">
                                                            <div className="row">
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        name="logistic_type"
                                                                        type="radio"
                                                                        value={"Domestic"}
                                                                        required={true}
                                                                        onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                        className="form-check-input"
                                                                        checked={this.state.editFieldData?.logistic_type === 'Domestic' ? true : false}
                                                                        id="domestic"
                                                                    />
                                                                    <label className="form-check-label mx-2" htmlFor="domestic">Domestic</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        name="logistic_type"
                                                                        type="radio"
                                                                        required={true}
                                                                        value={"International"}
                                                                        onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                        className="form-check-input"
                                                                        checked={this.state.editFieldData?.logistic_type === 'International' ? true : false}
                                                                        id="international"
                                                                    />
                                                                    <label className="form-check-label mx-2" htmlFor="international">International</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "division" &&
                                                    <>
                                                        <div className="col-sm-2 col-form-label col-form-label-sm mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Division</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <TapSelect
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'editFieldData', 'division_id');
                                                                }}
                                                                options={this.state.divisionsList}
                                                                value={this.state.divisionsList.find(s => s.value == this.state.editFieldData?.division_id)}
                                                                isSearchable={true}
                                                                isClearable={false}
                                                                placeholder="Select Division"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "type_of_load" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Type of Load</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <TapSelect
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'editFieldData', 'load_type');
                                                                }}
                                                                options={logisticDetail.logistic_type === 'International' ? this.internationalLoadType : this.domesticLoadType}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                required={true}
                                                                value={
                                                                    logisticDetail.logistic_type === 'International' 
                                                                    ? 
                                                                    this.internationalLoadType.find(s => s.value == this.state.editFieldData?.load_type)
                                                                    :
                                                                    this.domesticLoadType.find(s => s.value == this.state.editFieldData?.load_type)
                                                                }
                                                                placeholder="Select Load Type"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "transporter_name" &&
                                                    <>
                                                    <div className="col-sm-2 mt-3">
                                                        <label htmlFor="site_id" className="form-label ">Courier/Transporter Name</label>
                                                    </div>
                                                    <div className="col-sm-4 mt-3">
                                                        <TapSelect
                                                            changeEvent={(selectedOption) => {
                                                                this.tapSelectChange(selectedOption, 'editFieldData', 'transporter_id');
                                                            }}
                                                            options={this.state.transportersList}
                                                            value={this.state.transportersList.find(s => s.value == this.state.editFieldData?.transporter_id)}
                                                            isSearchable={true}
                                                            isClearable={true}
                                                            placeholder="Select Material Handler"
                                                        />
                                                    </div>
                                                    </>
                                                }
                                                {
                                                    field === "consigment_number" &&
                                                    <>
                                                    <div className="col-sm-2 mt-3">
                                                        <label htmlFor="site_id" className="form-label ">Consigment Number</label>
                                                    </div>
                                                    <div className="col-sm-4 mt-3">
                                                        <input
                                                            name="consigment_number"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                            value={this.state.editFieldData?.consigment_number}
                                                            placeholder="Enter Consigment Number"
                                                        />
                                                    </div>
                                                    </>
                                                }
                                                {
                                                    field === "expected_delivery_date" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Expected Delivery Date</label>
                                                        </div>
                                                        <div className="col-sm-4 add_calender_section mt-3">
                                                            <DatePicker
                                                                selected={
                                                                    this.state.editFieldData?.delivery_date
                                                                        ? moment(this.state.editFieldData?.delivery_date, 'YYYY-MM-DD').toDate()
                                                                        : false
                                                                }
                                                                onChange={(value, event) => this.formDateHandler('delivery_date', value, 'editFieldData')}
                                                                name="delivery_date"
                                                                dateFormat="dd-MMM-yyyy"
                                                                className={"form-control"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                autoComplete="off"
                                                                scrollMonthYearDropdown
                                                                placeholderText={`Enter Delivery Date`}
                                                            />
                                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date" className="add_calender-icon" />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "booking_place" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Booking Place</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="booking_place"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                value={this.state.editFieldData?.booking_place}
                                                                placeholder="Enter Booking Place"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "vehicle_number" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Vehicle Number</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="vehicle_number"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                value={this.state.editFieldData?.vehicle_number}
                                                                placeholder="Enter Vehicle Number"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "weight" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Weight</label>
                                                        </div>
                                                        <div className="col-sm-2 mt-3">
                                                            <input
                                                                name="weight"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                value={this.state.editFieldData?.weight}
                                                                placeholder="Enter Weight"
                                                            />
                                                        </div>
                                                        <div className="col-sm-2 mt-3">
                                                            <TapSelect
                                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'editFieldData', 'unit_of_measurement');}}
                                                                options={this.unitOfMeasurement} 
                                                                id="unit_of_measurement"
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                value={this.unitOfMeasurement.find(unit => unit.value === this.state.editFieldData?.unit_of_measurement)}
                                                                placeholder="Select Unit"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "no_of_boxes" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">No. of Boxes</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="number_of_boxes"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                value={this.state.editFieldData?.number_of_boxes}
                                                                placeholder="Enter No. of Boxes"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "delivery_date_at_godown" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Delivery Date at Godown</label>
                                                        </div>
                                                        <div className="col-sm-4 add_calender_section mt-3">
                                                            <DatePicker
                                                                selected={
                                                                    this.state.editFieldData?.delivery_date_at_godown
                                                                        ? moment(this.state.editFieldData?.delivery_date_at_godown, 'YYYY-MM-DD').toDate()
                                                                        : false
                                                                }
                                                                onChange={(value, event) => this.formDateHandler('delivery_date_at_godown', value, 'editFieldData')}
                                                                name="delivery_date_at_godown"
                                                                dateFormat="dd-MMM-yyyy"
                                                                className={"form-control"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                autoComplete="off"
                                                                scrollMonthYearDropdown
                                                                placeholderText={`Enter Delivery Date at Godown`}
                                                            />
                                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Godown" className="add_calender-icon" />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "delivery_date_at_site" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Delivery Date at Site</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3 add_calender_section mt-3">
                                                            <DatePicker
                                                                selected={
                                                                    this.state.editFieldData?.delivery_date_at_site
                                                                        ? moment(this.state.editFieldData?.delivery_date_at_site, 'YYYY-MM-DD').toDate()
                                                                        : false
                                                                }
                                                                onChange={(value, event) => {
                                                                    this.formDateHandler('delivery_date_at_site', value, 'editFieldData');
                                                                }}
                                                                name="delivery_date_at_site"
                                                                dateFormat="dd-MMM-yyyy"
                                                                className={"form-control"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                autoComplete="off"
                                                                scrollMonthYearDropdown
                                                                placeholderText={`Enter Delivery Date at Site`}
                                                            />
                                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Delivery Date at Site" className="add_calender-icon" />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "receiver_name" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Receiver Name</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="receiver_name"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                value={this.state.editFieldData?.receiver_name}
                                                                placeholder="Enter Receiver Name"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "invoice_number" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Invoice Number</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="gst_invoice_number"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                value={this.state.editFieldData?.gst_invoice_number}
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                placeholder="Enter Invoice Number"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "invoice_date" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Invoice Date</label>
                                                        </div>
                                                        <div className="col-sm-4 add_calender_section mt-3">
                                                            <DatePicker
                                                                selected={
                                                                    this.state.editFieldData?.invoice_date
                                                                        ? moment(this.state.editFieldData?.invoice_date, 'YYYY-MM-DD').toDate()
                                                                        : false
                                                                }
                                                                onChange={(value, event) => this.formDateHandler('invoice_date', value, 'editFieldData')}
                                                                name="invoice_date"
                                                                dateFormat="dd-MMM-yyyy"
                                                                className={"form-control"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                autoComplete="off"
                                                                scrollMonthYearDropdown
                                                                placeholderText={`Enter Invoice Date`}
                                                            />
                                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Invoice Date" className="add_calender-icon" />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "invoice_value" &&
                                                    <>
                                                    <div className="col-sm-2 mt-3">
                                                        <label htmlFor="site_id" className="form-label ">Invoice Value</label>
                                                    </div>
                                                    <div className="col-sm-4 mt-3">
                                                        <input
                                                            name="invoice_value"
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                            value={this.state.editFieldData?.invoice_value}
                                                            placeholder="Enter Invoice Value"
                                                        />
                                                    </div>
                                                    </>
                                                }
                                                {
                                                    field === "eway_bill_number" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Eway Bill Number</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="eway_bill_number"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                value={this.state.editFieldData?.eway_bill_number}
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                placeholder="Enter Eway Bill Number"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "payment_mode" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Payment Mode</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <TapSelect
                                                                changeEvent={(selectedOption) => { this.tapSelectChange(selectedOption, 'editFieldData', 'payment_mode');}}
                                                                options={this.state.paymentMode}
                                                                value={this.state.paymentMode.find(s => s.value == this.state.editFieldData?.payment_mode)}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                placeholder="Select Payment Mode"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "payment_date" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Payment Date</label>
                                                        </div>
                                                        <div className="col-sm-4 add_calender_section mt-3">
                                                            <DatePicker
                                                                selected={
                                                                    this.state.editFieldData?.payment_date
                                                                        ? moment(this.state.editFieldData?.payment_date, 'YYYY-MM-DD').toDate()
                                                                        : false
                                                                }
                                                                onChange={(value, event) => this.formDateHandler('payment_date', value, 'editFieldData')}
                                                                name="payment_date"
                                                                dateFormat="dd-MMM-yyyy"
                                                                className={"form-control"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                autoComplete="off"
                                                                scrollMonthYearDropdown
                                                                placeholderText={`Enter Payment Date`}
                                                            />
                                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Payment Date" className="add_calender-icon" />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "material_description" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Machinery/Material Description</label>
                                                        </div>
                                                        <div className="col-sm-10 mt-3">
                                                            <input
                                                                name="machinery_description"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                value={this.state.editFieldData?.machinery_description}
                                                                placeholder="Enter Machinery / Material Description"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "cnf_agent" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">C&F Agent</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <TapSelect
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'editFieldData', 'cnf_agent_id');
                                                                }}
                                                                options={this.state.cnfAgentsList}
                                                                value={this.state.cnfAgentsList.find(s => s.value == this.state.editFieldData?.cnf_agent_id)}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                placeholder="Select C&F Agent"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "material_handler_name" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Material Handler Name</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <TapSelect
                                                                changeEvent={(selectedOption) => {
                                                                    this.tapSelectChange(selectedOption, 'editFieldData', 'handler_id');
                                                                }}
                                                                options={this.state.handlersList}
                                                                value={this.state.handlersList.find(s => s.value == this.state.editFieldData?.handler_id)}
                                                                isSearchable={true}
                                                                isClearable={true}
                                                                placeholder="Select Material Handler"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "boe_number" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">BOE Number</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="boe_number"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                value={this.state.editFieldData?.boe_number}
                                                                onChange={(e) => this.formInputHandler(e, 'editFieldData')}
                                                                placeholder="Enter BOE Number"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "logistic_cost" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Logistic Cost</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="logistic_cost"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    this.formInputHandler(e, 'editFieldData');
                                                                }}
                                                                value={this.state.editFieldData?.logistic_cost}
                                                                placeholder="Enter Logistic Cost"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "cha_cost" && logisticDetail.logistic_type === "International" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">CHA Cost</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3">
                                                            <input
                                                                name="cha_cost"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    this.formInputHandler(e, 'editFieldData');
                                                                }}
                                                                value={this.state.editFieldData?.cha_cost}
                                                                placeholder="Enter CHA Cost"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "handling_cost" &&
                                                    <>
                                                        <div className="col-sm-2 my-3">
                                                            <label htmlFor="site_id" className="form-label ">Handling Cost</label>
                                                        </div>
                                                        <div className="col-sm-4 my-3">
                                                            <input
                                                                name="handling_cost"
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                onChange={(e) => {
                                                                    this.formInputHandler(e, 'editFieldData');
                                                                }}
                                                                value={this.state.editFieldData?.handling_cost}
                                                                placeholder="Enter Handling Cost"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "total_cost" &&
                                                    <>
                                                        <div className="col-sm-2 my-3">
                                                            <label htmlFor="site_id" className="form-label ">Total Cost</label>
                                                        </div>
                                                        <div className="col-sm-4 my-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                autoComplete="off"
                                                                disabled={true}
                                                                value={totalCost}
                                                                placeholder="Enter Total Cost"
                                                            />
                                                        </div>
                                                    </>
                                                }
                                                {
                                                    field === "date_of_dispatch" &&
                                                    <>
                                                        <div className="col-sm-2 mt-3">
                                                            <label htmlFor="site_id" className="form-label ">Date of Dispatch</label>
                                                        </div>
                                                        <div className="col-sm-4 mt-3 add_calender_section">
                                                            <DatePicker
                                                                selected={
                                                                    this.state.editFieldData?.dispatch_date
                                                                        ? moment(this.state.editFieldData?.dispatch_date, 'YYYY-MM-DD').toDate()
                                                                        : false
                                                                }
                                                                name="dispatch_date"
                                                                onChange={(value, event) => this.formDateHandler('dispatch_date', value, 'editFieldData')}
                                                                dateFormat="dd-MMM-yyyy"
                                                                className={"form-control"}
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                autoComplete="off"
                                                                scrollMonthYearDropdown
                                                                placeholderText={`Dispatch Date`}
                                                            />
                                                            <tapIcon.imageIcon icon={tapIcon.CalenderIcon} alt="Dispatch Date" className="add_calender-icon" />
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        )
                                    }) 
                                }
                            </div>
                                {
                                    this.state.modalType === "Purchase & Inventory"
                                    ?
                                    <div className="row align-items-center mt-3">
                                        <div className="col-sm-3">
                                            <label htmlFor="site_id" className="form-label ">Tag Users</label>
                                        </div>
                                        <div className="col-sm-8">
                                            <TapSelect
                                                changeEvent={(selectedOption) => this.tapSelectChange(selectedOption, 'purchaseInventory', 'users')}
                                                options={this.state.allUsers}
                                                isSearchable={true}
                                                isClearable={true}
                                                isMulti={true}
                                                value={this.state.allUsers.filter(t => this.state.purchaseInventory.users.includes(t.value))}
                                                placeholder="Please Select User"
                                            />
                                        </div>
                                        <div className="col-sm-3 mt-3 col-form-label col-form-label-sm">
                                            <label htmlFor="site_id" className="form-label ">Purchase Order</label>
                                        </div>
                                        <div className="col-sm-8 mt-3">
                                        <InputPurchaseOrderSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'purchaseInventory', 'purchase_order_transaction_ids');
                                                this.setState({ searchedOrderPurchase: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedOrderPurchase && this.state.searchedOrderPurchase.length > 0 ? this.state.searchedOrderPurchase : []}
                                        />
                                        </div>
                                        <div className="col-sm-3 col-form-label col-form-label-sm mt-3">
                                            <label htmlFor="site_id" className="form-label ">Purchase</label>
                                        </div>
                                        <div className="col-sm-8">
                                        <InputPurchaseSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'purchaseInventory', 'purchase_transaction_ids');
                                                this.setState({ searchedPurchase: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedPurchase && this.state.searchedPurchase.length > 0 ? this.state.searchedPurchase : []}
                                        />
                                        </div>
                                        <div className="col-sm-3 col-form-label col-form-label-sm mt-3">
                                            <label htmlFor="site_id" className="form-label ">Inventory Transfer</label>
                                        </div>
                                        <div className="col-sm-8">
                                        <InputInventoryTransferSearch
                                            changeEvent={(selectedOption) => {
                                                this.tapSelectChange(selectedOption, 'purchaseInventory', 'inv_transfer_transaction_ids');
                                                this.setState({ searchedInvTransfer: selectedOption && selectedOption.length > 0 ? selectedOption : [] });
                                            }}
                                            isMulti={true}
                                            menuPlacement="top"
                                            value={this.state.searchedInvTransfer && this.state.searchedInvTransfer.length > 0 ? this.state.searchedInvTransfer : []}
                                        />
                                        </div>
                                    </div> 
                                    :
                                    null
                                }
                            </form>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={this.state.editFormSubmitting} className="btn btn-secondary mx-2" data-bs-dismiss="modal" onClick={() => this.setState({editFieldData : null})}>Cancel</button>
                            <button type="submit" disabled={this.state.editFormSubmitting} className="btn btn-primary" form="editAddressForm">
                                Submit {this.state.editFormSubmitting ? (<tapIcon.FontAwesomeIcon icon={tapIcon.faSyncAlt} className="fa-spin" />) : ''}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    

    render() {
        return (<Ax>{this.modalJsx()}</Ax>);
    }
}

const mapStateToProps = state => {
    return {
        access_token            :   state.auth.access_token
    };
};

export default connect(mapStateToProps,null, null, { forwardRef: true })(LogisticEditModal);